import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import { getMessaging, getToken, onMessage, onBackgroundMessage } from 'firebase/messaging';
import { setFCMUpdateapi } from './utils/api';
import react, { useEffect } from "react";
import { useSelector } from "react-redux";
import { logoutUser, selectUser } from "./store/reducers/userReducer";
import { Navigate } from "react-router";

// const firebaseConfig = {
//   apiKey: "AIzaSyBVA6k_uITlmQ35FZSq_btIxj4T-gdkQys",
//   authDomain: "sample-4cf3b.firebaseapp.com",
//   projectId: "sample-4cf3b",
//   storageBucket: "sample-4cf3b.appspot.com",
//   messagingSenderId: "1030048767364",
//   appId: "1:1030048767364:web:0939a86d322e0ea6bbd1d1",
//   measurementId: "G-0XPEM11DGL"
// };
const firebaseConfig = {
  apiKey: "AIzaSyCgaEzdqlQAREPm5devEO29uDZkKWqBCgI",
  authDomain: "reflectnews-ed927.firebaseapp.com",
  projectId: "reflectnews-ed927",
  storageBucket: "reflectnews-ed927.appspot.com",
  messagingSenderId: "205128797617",
  appId: "1:205128797617:web:3e0d0a125985adc5aa4190",
  measurementId: "G-T40R4537SJ"
};


const app = initializeApp(firebaseConfig);
const authentication = getAuth();
const messaging = getMessaging(app);
let FCM = '';

const isSupported = () => 
'Notification' in window &&
'serviceWorker' in navigator &&
'PushManager' in window

if(isSupported()) {
Notification
.requestPermission()
.then((permission) => {
  if (permission === "granted") {
    console.log('Notification permission granted.');
    return getToken(messaging, { vapidKey: 'BMa0gpeuELvCuCeLR7smXU5EiChPHBuuepE80bP9YaEAelP8atHrUx0fNqX6ATUB-20AzcZhiXer0647Ub3gHzU' });
  } else {
    throw new Error('Notification permission denied.');
  }
})
.then((token) => {
  console.log('FCM Token:', token);
  FCM = token;
  if(localStorage.getItem("currentUserData")) {
  let userItem = JSON.parse(localStorage.getItem("currentUserData"));
  let body = {
    "fcmToken": token,
    "user_id": userItem.id,
  }
  const response = setFCMUpdateapi(body)
}
})
.catch((error) => {
  console.error('Permission error:', error);
});
}

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  if (Notification.permission === 'granted') {
    const notification = new Notification('Reflectnews Tamil', {
      body: payload.data?.message,
    });
  
    // Add an event listener to handle user interaction with the notification
    notification.addEventListener('click', () => {
      window.open(payload.fcmOptions?.link);
      // Handle the click event, e.g., navigate to a specific page
    });
  }
});

export {app, authentication, messaging, FCM};
