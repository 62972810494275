import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { getnewsApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { translate } from "../utils";
import { importantnews, getLatestNewslinks } from "../utils/api";
import no_image from "../images/no_image.jpg";
import { FiCalendar, FiClock } from "react-icons/fi";
import { Breathing } from "react-shimmer";
import {useThemeMode} from '../utils/themeMode'
import { BsArrowRight } from "react-icons/bs";

function RecentNewsSection() {
    const {mode} = useThemeMode();
    const [Data, setData] = useState([]);
    const [DataNes, setDataNews] = useState([]);
    const [themeButton, setThemeButton] = useState(localStorage.getItem('newsTheme'));
    const displayedData = DataNes.slice(0, 4);
    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        getnewsApi(
            "0",
            "8",
            "",
            "",
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [currentLanguage]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    const [spritual, setspritualnews] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchsetspritualnews = async () => {
        const response = await importantnews();
        if(response.data) {
        setspritualnews(response.data?.data)
        console.log("jhtbciuasedws", response);
        }
    }

    
    const newsGet = async () => {
        const response = await getLatestNewslinks();
        if(response.data) {
            setDataNews(response.data);
        }
      }
  
      useEffect(() => {
        newsGet();
      }, []);

    useEffect(() => {
        fetchsetspritualnews();
    }, [currentLanguage])


    const currentDate = new Date(); // Current date and time
    const filteredSpritual = spritual.filter((value) => {
        const showTillDate = new Date(value.show_till);
        return currentDate < showTillDate; // Compare current date with show_till date
    });

    console.log("erjweuorwerti", mode);
    // useEffect(() => {
    //     if (document.documentElement?.getAttribute('data-bs-theme') == 'dark') {
    //         setThemeButton("light");            
    //     }
    //     else {
    //         setThemeButton("dark");
    //         localStorage.setItem('newsTheme', "dark");
    //     }
    // }, [mode]);

    return (
        <>
            <div className="">
                <div id="rns-main" className="">
                    <div className="container">
                        <div className="row w-100 m-0">
                            <div className="col-lg-8 mb-xl-0 mb-3">
                            {!Data || Data.length === 0 ? null : (
                                <div id="rns-cards-main" className="p-0 ">

                                    <div id="rns-head-main" className="">
                                        <div className="d-flex align-items-center"><div className="border_strong_head me-2"></div> <h4 className="mb-0" id="rns-main-logo">{translate("முக்கிய செய்திகள்")}</h4></div>
                                        <Link className={mode == 'light' ? "cursor-pointer" : "cursor-pointer bg-gray"} id="vps-Viewmore" to={"/category-view?id=9&uid=1&name=முக்கிய செய்திகள்"} onClick={() => scrollToTop()}>
                                            {/* {translate("மேலும் வாசிக்க")} */}
                                            <img src="/images/8.png" className="arrow_icon" alt="icon"></img>
                                            {/* <BsArrowRight /> */}
                                        </Link>
                                    </div>

                                    <div className="row px-2">
                                        {filteredSpritual.length > 0 ?
                                            filteredSpritual.slice(0, 8).map((value, index) => {
                                                return (
                                                    <div className=" col-lg-3 col-md-6 col-sm-6 col-6 mb-3 px-xl-4 px-2 ">
                                                        <div id="rns-card" className="card h-100" key={index}>
                                                            <Link 
                                                            onClick={() => scrollToTop()} 
                                                            // to={"/news-view?Nid=" + value.id + "&Cid=" + value.category_id}
                                                            to={"/news-view?slug=" + value.slug + "&c=" + value.category_name + "&sc=" + value.subcategory_name ?? ''}
                                                            // href={value.slug}
                                                            > 
                                                            <img id="rns-images" src={value.image ? value.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top" alt="..." /> 
                                                            </Link>
                                                            <div id="rns-img-overlay" className=" card-inverse">
                                                                {/* <Link id="btnrnsCatagory" className="btn btn-sm" type="button" to={`/category-view?id=${value.category_id}`}>
                                                                {value.category_name}
                                                            </Link> */}
                                                            </div>
                                                            <div id="rns-card-body" className="card-block">
                                                                <Link 
                                                                onClick={() => scrollToTop()} 
                                                            //    href={value.slug}
                                                                // to={"/news-view?Nid=" + value.id + "&Cid=" + value.category_id}
                                                                to={"/news-view?slug=" + value.slug + "&c=" + value.category_name + "&sc=" + value.subcategory_name ?? ''}
                                                                > 
                                                                <p className={mode == 'light' ? "card-title two_line text-dark" : "card-title two_line text-light"}>{value.title}</p>
                                                                </Link>
                                                                {/* <Link id="btnrnsRead" className="btn overlay" to={"/news-view?slug=" + value.slug} type="button">
                                                            {translate("readmore")}
                                                                <HiOutlineArrowLongRight id="rns-arrow" size={20} />
                                                            </Link> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }) : (
                                                <div className="col-12 text-center mt-4" style={{height: 200}}>
                                                    <p>வேறு தகவல்கள் இல்லை</p>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            )}
                            </div>

                            <div className="col-lg-4 mt-4 p-0 ps-2">
                                <div className={mode == 'light' ? "custom-swiper ul_home" : "custom-swiper ul_home bg-dark"} >
                                <div id="rns-head-main" className="m-0 mb-2">
                                    <div className="d-flex align-items-center"><div className="border_strong_head me-2"></div> <h4 className="mb-0" id="rns-main-logo">{translate("லேட்டஸ்ட்")}</h4></div>
                                    <Link className="cursor-pointer fs-8 text-nowrap" id="vps-Viewmore" to={"/all-news"} onClick={() => scrollToTop()}>
                                        {/* {translate("மேலும் வாசிக்க")} */}
                                        <img src="/images/8.png" className="arrow_icon" alt="icon"></img>
                                    </Link>
                                </div>
                                {displayedData.map((item) => {
                                return(
                                    <div className="card br_10 border-0 mb-2">
                                        <div className="li_home">
                                            <div id="fs-main" className="h-100">
                                                <div id="body-first-section">
                                                    <div className="d-flex">
                                                        <div id="right-first-section" className="">
                                                            <Link onClick={() => scrollToTop()}
                                                            to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + (item.subcategory_name ? item.subcategory_name : '')}
                                                            //  to={"/breaking-news?slug=" + item.slug}
                                                            // href={item.slug}
                                                            >
                                                                <img
                                                                    src={item.image ? item.image : no_image} onError={(e) => { e.target.src = no_image;}}
                                                                    className="float-end fs-Newscard-image"
                                                                    id="fs-Newscard-image"
                                                                    fallback={<Breathing width={800} height={600} />}
                                                                    alt="..."
                                                                />
                                                            </Link>
                                                        </div>
                                                        <div className="w-auto ps-2">
                                                            <div id="Left-first-section" className={mode == 'light' ? "" : 'bg-dark'}>
                                                                <Link onClick={() => scrollToTop()} 
                                                                //  href={item.slug}
                                                                to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + (item.subcategory_name ? item.subcategory_name : '')}
                                                                // to={"/breaking-news?slug=" + item.slug}
                                                                >
                                                                    <p className={mode == 'light' ? "text-dark" : "text-light"} id="Top-Title">
                                                                        {item.title}
                                                                    </p>
                                                                </Link>
                                                                <p id="cv-card-date" className="w-100 mb-0">
                                                                    <FiClock size={18} id="cv-logoClock" className="me-2" />
                                                                    {item.date}
                                                                </p>
                                                                <div>{/* Add any child elements here */}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )})}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
        </>
    );
}

export default RecentNewsSection;
