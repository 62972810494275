import React from "react";
import { FiCalendar, FiClock } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "../Hooks";
import { getnewsbytagApi } from "../store/actions/campaign";
import Skeleton from "react-loading-skeleton";

function TagNewsview() {
    const [Data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const query = useQuery();
    const Tid = query.get("Tid");

    useEffect(() => {
        getnewsbytagApi(
            Tid,
            (response) => {
                setData(response.data);
                setLoading(false);
            },
            (error) => {
                console.log(error);
                setLoading(false);
            }
        ); // eslint-disable-next-line
    }, []);
    
    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <div className="my-3">
            <div className="container">
                {loading ? 
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 p-3">
                        <Skeleton height={300} count={1} />
                    </div>
                </div>
                 : <> <div className="row">
                {Data.length > 0 ? (
    Data?.map((element) => (
        <div className="col-lg-4 col-md-6 col-xl-3 col-12 mb-3 px-2" key={element.id}>
            <Link id="Link-all"
             to={"/news-view?slug=" + element.slug + "&c=" + element.category_name + "&sc=" + element.subcategory_name}
            //  to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id}
             >
                <div id="ts-card" className="card h-100">
                    <img id="ts-card-image" src={element.image} className="card-img" alt="..." />

                    <div id="ts-card-body" className="card-body">
                        <button id="ts-btnCatagory" className="btn btn-sm" type="button">
                            {element.category_name}
                        </button>
                        <h6 id="ts-card-title" className="card-title two_line">
                            {element.title}
                        </h6>
                        <p id="cv-card-date">
                            <FiClock size={18} id="ts-logoCalendar" />
                            {element.date.slice(0, 10)}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    ))
) : (
    <p className="text-center my-5">வேறு தகவல்கள் இல்லை</p>
)}</div></>}

                
            </div>
        </div>
    );
}

export default TagNewsview;
