import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { useQuery } from "../Hooks";
import { Link } from "react-router-dom";
import TagsSection from "./TagsSection";
import { FacebookIcon, WhatsappIcon, TwitterIcon, TwitterShareButton, WhatsappShareButton, FacebookShareButton } from "react-share";
import RelatedBreakingNews from "./RelatedBreakingNews";
import BreadcrumbNav from "./BreadcrumbNav";
import { getbreakingnewsidApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { translate } from "../utils";
import { Card } from "react-bootstrap";
import { BsFillPlayFill } from "react-icons/bs";
import VideoPlayerModal from "./VideoPlayerModal";
import { AiOutlineLike, AiTwotoneLike } from "react-icons/ai";
import CommentSection from "./CommentSection";
import { getAddUrl } from "../utils/api";
import no_image from "../images/no_image.jpg";

function BreakingNews() {
    const [Data, setData] = useState([]);
    const [FontSize, setFontSize] = useState(14);
    const [Video_url, setVideo_url] = useState();
    const [modalShow, setModalShow] = useState(false);
    const query = useQuery();
    const BNid = query.get("BNid");
    const slug = query.get("slug");
    const shareUrl = window.location.href;
    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        getbreakingnewsidApi(
            slug,
            (response) => {
                setData(response.data)
            },
            (error) => {
                console.error(error);
            }
        );
    }, [slug, currentLanguage]);

    function handleVideoUrl(url) {
        setModalShow(true);
        setVideo_url(url);
    }

    const [adddata, setadddata] = useState([]);

    const fetchadd = async () => {
        const response = await getAddUrl();
        setadddata(response.data?.data)
    }

    useEffect(() => {
        fetchadd();
    }, [currentLanguage])

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    return (
        <>
            {Data.length === 0 ? (
                "ஏற்றுகிறது"
            ) : (
                <>
                    <BreadcrumbNav SecondElement="செய்தி விவரங்கள்" ThirdElement={Data[0].title} />
                    <div id="B_NV-main">
                        <div id="B_NV-page" className="container">
                            <div className="row">
                                <div className="col-md-7 col-lg-8 col-12">

                                    <div id="B_NV-body">
                                        <Link onClick={() => scrollToTop()}
                                         id="btnB_NVCatagory" className="btn btn-sm" type="button"
                                          to="/breaking-news-view"
                                          >
                                            {translate("Breaking News")}
                                        </Link>
                                        <h1 id="B_NV-title">{Data[0].title}</h1>
                                        {/* <div className="d-flex align-items-center mb-4"><img src="/images/58.png" className="img_icon_home " alt="icon" />
                                         <p className="mb-0 text-theme-light">ஜீவகணேஷ்.ப</p>
                                         </div> */}

                                        {/* <img src="/images/56.jpg" className="detail-pg_images mb-4" alt="icon"></img> */}
                                        <div id="vps-body-left">
                                            {Data[0] ? (
                                                <Card id="vps-main-card" className="text-black">
                                                    <Card.Img id="nv-image" src={Data[0].image ? Data[0].image : no_image} onError={(e) => { e.target.src = no_image;}} alt="..." />
                                                    <Card.ImgOverlay>
                                                    {Boolean(Data[0].content_value) && (
                                                        <Link id="vps-btnVideo" onClick={() => handleVideoUrl(Data[0].content_value)}>
                                                            <BsFillPlayFill id="vps-btnVideo-logo" fill="red" size={60} />
                                                        </Link>
                                                         )}
                                                    </Card.ImgOverlay>
                                                </Card>
                                            ) : null}
                                        </div>

                                        {/* {adddata.length > 0 && adddata?.map((item) => {
                                            if (item.ad_space === "breaking-news-detail-1") {
                                                return (
                                                    <a target="black" href={item.ad_url}>
                                                    <img src={Data[0].image ? Data[0].image : no_image} onError={(e) => { e.target.src = no_image;}} className="detail-pg_images mb-4" alt="icon" /></a>
                                                );
                                            }

                                        })} */}

                                        {/* <img src="/images/57.png" className="add_imgs mb-4" alt="icon"></img> */}

                                        {adddata.length > 0 && adddata?.map((item) => {
                                            if (item.ad_space === "breaking-news-detail-3") {
                                                return (
                                                    <a target="black" href={item.ad_url}>
                                                    <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3"alt="icon" /></a>
                                                );
                                            }

                                        })}

                                        {/* <p className="text-gray">
                                            ராமநாதபுரம் மாவட்டத்தின் தெக்குப்பட்டி கிராமத்தில் சாதியப் பாகுபாடுகள் நிரம்பிக் கிடக்கின்றன. மேலத் தெருவைச் சேர்ந்த மூர்க்கசாமியும் (அருள் நிதி) ஒடுக்கப்பட்ட சமூகத்தைச் சேர்ந்த பூமிநாதனும் (சந்தோஷ் பிரதாப்) உயிர் நண்பர்கள். சாதிய பாகுபாடுகளிலிருந்து தன் மக்களை விடுவிக்க போராடும் பூமிநாதனுக்கு ஆதரவாக இருந்து தன் சொந்த சாதியினரையே எதிர்க்கிறார் மூர்க்கசாமி. இப்படியிருக்க அந்த ஊர் மாவட்ட செயலாளராக இருந்து பதவி பறிக்கப்பட்ட முனியராஜ் (ராஜசிம்மன்) அரசியல் லாபத்துக்காக பூமிநாதனை கொல்ல திட்டம் திட்டுகிறார். அவரின் திட்டம் நிறைவேறியதா? மூர்க்கன் எப்படி இதில் பலிகடாவானார் என்பது திரைக்கதை
                                        </p>
                                        <p className="text-gray">
                                            இருவேறு சமூகங்கள். அவர்களிடையேயான சாதியப் பாகுபாடு. ஒரே சமூகத்தின் இரண்டு பிரிவுகளில் உள்ள ஏற்றத் தாழ்வுகள், தங்களைச் சுற்றியிருக்கும் வாய்ப்புகளை பயன்படுத்தி முன்னேறும் ஒடுக்கப்பட்ட சமூகத்தினர் உள்ளிட்ட பல்வேறு விஷயங்களை காட்சிப்படுத்தியதில் 'கழுவேத்தி மூர்க்கன்' மற்ற வழக்கமான கிராமத்து கதைகளிலிருந்து விலகி நிற்கிறது. குறிப்பாக. ஒடுக்கப்படும் மக்களின் பக்கம் மற்ற சமூகத்தினர் நிற்க வேண்டிய தேவையையும், சுய சாதிப்பற்று சொந்த சாதிக்காரனையே எப்படி பலி கொடுத்துவிடும் என்பதையும் சொன்ன விதம் அடர்த்தி கூட்டுகிறது.
                                        </p>
                                        <p className="text-gray">
                                            கொல பண்றது வீரம் இல்ல; 10 பேர காப்பாத்துறது தான் வீரம்', 'ஒருத்தன் தலைக்கு மேல நீங்க இருக்குறதா நெனைக்கிறீங்க, ஆனா நீங்களே இன்னொருத்த காலுக்கு கீழ தான்னு சொல்றாங்க", 'மீசைங்குறது வெறும் மயிர்' போன்ற வசனங்கள் சொல்ல வந்த கருத்துகளுக்கு உறுதுணையாயிருப்பது படத்தின் கன்டென்ட்டை இன்னும் ஆழமாக்குகிறது.
                                        </p> */}

                                        {/* <div id="B_NV-Header" className="">
                                            <div id="B_NV-left-head"></div>
                                            </div> */}
                                    

                                        {/* <img id="B_NV-image" src={Data[0].image} alt="..." /> */}
                                        <nav id="B_NV-functions" className="d-none">
                                            <div id="B_NV-functions-left">
                                                <Form.Label id="B_NV-font-lable">{translate("fontsize")}</Form.Label>
                                                <Form.Range id="B_NV-FontRange" min={14} max={24} step={2} value={FontSize} onChange={(e) => setFontSize(e.target.value)} />
                                                <div className="d-flex justify-content-between">
                                                    <Form.Label id="B_NV-FontRange-labels">14px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">16px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">18px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">20px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">22px</Form.Label>
                                                    <Form.Label id="B_NV-FontRange-labels">24px</Form.Label>
                                                </div>
                                            </div>
                                           
                                        </nav>

                                        <div id="B_NV-functions-right"></div>
                                        <p id="B_NV-description" style={{ fontSize: `${FontSize}px` }} dangerouslySetInnerHTML={{ __html: Data[0].description }}></p>

                                        {adddata.length > 0 && adddata?.map((item) => {
                                            if (item.ad_space === "breaking-news-detail-4") {
                                                return (
                                                    <a target="black" href={item.ad_url}>
                                                    <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3"alt="icon" /></a>
                                                );
                                            }

                                        })}

                                        {/* <img src="/images/47.png" className="add_img mb-4" alt="icon"/> */}
                                        {/* <p className="text-gray">ராமநாதபுரம் மாவட்டத்தின் தெக்குப்பட்டி கிராமத்தில் சாதியப் பாகுபாடுகள் நிரம்பிக் கிடக்கின்றன. மேலத் தெருவைச் சேர்ந்த மூர்க்கசாமியும் (அருள் நிதி) ஒடுக்கப்பட்ட சமூகத்தைச் சேர்ந்த பூமிநாதனும் (சந்தோஷ் பிரதாப்) உயிர் நண்பர்கள். சாதிய பாகுபாடுகளிலிருந்து தன் மக்களை விடுவிக்க போராடும் பூமிநாதனுக்கு ஆதரவாக இருந்து தன் சொந்த சாதியினரையே எதிர்க்கிறார் மூர்க்கசாமி. இப்படியிருக்க அந்த ஊர் மாவட்ட செயலாளராக இருந்து பதவி பறிக்கப்பட்ட முனியராஜ் (ராஜசிம்மன்) அரசியல் லாபத்துக்காக பூமிநாதனை கொல்ல திட்டம் திட்டுகிறார். அவரின் திட்டம் நிறைவேறியதா? மூர்க்கன் எப்படி இதில் பலிகடாவானார் என்பது திரைக்கதை</p> */}

                                    </div>
                                    <div id="nv-Header" className="mb-3">
                                        <div id="nv-left-head">
                                            {/* <h6 id="head-lables">
                                                    <AiOutlineEye size={18} id="head-logos" />
                                              </h6> */}
                                            {/* <p id="head-lables">
                                                <AiOutlineLike size={18} id="head-logos" /> {Data && Data[0].total_like} {translate("likes")}
                                            </p> */}
                                        </div>

                                        <div id="nv-right-head">
                                            {/* <h6 id="nv-Share-Label">{translate("Share")}:</h6> */}
                                            <FacebookShareButton url={shareUrl} title={Data && Data[0].title + " - News"} hashtag={"News"}>
                                                <FacebookIcon size={35} className="rounded" />
                                            </FacebookShareButton>
                                            <WhatsappShareButton url={shareUrl} title={Data && Data[0].title + " - News"} hashtag={"News"}>
                                                <WhatsappIcon size={35} className="rounded" />
                                            </WhatsappShareButton>
                                            <TwitterShareButton url={shareUrl} title={Data && Data[0].title + " - News"} hashtag={"News"}>
                                                <TwitterIcon size={35} className="rounded" />
                                            </TwitterShareButton>
                                        </div>
                                    </div>

                                    {adddata.length > 0 && adddata?.map((item) => {
                                        if (item.ad_space === "breaking-news-detail-5") {
                                            return (
                                                <a target="black" href={item.ad_url}>
                                                <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3"alt="icon" /></a>
                                            );
                                        }

                                    })}
                                    {/* <p id='nv-description' dangerouslySetInnerHTML={{__html: Data[0].description}}></p> */}
                                    {/* <CommentSection BNid={BNid} /> */}

                                </div>
                                <div className="col-md-5 col-lg-4 col-12">
                                    {/* <div id="B_NV-right-section"> */}
                                    <div >
                                        <RelatedBreakingNews />
                                        <TagsSection />
                                    </div>
                                </div>
                            </div>

                            {adddata.length > 0 && adddata?.map((item) => {
                                if (item.ad_space === "breaking-news-detail-6") {
                                    return (
                                        <a target="black" href={item.ad_url}>
                                        <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3"alt="icon" /></a>
                                    );
                                }

                            })}

                            {/* <img src="/images/54.png" className="add_without_border mb-4" alt="icon"></img> */}
                            <VideoPlayerModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                // backdrop="static"
                                keyboard={false}
                                url={Video_url}
                            // title={Data[0].title}
                            />
                        </div>

                    </div>
                </>
            )}
        </>
    );
}

export default BreakingNews;
