import photo from "../images/Login.jpg";
import React, { useState, useEffect } from "react";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/fa/eye";
import { eyeSlash } from "react-icons-kit/fa/eyeSlash";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router";
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { BsCheckLg } from 'react-icons/bs';
import { register } from "../store/reducers/userReducer";
import { translate } from "../utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { webSettingsData } from "../store/reducers/websettingsReducer";
import {useThemeMode} from '../utils/themeMode'


function RagisterModalTwo(props) {
    const {mode} = useThemeMode();
    const initialValues = { username: "", email: "", password: "", confirmpassword: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState(
        "",
        setTimeout(() => {
            if (formErrors !== "") setFormErrors("");
        }, 5000)
    );
    const [isValidForm, setIsValidForm] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [type, setType] = useState("password");
    const [type2, setType2] = useState("password");
    const [icon, setIcon] = useState(eyeSlash);
    const [icon2, setIcon2] = useState(eyeSlash);
    const [file, setFile] = useState();

    const websettings = useSelector(webSettingsData);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        console.log(formValues);
    };
    const handleConfirmpassword = (e) => {
        const { name, value } = e.target;

        setFormValues({ ...formValues, [name]: value });
        console.log(formValues);
    };

    const navigate = useNavigate();

    useEffect(() => {
        if (isValidForm) {
            setIsSubmit(true);
            const auth = getAuth();
            createUserWithEmailAndPassword(auth, formValues.email, formValues.password)
                .then((userCredential) => {
                    // send verification mail.
                    const user = userCredential.user;
                    sendEmailVerification(auth.currentUser)
                        .then(() => {
                            toast.success("வெற்றிகரமாக உருவாக்கப்பட்டது")
                            const name = formValues.username;
                            const Email = formValues.email;
                            // const Id = formValues.id;

                            register(
                                user.uid,
                                name,
                                Email,
                                "",
                                "email",
                                file,
                                "1",
                                "",
                                (response) => {
                                    updateProfile(user, {
                                        displayName: response.id,
                                    })
                                        .then(() => {
                                            // Profile updated!
                                            // ...
                                        })
                                        .catch((error) => {
                                            // An error occurred
                                            // ...
                                        });

                                    // props.setIsLogout(true)
                                    props.onHide();
                                    props.setLoginModalShow(false);
                                },
                                (error) => {
                                    console.log(error);
                                }
                            );
                        }).catch((error) => console.log(error));
                })

                .catch(function (error) {
                    var errorCode = error.code;
                    var errorMessage;
                    switch (errorCode) {
                        case 'auth/email-already-in-use':
                            errorMessage = 'மின்னஞ்சல் ஏற்கனவே பயன்பாட்டில் உள்ளது.';
                            // Display a message to the user indicating that the email is already in use
                            break;
                        case 'auth/invalid-email':
                            errorMessage = ' தவறான மின்னஞ்சல் முகவரி.';
                            // Display a message to the user indicating that the email address is not valid
                            break;
                        case 'auth/weak-password':
                            errorMessage = 'கடவுச்சொல் மிகவும் பலவீனமாக உள்ளது.';
                            // Display a message to the user indicating that the password is too weak
                            break;
                        default:
                            errorMessage = 'பிழை ஏற்பட்டது:';
                    }
                    toast.error(errorMessage);
                });
        }
        // eslint-disable-next-line
    }, [isValidForm]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
    };

    const handletoggle = () => {
        if (type === "password") {
            setIcon(eye);
            setType("text");
        } else {
            setIcon(eyeSlash);
            setType("password");
        }
    };

    const handleCtoggle = () => {
        if (type2 === "password") {
            setIcon2(eye);
            setType2("text");
        } else {
            setIcon2(eyeSlash);
            setType2("password");
        }
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit); // eslint-disable-next-line
    }, [formErrors]);

    const validate = (values) => {
        const errors = {}; // eslint-disable-next-line
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        // const password_pattern = /^(?=.*\d)(?=.*\[a-z])(?=.*\[A-Z])[a-zA-Z0-9]{8,}$/
        if (!values.username) {
            errors.username = " பயனர் பெயர் தேவை!";
        }

        if (!values.email) {
            errors.email = "மின்னஞ்சல் தேவை!";
        } else if (!regex.test(values.email)) {
            errors.email = "சரியான மின்னஞ்சலை உள்ளிடவும்";
        }
        if (!values.password) {
            errors.password = "கடவுச்சொல் தேவை!";
        } else if (values.password.length < 6) {
            errors.password = "கடவுச்சொல் 6 எழுத்துகளுக்கு மேல் இருக்க வேண்டும்";
        } else if (values.password.length > 12) {
            errors.password = "கடவுச்சொல் 12 எழுத்துகளுக்கு மேல் இருக்கக்கூடாது";
        }
        if (!values.confirmpassword) {
            errors.confirmPassword = " கடவுச்சொல்லை உறுதிப்படுத்த வேண்டும்!";
        } else if (values.confirmpassword === "" || values.confirmpassword !== values.password) {
            errors.confirmPassword = "கடவுச்சொல் பொருந்தவில்லை!";
        } else {
            // console.log("wdjlfbsdchiwSD")
            setIsValidForm(true);
            navigate("/");
        }

        return errors;
    };

    // eslint-disable-next-line
    function handleChange1(e) {
        // console.log(URL.createObjectURL(e.target.files[0]));
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    return (
        <div>
            <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="border-radius-2">
                <div className="ModalWrapper" id="ModalWrapper22">
                    <div style={{ width: "100%", objectFit: "cover", borderRadius: "20px" }} id="login_img3">
                        <img className="ModalImg3" src={photo} alt="" />
                        
                        {/* <div className="logo-text3">
                            <h4>{translate("beautifulltheme")}</h4>
                            <p> {translate("bestinvestment")}</p>
                        </div> */}
                    </div>

                    <div className={mode == 'light' ? "register_popup_register groupformcontrol" : "register_popup_register groupformcontrol bg-dark"} id="modal-content">
                        <Modal.Header className="pb-0" closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <div className="d-flex justify-content-center">
                                    <img src={websettings && websettings.web_header_logo} alt="" id="logo3" />
                                </div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">
                            <div className="">
                            <h1 className={mode == 'light' ? "text-dark text-center my-1 fw-bold " : "text-light text-center my-1 fw-bold "} >{translate("ஒரு கணக்கை உருவாக்க")}</h1>
                        
                                {/* <div className="welcom-back3">
                                    <div>
                                        <h5>
                                            <strong>{translate("Welcome")}</strong>
                                        </h5>
                                        <div id="Welcom" style={{ fontSize: "14px" }}>
                                            {" "}
                                            {translate("register-daily-news")}
                                        </div>
                                    </div>
                                    <div id='textwithimage'>
                                        <div className="my-3">
                                            <h5>Add Profile:</h5>
                                            <input className='input mx-1' type="file" name="image" onChange={handleChange1} required />

                                        </div>
                                        <div>
                                            <img src={file} className="imageInput rounded float-end" alt="..."></img>
                                        </div>
                                    </div>
                                </div> */}
                                <form className="" onSubmit={handleSubmit}>
                                    <div>
                                        {/* <div className="form-floating mb-3 mt-4"> */}
                                        <div className={formErrors ? "mb-1 mt-2" : "mb-3 mt-2"}>
                                            <label htmlFor="floatingInput" name="name" className={mode == 'light' ? "text-dark" : "text-light"}>
                                                {translate("பயனர் பெயர்")}
                                            </label>
                                            <div class="input-group">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
                                                  </div>
                                                  <input type="text" className="form-control" name="username" id="floatingInput" placeholder="உங்கள் பயனர் பெயரை உள்ளிடவும்" aria-describedby="Username" value={formValues.username} onChange={handleChange} />
                                                </div>
                                           
                                            <p className="error-msg"> {formErrors.username}</p>

                                        </div>
                                        {/* <div className="form-floating mb-3"> */}
                                        <div className={formErrors ? "mb-1" : "mb-3"}>
                                            <label htmlFor="floatingInput" name="Email" className={mode == 'light' ? "text-dark" : "text-light"}>
                                                {translate("மின்னஞ்சல் முகவரி")}
                                            </label>
                                            <div class="input-group">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                                                  </div>
                                                  <input type="text" className="form-control" name="email" id="floatingInput" placeholder="உங்கள் மின்னஞ்சல் முகவரியை உள்ளிடவும் " aria-describedby="emailHelp" value={formValues.email} onChange={handleChange} />
                                                </div>
                                           
                                            <p className="error-msg"> {formErrors.email}</p>

                                        </div>
                                        {/* <div className="form-floating mb-3"> */}
                                        <div className={formErrors ? "mb-1" : "mb-3"}>
                                            <label htmlFor="floatingPassword" className={mode == 'light' ? "text-dark" : "text-light"}>{translate("கடவுச்சொல்")}</label>
                                             <div class="input-group">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock" aria-hidden="true"></i></span>
                                                  </div>
                                                  <input type={type} className="form-control" id="floatingPassword" placeholder="கடவுச்சொல்லை உள்ளிடவும்" name="password" value={formValues.password} onChange={handleChange} />
                                                  
                                                    <span onClick={handletoggle} className="password-icon-1 pe-2 pt-1">
                                                      <Icon icon={icon} size={18} />
                                                    </span>
                                                </div>
                                           

                                           
                                            <p className="error-msg"> {formErrors.password}</p>
                                        </div>
                                        {/* <div className="form-floating mb-3"> */}
                                        <div className={formErrors ? "mb-1" : "mb-3"}>
                                            <label htmlFor="floatingConfirmPassword" className={mode == 'light' ? "text-dark" : "text-light"}>{translate("கடவுச்சொல்லை உறுதிப்படுத்தவும்")}</label>
                                           
                                            <div class="input-group">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock" aria-hidden="true"></i></span>
                                                  </div>
                                                  <input type={type2} className="form-control" id="floatingPassword" placeholder="கடவுச்சொல்லை மீண்டும் தட்டச்சு செய்க" name="confirmpassword" value={formValues.confirmPassword} onChange={handleConfirmpassword} />
                                                  <span onClick={handleCtoggle} className="password-icon-1 pe-2 pt-1">
                                                <Icon icon={icon2} size={18} />
                                            </span>
                                                
                                                </div>
                                           
                                            <p className="error-msg"> {formErrors.confirmPassword}</p>
                                        </div>
                                        <div className="py-3 d-flex justify-content-center align-items-center">
                                            <button type="submit" className="btn btn-lg" id="loginbutton2">
                                                {translate("பதிவு")}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </Modal.Body>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default RagisterModalTwo;
