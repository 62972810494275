import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { getpagesApi } from "../store/actions/campaign";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";
import {useThemeMode} from '../utils/themeMode';

function Complaints(props) {
    const {mode, menuMode, updateMenuMode} = useThemeMode();
    const [loading, setLoading] = useState(true);

    const [Data, setData] = useState({
        page_content: "",
    });

    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        setLoading(true);
        getpagesApi(
            (response) => {
                let Complaints = response.data.filter((data) => data.title === "complaints");
                setData(Complaints[0]);
                setLoading(false);
            },
            (error) => {
                setData("");
                setLoading(false);
                console.error(error);
            }
        );
    }, [currentLanguage]);

    return (
        <div className="container py-4">
            {loading ? <Skeleton height={400} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} /> : <div>{Data?.page_content ? <p id="pp-modal-body" className="mb-0" dangerouslySetInnerHTML={{ __html: Data.page_content }}></p> : <div className=""> <img src="/images/no_image.jpg" className="no_img my-5" alt="icon" /> </div>}</div>}
       </div>
    );
}

export default Complaints;
