import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import React from "react";
import { getbreakingNewsApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { translate } from "../utils";
import no_image from "../images/no_image.jpg";

function RelatedBreakingNews() {
    const [Data, setData] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        getbreakingNewsApi(
            (response) => {
                const responseData = response.data;
                setData(responseData);
            },
            (error) => {
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setData("");
                }
            }
        );
    }, [currentLanguage]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    return (
        <div>
            {Data.length === 0 ? (
                ""
            ) : (
                // <div id="rbn-main">
                <div>
                    <nav id="rbn-cat-nav" className="navbar">
                        <h4 id="nav-logo">
                            <b>{translate("தொடர்புடைய செய்திகள்...")}</b>
                        </h4>
                    </nav>
                    {/* <div className="scrol_listinf"> */}
                    {Data.length > 0 &&
                        Data?.slice(0, 6).map((element) => (
                            <div key={element.id} id="rbn-main" className=" mb-3">
                                <a 
                                    onClick={() => scrollToTop()} 
                                    id="Link-all" 
                                    href={element.slug}
                                    // to={"/breaking-news?BNid=" + element.id}
                                    >
                                    {/* <div id="rbn-card" className="card row"> */}
                                    <div className="row">
                                        <div className="col-4">
                                            {/* <img id="rbn-image" src={element.image} className="card-img-top" alt="..." /></div> */}
                                            <img id="" src={element.image ? element.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top rbn-image" alt="..." /></div>
                                        {/* <div id="rbn-card-body" className="rbn-card-body"> */}
                                        <div className="col-8">
                                        <Link onClick={() => scrollToTop()}
                                         id="btnB_NVCatagory" className="btn btn-sm" type="button"
                                          to="/breaking-news-view"
                                          >
                                            {/* <Link onClick={() => scrollToTop()}
                                             id="btnrbnCatagory" className="btn btn-sm" type="button"
                                             to={"/breaking-news?BNid=" + element.id}> */}
                                                {translate("Breaking News")}
                                            </Link>
                                            <a onClick={() => scrollToTop()}
                                            //  to={"/breaking-news?BNid=" + element.id}
                                            href= {element.slug}
                                             >
                                                <h6 id="rbn-card-text " className="card-text text-dark two_line">
                                                    {element.title}
                                                </h6>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        ))}
                    {/* </div> */}
                </div>
            )}
        </div>
    );
}

export default RelatedBreakingNews;
