import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import photo from "../images/Login.jpg";
import { Icon } from "react-icons-kit";
import { eye } from "react-icons-kit/fa/eye";
import { eyeSlash } from "react-icons-kit/fa/eyeSlash";
import { FaFacebookF, FaGoogle, FaMobileAlt } from "react-icons/fa";
import { signInWithPopup, FacebookAuthProvider, GoogleAuthProvider, signInWithEmailAndPassword, getAuth, sendEmailVerification } from "firebase/auth";
import { authentication } from "../Firebase";
import { register } from "../store/reducers/userReducer";
import PhoneLoginTwo from "./PhoneLoginTwo";
import RagisterModalTwo from "./RegisterModalTwo";
import ForgotPasswordTwo from "./ForgotPasswordTwo";
import { translate } from "../utils";
import { toast } from "react-toastify";
import { webSettingsData } from "../store/reducers/websettingsReducer";
import { useSelector } from "react-redux";
import {useThemeMode} from '../utils/themeMode'
// import firebase from 'firebase/app';

function SignInModal(props) {
    const {mode} = useThemeMode();
    const [modalShow, setModalShow] = React.useState(false);
    const [ForgotModalShow, setForgotModalShow] = React.useState(false);
    const [PhoneModalShow, setPhoneModalShow] = React.useState(false);
    // const handleClose = () => props.setPrivacy(false);

    const initialValues = { email: "", password: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState(
        "",
        setTimeout(() => {
            if (formErrors !== "") setFormErrors("");
        }, 5000)
    );
    const [isSubmit, setIsSubmit] = useState(false);
    const [type, setType] = useState("password");
    const [icon, setIcon] = useState(eyeSlash);

    const websettings = useSelector(webSettingsData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        // console.log(name)
        setFormValues({ ...formValues, [name]: value });
        // console.log(formValues);
    };
    // const navigate = useNavigate()

    // form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        // navigate('/')
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit);
    }, [formErrors, isSubmit]);

    // validate email
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/i;
        // const regex = /^[^\s@]+@[^s]+\.[^\s@]{2,}$/i;
        if (!values.email) {
            errors.email = "மின்னஞ்சல் தேவை!";
        } else if (!regex.test(values.email)) {
            errors.email = "சரியான மின்னஞ்சலை உள்ளிடவும்";
        }
        if (!values.password) {
            errors.password = " கடவுச்சொல் தேவை!";
        } else if (values.password.length < 6) {
            errors.password = " கடவுச்சொல் 6 எழுத்துகளுக்கு மேல் இருக்க வேண்டும்";
        } else if (values.password.length > 12) {
            errors.password = "கடவுச்சொல் 12 எழுத்துகளுக்கு மேல் இருக்கக்கூடாது";
        }
        return errors;
    };

    // password
    const handletoggle = () => {
        if (type === "password") {
            setIcon(eye);
            setType("text");
        } else {
            setIcon(eyeSlash);
            setType("password");
        }
    };

    // sigin facebook
    const signInWithFacebook = () => {
        const provider =  new FacebookAuthProvider();
        console.log("ewrlheiuwrgwiuer", provider);
        signInWithPopup(authentication, provider)
            .then((re) => {
                console.log("eriwhreiureuigweruigwer",re);
                props.onHide();
                props.setIsLogout(true);

                register(
                    re.user.uid,
                    re.user.displayName,
                    formValues.email,
                    "",
                    "fb",
                    "",
                    "1",
                    "",
                    (response) => {
                        props.setisloginloading(false);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    // sign in google
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(authentication, provider)
            .then((response) => {
                props.onHide();
                props.setIsLogout(true);

                register(
                    response.user.uid,
                    response.user.displayName,
                    formValues.email,
                    "",
                    "gmail",
                    response.user.photoURL,
                    "1",
                    "",
                    (response) => {
                        props.setisloginloading(false);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            })
            .catch((err) => {
                console.log(err.message);
            });
    };
    // eslint-disable-next-line
    const [phonenum, setPhonenum] = useState(null);
    const auth = getAuth();

    // sign in with email and password
    const Signin = async () => {
        await signInWithEmailAndPassword(auth, formValues.email, formValues.password)
            .then(async (userCredential) => {
                // Signed in
                const user = userCredential.user;

                if (user.emailVerified) {
                    // alert("Verified")
                    props.setIsLogout(true);
                } else {
                    toast.error("வெற்றிகரமாக உள்நுழைக")
                    sendEmailVerification(auth.currentUser);
                }
                // props.setIsLogout(true)
                props.onHide();

                register(
                    user.uid,
                    "",
                    formValues.email,
                    "",
                    "email",
                    "",
                    "1",
                    "",
                    (response) => {
                        props.setisloginloading(false);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            })
            .catch(function (error) {
                var errorCode = error.code;
                var errorMessage;
                switch (errorCode) {
                    case 'auth/invalid-email':
                        errorMessage = 'தவறான மின்னஞ்சல். சரியான மின்னஞ்சலை உள்ளிட்டு மீண்டும் முயற்சிக்கவும்.';
                        break;
                    case 'auth/wrong-password':
                        errorMessage = 'தவறான கடவுச்சொல். சரியான கடவுச்சொல்லை உள்ளிட்டு மீண்டும் முயற்சிக்கவும்.';
                        break;
                    case 'auth/user-not-found':
                        errorMessage = 'பயனர் கிடைக்கவில்லை. உங்கள் மின்னஞ்சலைச் சரிபார்த்து மீண்டும் முயற்சிக்கவும்.';
                        break;
                    case 'auth/user-disabled':
                        errorMessage = 'இந்தக் கணக்கு முடக்கப்பட்டுள்ளது. உதவிக்கு ஆதரவைத் தொடர்பு கொள்ளவும்.';
                        break;
                    // handle other error codes as needed
                    default:
                        errorMessage = 'பிழை ஏற்பட்டது. பிறகு முயற்சிக்கவும்.';
                }
                // display error message in a toast or alert
                toast.error(errorMessage);
            });
    };

    return (
        <>
            <div>
                <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="border-radius-2">
                    <div className="ModalWrapper" id="ModalWrapper">
                        <div style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "20px" }} id="login_img1">
                            <img className="ModalImg" src={photo} alt="" />
                           
                            {/* <div className="logo-text">
                                <h4>{translate("beautifulltheme")}</h4>
                                <p> {translate("bestinvestment")}</p>
                            </div> */}
                        </div>
                        <div className={mode == 'light' ? "model_login_popup groupformcontrol" : "model_login_popup groupformcontrol bg-dark text-light"} id="modal-content">
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pt-0">
                                <div className="form_login_bg">
                                    <div>
                                        <div className="welcom-back mt-0">
                                        <div className="d-flex justify-content-center">
                                <img src={websettings && websettings.web_header_logo} alt="" id="logo1" />
                            </div>
                            <h1 className={  mode == 'light' ? "text-dark text-center my-3 fw-bold" : "text-light text-center my-3 fw-bold"} >{translate("உள்நுழைய")}</h1>
                                            {/* <h5>
                                                <strong>{translate("welcomeback")}</strong>
                                            </h5> */}
                                            {/* <div id="Welcom" style={{ fontSize: "14px" }}>
                                                {" "}
                                                {translate("enter-email-password")}
                                            </div> */}
                                        </div>
                                        <form className="my-2" onSubmit={handleSubmit}>
                                            {/* <div className="form-floating mb-3"> */}
                                            <div className="mb-3">
                                                <label htmlFor="floatingInput" className={mode == 'light' ? "text-dark" : "text-light"}>{translate("மின்னஞ்சல் முகவரி")}</label>
                                                <div class="input-group">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                                                  </div>
                                                  <input type="text" className="form-control border-0" name="email" id="floatingInput" placeholder="உங்கள் மின்னஞ்சல் முகவரியை உள்ளிடவும்" aria-describedby="emailHelp" value={formValues.email} onChange={handleChange} />
                                                </div>
                                                <p className="error-msg"> {formErrors.email}</p>
                                                

                                            </div>
                                            
                                            {/* <div className="form-floating mb-3"> */}
                                            <div className="mb-3">
                                                <label htmlFor="floatingPassword" className={mode == 'light' ? "text-dark" : "text-light"}>{translate("கடவுச்சொல்")}</label>

                                                <div class="input-group">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-lock" aria-hidden="true"></i></span>
                                                  </div>
                                                  <input type={type} className="form-control border-0" id="floatingPassword" placeholder="உங்கள் கடவுச்சொல்லை உள்ளிடவும்" name="password" value={formValues.password} onChange={handleChange} />
                                                  <span onClick={handletoggle} className="password-icon-1 pe-2 pt-1">
                                                    <Icon icon={icon} size={18} />
                                                 </span>
                                                </div>
                                                <p className="error-msg">{" "}{formErrors.password}</p>
                                            </div>
                                            <div className="text-end pb-2">
                                                <span className="fs-7">
                                                <a onClick={() => {
                                                        props.onHide();
                                                        setModalShow(false);
                                                        setPhoneModalShow(false);
                                                        setForgotModalShow(true);
                                                    }}
                                                    id=""
                                                    className={mode == 'light' ? "text-dark" : "text-light"}
                                                >
                                                    {" "}
                                                    {translate("கடவுச்சொல்லை மறந்துவிட்டீர்களா?")}{" "}
                                                </a>
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-center w-100 pb-xl-3" id="login">
                                                <button type="submit" className="btn btn-lg w-100" id="loginbutton" onClick={Signin}>
                                                    {translate("உள்நுழைய")}
                                                </button>
                                            </div>
                                            <div className="d-block justify-content-between align-items-center">
                                                <div className="footer text-start">
                                                    <p className="">
                                                        {/* {translate("donthaveacc_lbl")} */}
                                                            <a className={mode == 'light' ? "text-dark" : "text-light"}
                                                            onClick={() => {
                                                                // console.log("click")
                                                                props.onHide();
                                                                setPhoneModalShow(false);
                                                                setForgotModalShow(false);
                                                                setModalShow(true);
                                                            }}>
                                                            {" "}
                                                            {translate("கணக்கு இல்லையா?")}{" "}{translate("இங்கே பதிவு செய்யவும்")}
                                                            {/* <span className="text-theme fw-blold"></span> */}
                                                        </a>
                                                    </p>
                                                </div>                                            
                                            </div>
                                        </form>
                                        <div className={mode == 'light' ? "bordert mx-3 my-3 py-2" : "mx-3 my-3 py-2"}></div>
                                    </div>
                                    <div className="container px-0" id="social_buttons">
                                        <div className="row">
                                            <div className="col-lg-6 col-12">
                                                <button id="login-social1" type="button" className=" btn " onClick={signInWithFacebook}>
                                                    <FaFacebookF /><p>{translate("Signin with facebook")}</p>
                                                </button>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <button id="login-social2" type="button" className=" btn " onClick={signInWithGoogle}>
                                                    <FaGoogle /><p>{translate("Signin with google")}</p>
                                                </button>
                                            </div>
                                            {/* <div className="col-lg-4 col-12">
                                                <Button
                                                    id="login-social3"
                                                    type="button"
                                                    className=" btn "
                                                    onClick={() => {
                                                        props.onHide();
                                                        setModalShow(false);
                                                        setPhoneModalShow(true);
                                                        setForgotModalShow(false);
                                                    }}
                                                >
                                                    <FaMobileAlt /><p>{translate("Signin with phone")}</p>
                                                </Button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>

                            
                            {/* <Modal.Footer className="footer">
                            {/* <Button onClick={props.handleClose}>Close</Button> */}
                            {/* <h6 className="">Don't have an account?<Link to="/register">Register </Link></h6>
                        </Modal.Footer> */}
                        </div>
                    </div>
                </Modal>
            </div>
            <ForgotPasswordTwo setLoginModalShow={props.setLoginModalShow} show={ForgotModalShow} onHide={() => setForgotModalShow(false)} />
            <RagisterModalTwo setIsLogout={props.setIsLogout} setLoginModalShow={props.setLoginModalShow} show={modalShow} onHide={() => setModalShow(false)} />
            <PhoneLoginTwo setisloginloading={props.setisloginloading} setIsLogout={props.setIsLogout} setLoginModalShow={props.setLoginModalShow} setPhonenum={setPhonenum} show={PhoneModalShow} onHide={() => setPhoneModalShow(false)} />
        </>
    );
}

export default SignInModal;
