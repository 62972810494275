import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import { getAddUrl, spritualnews, todayspritualnews } from "../utils/api"
import { useSelector } from "react-redux";
import { loadLanguageLabels, loadLanguages, selectCurrentLanguage, selectLanguages, setCurrentLanguage } from "../store/reducers/languageReducer";
import { webSettingsData } from "../store/reducers/websettingsReducer";
import no_image from "../images/no_image.jpg"
import { setActiveLink } from "../utils";
import {useThemeMode} from '../utils/themeMode'
SwiperCore.use([Navigation, Pagination]);

function SpritualNews() {
    const {mode} = useThemeMode();
    const [Data, setData] = useState([]);

    const showNavigation = Data.length > 1;

    const swiperOption = {
        loop: true,
        speed: 750,
        autoplay: true,
        spaceBetween: 10,
        slidesPerView: 2,
        navigation: showNavigation,
        infinite: false,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            567: {
                slidesPerView: 1,
            },
            1200: {
                slidesPerView: 1,
            },
        },
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            console.log("after change", currentSlide);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };
   

    const swiperRef = useRef();
    const languagesData = useSelector(selectLanguages);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const websettings = useSelector(webSettingsData);

    const [adddata, setadddata] = useState([]);

    const fetchadd = async () => {
        const response = await getAddUrl();
        setadddata(response.data?.data)
        console.log("jtjeyrgtjehrtbjher", response);
    }

    useEffect(() => {
        fetchadd();
    }, [currentLanguage])


    const [spritual, setspritualnews] = useState([]);

    const fetchsetspritualnews = async () => {
        const response = await spritualnews();
        if(response.data) {
            setspritualnews(response.data?.data)
            console.log("jhgspritualnews", response);
        }
    }

    useEffect(() => {
        fetchsetspritualnews();
    }, [currentLanguage])


    const [newstoday, setnewstoday] = useState([]);

    const fetchnewstoday = async () => {
        const response = await todayspritualnews();
        if(response.data) {
        setnewstoday(response.data?.data)
        console.log("jhgtofayuews", response);
        }
    }

    useEffect(() => {
        fetchnewstoday();
    }, [currentLanguage])

    const currentDates = new Date(); // Current date and time

    const filtered = newstoday.filter((value) => {
        const showTillDate = new Date(value.show_till);
        return currentDates <= showTillDate; // Compare current date with show_till date
    });

    const currentDate = new Date(); // Current date and time

    const filteredSpritual = spritual.filter((value) => {
        const showTillDate = new Date(value.show_till);
        return currentDate <= showTillDate; // Compare current date with show_till date
    });

    useEffect(() => {
        let ajfhudgf = document.getElementById('ljwheriwgergweurwuerweur')?.getElementsByTagName("p");
        if(ajfhudgf && ajfhudgf?.length > 0) {
        document.getElementById('elrhfweiurgwiergewrwheg').innerHTML = ajfhudgf[0]?.textContent;
        }
    }, [filteredSpritual]);

    return (
        <>
            <div className="container pt-2 pt-lg-4 pb-0">

                <div className="mb-3 mb-lg-4">
                    <div className="banner_home ">
                        {adddata.length > 0 && adddata?.map((item) => {
                            if (item.ad_space === "home_page-3") {
                                return (
                                    <a target="black" href={item.ad_url}>
                                         <img  src={item.ad_image ? item.ad_image : no_image} 
                    onError={(e) => { e.target.src = no_image;}}
                    className="w-100 mb-3"
                    alt="icon"
                />
                                         {/* <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /> */}
                                         </a> 
                                );
                            }
                        })}
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-8 ">
                        <div className="d-flex align-items-center mb-4 justify-content-between"> 
                            <div className="d-flex align-items-center"> <div className="border_strong_head me-2"></div>  <h4 className="mb-0 text-theme" id="hns-main-logo">ஆன்மிகம்</h4></div>
                            <Link onClick={() => scrollToTop()} href="/" id="hns-Viewmore" to={"/category-view?id=5&uid=1&name=ஆன்மிகம்"}><img src="/images/8.png" className="arrow_icon" alt="icon" /></Link>
                         </div>
                        <div className="row">
                            
                                {filteredSpritual.length > 0 ? (
                                    filteredSpritual.slice(0, 1).map((item) => (
                                        <div className="col-md-5 mb-md-0 mb-3">
                                            <Link 
                                                onClick={() => { scrollToTop(); setActiveLink() }} 
                                                key={item.id} 
                                                // href={item.slug}
                                                // to={"/news-view?Nid=" + item.id + "&Cid=" + item.category_id}
                                                to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + item.subcategory_name}
                                                >
                                                <div className="card h-100 border-0">
                                                    <img src={item.image ? item.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top img_sritual_ju1" alt="icon" />
                                                    <div className="card-block mt-3">
                                                        <p className={mode == 'light' ? "card-title three_line text-dark mb-0" : "card-title three_line text-light mb-0"}>{item.title}</p>
                                                        <div className="my-1 my-xl-3">
                                                            {/* <span className="text-san-1"> <i className="fs-6px fa fa-circle" aria-hidden="true"></i> ஆன்மிகம்</span> <span className="px-1 px-xl-2 text-gray">|</span>  */}
                                                            <span className="text-san-2">{item.date}</span>
                                                        </div>
                                                        <p className="d-none" id="ljwheriwgergweurwuerweur" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                        <p id="elrhfweiurgwiergewrwheg" className={mode == 'light' ? "text-dark four_line" : "text-light four_line"}></p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <div className="col-12 text-center mt-4">
                                        <p>வேறு தகவல்கள் இல்லை</p>
                                    </div>
                                )}

                            <div className="col-md-7">
                                <div className="row">
                                    {
                                        filteredSpritual.length > 1 &&
                                        filteredSpritual?.slice(1, 5).map((item) => {
                                            {/* {spritual.length > 1 && spritual?.slice(1, 5).map((item) => { */ }
                                            {
                                                return (
                                                    <div className="col-6 mb-4">
                                                        <Link  
                                                           onClick={() => { scrollToTop(); setActiveLink() }} 
                                                           to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + item.subcategory_name}
                                                        //    to={"/news-view?Nid=" + item.id + "&Cid=" + item.category_id}
                                                        // href={item.slug}
                                                           >
                                                            <div className="card h-100 border-0">
                                                                <img src={item.image ? item.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top img_sritual_ju" alt="icon" />
                                                                <div className="card-block mt-3">
                                                                    <p className={mode == 'light' ? "card-title two_line text-dark" : "card-title two_line text-light"}>{item.title}</p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                );
                                            }

                                        })}
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 ps-xl-5 position-relative">
                        <div className="d-flex align-items-center mb-4 mt-3"> <div className="border_strong_head me-2"></div> <h4 className="mb-0 text-theme" id="hns-main-logo">சமூக வலைதளம்</h4> </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-6">
                                <Swiper modules={[Navigation]}
                                    onBeforeInit={(swiper) => {
                                        swiperRef.current = swiper;
                                    }} {...swiperOption} className="h-auto mb-lg-4 mb-md-0 mb-3 navigation">
                                    {/* {newstoday.length > 0 && newstoday?.map((item) => { */}
                                    {filtered.length > 0 &&
                                        filtered?.map((item) => {
                                            {
                                                return (<SwiperSlide className="">
                                                    <div className="card card-spritual-crd">
                                                        <div className="d-flex align-items-center">
                                                            <img src={item.image ? item.image : no_image} onError={(e) => { e.target.src = no_image;}} className="img_sritual_round me-3" alt="icon" />
                                                            <h4 className={mode == 'light' ? "fw-bold one_line text-dark" : "fw-bold one_line text-light"}>{item.title}</h4>
                                                        </div>
                                                        <div className="card-block mt-3">
                                                            <p className="card-title five_line text-gray" dangerouslySetInnerHTML={{ __html: newstoday[0].description }}></p>
                                                            {item.subcategory_name &&
                                                            <div className="visf_colrty mt-3">
                                                                <span className="me-4">{item.subcategory_name}</span>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                                );
                                            }
                                        })}
                                </Swiper>

                                <div className="nav_position">
                                    <button className="nav-review" onClick={() => swiperRef.current?.slidePrev()}>
                                        <img src="/images/4.png" className="img_nv pre1" alt="icon" />
                                        <img src="/images/5.png" className="img_nv pre2" alt="icon" />
                                    </button>
                                    <button className="nav-next" onClick={() => swiperRef.current?.slideNext()}>
                                        <img src="/images/6.png" className="img_nv next1" alt="icon" />
                                        <img src="/images/7.png" className="img_nv next2" alt="icon" />
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-6">
                                {adddata.length > 0 && adddata?.map((item) => {
                                    if (item.ad_space === "home_page-2") {
                                        return (
                                            <a target="black" href={item.ad_url}> 
                                               <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 img_sidebar" alt="icon" />
                                            </a>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SpritualNews;
