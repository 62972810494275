import React, { useState } from "react";
import breakingNews2_jpg from "../images/earthImage.png";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getbreakingNewsApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { translate } from "../utils";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Skeleton from "react-loading-skeleton";
import no_image from "../images/no_image.jpg";
import { informationnews } from "../utils/api"
import {useThemeMode} from '../utils/themeMode'

SwiperCore.use([Navigation, Pagination]);

function BreakingNewsSection() {
    const {mode} = useThemeMode();
    const [Data, setData] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [isLoading, setIsLoading] = useState(true);


    // useEffect(() => {
    //     getbreakingNewsApi(
    //         (response) => {
    //             const responseData = response.data;
    //             setData(responseData);
    //             setIsLoading(false);
    //         },
    //         (error) => {
    //             setIsLoading(false);
    //             if (error === "வேறு தகவல்கள் இல்லை") {
    //                 setData("");
    //             }
    //         }
    //     );
    // }, [currentLanguage]);

    const fetchnewstoday = async () => {
        const response = await informationnews();
        if(response.data) {
        setData(response.data?.data)
        setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchnewstoday();
    }, [currentLanguage])

    const currentDate = new Date(); // Current date and time

    const filteredSpritual = Data.filter((value) => {
        const showTillDate = new Date(value.show_till);
        return currentDate <= showTillDate; // Compare current date with show_till date
    });

    const showNavigation = Data.length > 1;

    const swiperOption = {
        // loop:true,
        speed: 750,
        spaceBetween: 20,
        slidesPerView: 2,
        infinite: false,
        // navigation: showNavigation,
        breakpoints: {
            0: {
                slidesPerView: 2,
            },
            567: {
                slidesPerView: 2,
            },

            768: {
                slidesPerView: 2,
            },

            992: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
        autoplay: true,
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    

    return (
        <>
            <div id="bns-main" className={mode == 'light' ? "" : "bns-main-dark"}>
                <div className="container custom-card">
                    
                    <div className="row ">
                        <div id="rns-head-main" className="">
                            <div className="d-flex align-items-center"><div className="border_strong_head me-2"></div> <h4 className="mb-0" id="rns-main-logo">{translate("இந்தியா")}</h4></div>
                            <Link className="cursor-pointer" id="vps-Viewmore" to={"/category-view?id=25&uid=1&name=இந்தியா&pos=fifth-section"} onClick={() => scrollToTop()}>
                                {/* {translate("மேலும் வாசிக்க")} */}
                                <img src="/images/8.png" className="arrow_icon" alt="icon"></img>
                            </Link>
                        </div>
                        <div className="col-md-4 col-lg-3 col-12 mb-md-0 mb-3 d-none">
                            <div id="bns-main-card" className="card">
                                <img id="bns-main-image" src={breakingNews2_jpg} className="card-img" alt="..." />
                                <div id="bns-main-text" className="card-img-overlay">

                                   
                                    <div className="">
                                        <h2 className="txt-dec" viewBox="0 0 1620 300">
                                            <text x="0%" y="50%" dy=".35em" text-anchor="center" >
                                                {translate("தகவல்")}
                                            </text> 
                                        </h2>
                                        <h2 className="txt-dec" viewBox="0 0 1620 300">
                                            <text x="0%" y="50%" dy=".35em" text-anchor="center" >
                                                {translate("தொழில்நுட்பம்")}
                                            </text>
                                        </h2>
                                    </div>
                                    {/* <p id="bns-logo-col" className="card-text"> <b> {translate("தகவல்")} <br /> {translate("தொழில்நுட்பம்")}</b></p> */}
                                    {/* <p id="bns-logo-row" className="card-text"> <b>{translate("Breaking News")}</b> </p> */}
                                    <Link id="btnbnsViewAll" className="btn" type="button" to={"/category-view?id=14&uid=1&pos=fifth-section"} onClick={() => scrollToTop()}>
                                        {translate("மேலும் வாசிக்க")} <img src="/images/8.png" className="arrow_icon" alt="icon"></img>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 ">
                            {isLoading ? <Skeleton height={300} count={1} /> :
                            <div id="bns-rest-cards">
                                <Swiper {...swiperOption} >
                                    {/* {Data.length > 0 &&  Data?.map((element) => { */}
                                    {filteredSpritual.length > 0 ?
                                        filteredSpritual.map((element) => {
                                            {
                                                return (

                                                    <SwiperSlide key={element.id} className="">
                                                        {/* <div id="bns-card" className="card" key={element.id}> */}
                                                        <div id="bns-card-set" className="card border-0" key={element.id}>
                                                            <Link 
                                                            onClick={() => scrollToTop()} 
                                                            id="Link-all" 
                                                            // to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id} 
                                                            to={"/news-view?slug=" + element.slug + "&c=" + element.category_name + "&sc=" + element.subcategory_name+ "&pos=fifth-section"} 
                                                            // href={element.slug}
                                                            >
                                                                <div className="zoom">
                                                                    <img id="bns-image" src={element.image ? element.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top" alt="..." /></div>
                                                                {/* <div id="bns-card-body" className="card-body px-2"> */}
                                                                <div className="card-body px-2 pt-0">
                                                                    {/* <Link id="btnbnsCatagory" className="btn btn-sm" type="button" to="/breaking-news-view">
                                                                            {translate("Breaking News")}
                                                                        </Link> */}
                                                                    <p id="bns-card-text" className={mode == 'light' ? "mb-2" : "mb-2 text-light"}>
                                                                        {element.title}
                                                                    </p>
                                                                    <a 
                                                                    // to={"/breaking-news?BNid=" + element.id} 
                                                                    href={element.slug}
                                                                    >மேலும் படிக்க</a>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    </SwiperSlide>
                                                );
                                            }
                                        }) : (
                                            <div className="col-12 text-center mt-4" style={{height: 200}}>
                                                <p>வேறு தகவல்கள் இல்லை</p>
                                            </div>
                                        )}
                                    {/*  {isLoading ? (
                                            <div className="col-12 loading_data">
                                                <Skeleton height={20} count={22} />
                                            </div>
                                        ) : Data.length > 0 ? (
                                            
                                            Data?.slice(0, 3).map((element) => (
                                                <SwiperSlide key={element.id} className="">
                                                    <div id="bns-card" className="card" key={element.id}>
                                                    <div id="bns-card-set" className="card border-0" key={element.id}>
                                                        <Link id="Link-all" to={"/breaking-news?BNid=" + element.id}>
                                                            <div className="zoom">
                                                            <img id="bns-image" src={element.image ? element.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top" alt="..." /></div>
                                                            <div id="bns-card-body" className="card-body px-2">
                                                            <div className="card-body px-2">
                                                                <Link id="btnbnsCatagory" className="btn btn-sm" type="button" to="/breaking-news-view">
                                                                {translate("Breaking News")}
                                                                </Link>
                                                                <p id="bns-card-text" className="mb-2">
                                                                    {element.title}
                                                                </p>
                                                                <Link to={"/breaking-news?BNid=" + element.id} href="">மேலும் படிக்க</Link>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        ) : (
                                            // Show "வேறு தகவல்கள் இல்லை" message if no data is available
                                            <div className="col-12 no_data mt-5">
                                                <p className="text-center">{translate("நிர்வாக குழுவிலிருந்து தரவைச் சேர்க்கவும்)}</p>
                                            </div>
                                        )} */}
                                </Swiper>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BreakingNewsSection;
