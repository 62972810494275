import React, { useEffect, useState } from 'react'
import HealthNewsSection from './HealthNewsSection'
import BreakingNewsSection from './BreakingNewsSection'
import FirstSection from './FirstSection'
import RecentNewsSection from './RecentNewsSection'
import VideoPlayerSection from './VideoPlayerSection'
import CinemaNews from './CinemaNews'
import SpritualNews from './SpritualNews'
import GoogleNews from './GoogleNews'
import TwitterNews from './TwitterNews'
import FacebookNews from './FacebookNews'
import YouTubeNews from './YouTubeNews'
import InstagramNews from './InstagramNews'
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import {useThemeMode} from '../utils/themeMode'

const HomePage = React.memo(function HomePage() {
    const {mode} = useThemeMode();
    const [loading, setLoading] = useState(true);
    // useEffect(() => {
    //     setTimeout(() => {
    //         window.scrollTo(0, window.scrollY);
    //       }, 1000);
    //   }, []);

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000)
    })

    return (<>
        <div className='sdfjkjfjh'>
            <button type='button' id='lefkhiuwhtiuehrteth' className='d-none' onClick={() => {
                document.getElementById("sixth-section").scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'end',
                    offaet: { top: 35 }
                  });
            }} >lheiruth</button>
            <button type='button' id='ggjhritgiutberjheuy' className='d-none' onClick={() => {
                document.getElementById("fifth-section").scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'end',
                    offaet: { bottom: 35 }
                  });
            }} >lheiruth</button>
            {/* first section */}
            <div id='first-section' className="">
                <FirstSection />
            </div>

            {/*/!* Recent News section *!/*/}
            <div id='second-section' className=''>
                <RecentNewsSection />
            </div>

            {/*/!* SpritualNews *!/*/}
            <div id='third-section' className=''>
                <SpritualNews />
            </div>

            {/*/!* CinemaNews section *!/*/}
            <div id='fourth-section' className=''>
                <CinemaNews />
            </div>

            {/* Breacking News section */}
            <div id='fifth-section' className=''>
                <BreakingNewsSection />
            </div>

            {/* health News Section */}
            <div id='sixth-section' className=''>
                <HealthNewsSection />
            </div>

            {/* Video player section */}
            {/* <div id=''>
                <VideoPlayerSection />
            </div> */}

            {/* <div className='mt-4' id=''>
                <GoogleNews />
            </div> */}



            <div id='seventh-section' className={mode == 'light' ? 'bg-const' : 'bg-const bns-main-dark'}>
                <div className='container'>
                    <div className='row justify-content-center'>

                        {/* <div className='col-md-6 mb-3' id=''>
                            <YouTubeNews />
                        </div> */}
                        <div className='col-md-6 col-xl-4 mb-3' id=''>                            
                            <TwitterNews />
                        </div>
                        <div className='col-md-6 col-xl-4 mb-3' id=''>
                            <InstagramNews />
                        </div>
                        <div className='col-md-6 col-xl-4 mb-3' id=''>
                            <FacebookNews />
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </>)
})

export default HomePage
