import React, { useState, useRef, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { categoriesApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { setActiveLink } from "../utils";
import { useLocation } from 'react-router-dom';
import {useThemeMode} from '../utils/themeMode';
import Form from "react-bootstrap/Form";
// import HorizontalScrollMenu from 'react-horizontal-scrolling-menu';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

function CatNav() {
  const {mode, menuMode, updateMenuMode} = useThemeMode();
    const [Data, setData] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [FontSize, setFontSize] = useState(menuMode);
    const currentLanguage = useSelector(selectCurrentLanguage);

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    useEffect(() => {
      updateMenuMode(FontSize);
      localStorage.setItem('newsFont', FontSize);
      document.documentElement.style.setProperty('--text-size', `${FontSize}px`);
      const allParagraphs = document.querySelectorAll('p');
      const allLinks = document.querySelectorAll('a');

      // const paragraphStyle = {
      //   fontSize: FontSize+'px',
      // };

      // allParagraphs.forEach(paragraph => {
      //   for (const property in paragraphStyle) {
      //     paragraph.style[property] = paragraphStyle[property];
      //   }
      // });
      // allLinks.forEach(paragraph => {
      //   for (const property in paragraphStyle) {
      //     paragraph.style[property] = paragraphStyle[property];
      //   }
      // });
    }, [FontSize, Data])
   
    useEffect(() => {
        categoriesApi(
            "0",
            "100",
            (response) => {
                const responseData = response.data;
                setData(responseData);
               
            },
            (error) => {
                console.error(error);
            }
        );
    }, [currentLanguage]);

    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef(null);

    // handle scroll event
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.pageYOffset > (elTopOffset + elHeight)) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };

    // add/remove scroll event listener
    useEffect(() => {
        var header = headerRef.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height)
        }
          window.addEventListener('scroll', handleScrollEvent);
        return () => {
          window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);
    
    // const [activeLink, setActiveLink] = useState(null);

    const location = useLocation();
    const activeLink = new URLSearchParams(location.search).get('id');


    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [activatedLink, setActivatedLink] = useState('');

    const handleMouseEnter = (category) => {
      setHoveredCategory(category);
    };
  
    const handleMouseLeave = () => {
      setHoveredCategory(null);
    };
   


    const categoryRefs = useRef([]);
  

    const handleCategoryClick = (index) => {
      const categoryElement = categoryRefs.current[index];
      if (categoryElement) {
        const offset = categoryElement.getBoundingClientRect().left;
        window.scrollTo({ left: window.scrollX + offset, behavior: 'smooth' });
      }
    };
    
    const [scrollPosition, setScrollPosition] = useState(0);
    const [navWidth, setNavWidth] = useState(0);
    const navRef = useRef(null);
  
    const handleScrollmen = (scrollAmount) => {
      if (navRef.current) {
        navRef.current.scrollLeft += scrollAmount;
      }
    };
    const handleScrollmens = (scrollAmount) => {
      if (navRef.current) {
        navRef.current.scrollLeft -= scrollAmount;
      }
    };

    return (
        // <div id="sticky-header" className={`navbar${sticky.isSticky ? ' sticky' : ''}`} ref={headerRef}>
        <div id="sticky-header" className="navbar" ref={headerRef}>
            <Navbar id="cn-main" className="w-100">
                <Container>
                    {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                    <Navbar.Collapse id="basic-navbar-nav">
                        <div className="text-white me-lg-3 me-3" onClick={handleShow}>
                            <i className="fa fa-align-left nav_icons_h" aria-hidden="true"></i></div>

                            <a className="me-2" href={window.location?.origin} ><i className="fa fa-home nav_icons_h" aria-hidden="true"></i></a>
                            {/* <Link className="me-2" to="/" ><i className="fa fa-home nav_icons_h" aria-hidden="true"></i></Link> */}
                            <button className="btn_slide px-3 py-2 m-0" onClick={() => {
                              handleScrollmens(200)
                            }}><i class="fa fa-angle-left nav_icons_h" aria-hidden="true"></i></button>
                        

                       
                            {/* sm dropdown */}
                        
                        
                            <Nav ref={navRef} className="mx-auto custome_scroll">
                                <div
                                    className="category-wrapper"
                                    onMouseEnter={() => handleMouseEnter('99999999')}
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    <Link
                                      onClick={() => {
                                        scrollToTop();  
                                        setActivatedLink('99999999');
                                        handleMouseLeave()
                                      }}
                                      id="catNav-links"
                                      to={{
                                        pathname: "/all-news"
                                      }}
                                      className={`${activatedLink === '99999999' ? 'active' : ''}`}  
                                    >
                                      <p>லேட்டஸ்ட்</p>                                      
                                    </Link>                                   
                                  </div>
                                {Data?.length > 0 && Data?.map((element) => {
                                  // setNavWidth(navWidth + document.getElementById(element?.id)?.offsetWidth);
                                return (
                                  <div
                                    key={element?.id}
                                    className="category-wrapper"
                                    id={"navew"+element?.id}
                                    onMouseEnter={() => handleMouseEnter(element.id)}
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    <Link
                                      onClick={() => {
                                        scrollToTop();  
                                        setActiveLink(element.id);
                                        setActivatedLink('');
                                        handleMouseLeave()
                                      }}
                                      id="catNav-links"
                                      key={element?.id}
                                      to={{
                                        pathname: "/category-view",
                                        search: `?id=${element?.id}&uid=1&name=${element?.category_name}`
                                      }}
                                      className={`${activeLink === element.id ? 'active' : ''}`}  
                                    >
                                      <p id={element?.id} className="text-nowrap">{element.category_name}</p>                                      
                                    </Link> 
                                    {hoveredCategory === element.id && (
                                      <div className={mode == 'light' ? `subcategory-dropdown ${activeLink === element.id ? 'active' : ''}` : `subcategory-dropdown bg-dark ${activeLink === element.id ? 'active' : ''}`} style={{ position: 'absolute', left: document.getElementById(hoveredCategory)?.getBoundingClientRect().left}}>
                                          <div className=" custome_dropdown ">
                                            <div className="controls ">
                                              <div className={` ${element.subcategory && element.subcategory.length > 0 ? 'submenu-filled' : ''}`}>
                                                 {element.subcategory?.map((subcategory) => (
                                                 
                                                   <Link
                                                     key={subcategory.id}
                                                     to={{
                                                       pathname: "/category-view",
                                                       search: `?id=${element?.id}&subid=${subcategory.id}&uid=1&name=${element?.category_name}&sname=${subcategory.subcategory_name ?? ''}`,
                                                     }}
                                                     onClick={() => {
                                                      handleMouseLeave();
                                                      scrollToTop();
                                                    }}
                                                   >
                                                     <p className={mode == 'light' ? "one_line light-nav-sub-menu" : "one_line dark-nav-sub-menu"}> 
                                                  
                                                        {subcategory.subcategory_name ?? ''}</p>
                                                   </Link>
                                                    
                                                 ))}
                                                  </div>
                                              </div>
                                            </div>
                                      </div>
                                    )}                                   
                                  </div>
                                )})}
                            </Nav>




                          {/* full width */}

                           {/* <Nav  ref={navRef} className="mx-auto custome_scroll">
                                {Data?.length > 0 && Data?.map((element) => (
                                  <div
                                    key={element?.id}
                                    className="category-wrapper"
                                    onMouseEnter={() => handleMouseEnter(element.id)}
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    <Link
                                      onClick={() => {
                                        scrollToTop();
                                        setActiveLink(element.id);
                                        handleMouseLeave()
                                      }}
                                      id="catNav-links"
                                      key={element?.id}
                                      to={{
                                        pathname: "/category-view",
                                        search: `?id=${element?.id}&uid=1&name=${element?.category_name}`
                                      }}
                                      className={activeLink === element.id ? 'active' : ''}
                                    >
                                      <p>{element.category_name}</p>
                                    </Link>
                                    {hoveredCategory === element.id && (
                                      <div className={`subcategory-dropdown ${activeLink === element.id ? 'active' : ''}`}>
                                          <div className=" custome_dropdown ">
                                            <div className="controls container">
                                              <div className={`row ${element.subcategory && element.subcategory.length > 0 ? 'submenu-filled' : ''}`}>
                                                 {element.subcategory?.map((subcategory) => (
                                                  <div className="col-xl-2 col-lg-3">
                                                   <Link
                                                     key={subcategory.id}
                                                     to={{
                                                       pathname: "/category-view",
                                                       search: `?id=${element?.id}&subid=${subcategory.id}&uid=1&name=${subcategory.subcategory_name}`,
                                                     }}
                                                     onClick={() => {
                                                      handleMouseLeave();
                                                      scrollToTop();
                                                    }}
                                                   >
                                                     <p className="one_line"> 
                                                     <i class="fa fa-star fs-12 me-2" aria-hidden="true"></i>
                                                        {subcategory.subcategory_name}</p>
                                                   </Link>
                                                       </div> 
                                                 ))}
                                                  </div>
                                              </div>
                                            </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </Nav> */}
                          
                            <button className="btn_slide px-3 py-2 m-0" onClick={() => {
                                handleScrollmen(200);
                            }}><i class="fa fa-angle-right nav_icons_h" aria-hidden="true"></i></button>
                          
                            {/* <button className="btn_slide px-3 py-2 m-0" onClick={() => {
                              if(scrollPosition < document.getElementById('nav-width-id')?.offsetWidth, navRef.current?.getBoundingClientRect().width && (document.getElementById('nav-width-id')?.offsetWidth, navRef.current?.getBoundingClientRect().width - scrollPosition) > 200) {
                                handleScrollmen(200);
                              } else if(scrollPosition < document.getElementById('nav-width-id')?.offsetWidth, navRef.current?.getBoundingClientRect().width && (document.getElementById('nav-width-id')?.offsetWidth, navRef.current?.getBoundingClientRect().width - scrollPosition) <= 200) {
                                handleScrollmen(document.getElementById('nav-width-id')?.offsetWidth, navRef.current?.getBoundingClientRect().width - scrollPosition)
                              }
                            }}><i class="fa fa-angle-right nav_icons_h" aria-hidden="true"></i></button> */}


                               {/* <Nav className="mx-auto custome_scroll">
                                 {Data?.length > 0 &&
                                   Data?.map((element) => (
                                     <div
                                       key={element?.id}
                                       className="category-wrapper"
                                       onMouseEnter={() => handleMouseEnter(element.id)}
                                       onMouseLeave={handleMouseLeave}
                                     >
                                       <Link
                                         onClick={() => {
                                           scrollToTop();
                                           setActiveLink();
                                         }}
                                         id="catNav-links"
                                         key={element?.id}
                                         to={{
                                           pathname: "/category-view",
                                           search: `?id=${element?.id}&uid=1&name=${element?.category_name}`
                                         }}
                                         className={activeLink === element.id ? 'active' : ''}
                                       >
                                         <p>{element.category_name}</p>
                                       </Link>
                                       {hoveredCategory === element.id && (
                                         <div className="subcategory-dropdown">
                                           {element.subcategory?.map((subcategory) => (
                                             <Link
                                               key={subcategory.id}
                                               to={{
                                                 pathname: "/category-view", 
                                                 search: `?id=${subcategory.id}&uid=1&name=${subcategory.subcategory_name}`,
                                               }}
                                             >
                                               <p>{subcategory.subcategory_name}</p>
                                             </Link>
                                           ))}
                                         </div>
                                       )}
                                     </div>
                                   ))}
                               </Nav> */}

                                {/* <Nav className="mx-auto custome_scroll">                            
                                    {Data?.length > 0 &&
                                        Data?.map((element) => (
                                            <Link
                                                onClick={() => { scrollToTop(); setActiveLink(element.id) }}
                                                id="catNav-links"
                                                key={element?.id}
                                                to={{
                                                    pathname: "/category-view",
                                                    search: `?id=${element?.id}&uid=1&name=${element?.category_name}`
                                                }}
                                                className={activeLink === element.id ? 'active' : ''}
                                            >
                                                <p>{element.category_name}</p>
                                            </Link>
                                        ))}
                                </Nav> */}

                        <div className="text-white ms-lg-5 me-3 d-none"><img src="/images/45.png" className="img_icon_home" alt="icon" /></div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header>
                    <Offcanvas.Title>
                        <img id="NewsLogo" src="https://news.vrikshatech.in/backend/public/images/1685943665.4299.png" alt="logo"/>
                    </Offcanvas.Title>
                    <i className="fa fa-xmark fs-5" onClick={handleClose}></i>
                </Offcanvas.Header>
                <Offcanvas.Body className="px-0 pt-0">
                  <div id="nv-functions-left" className="col-12">
                      <div className="card mb-3 border-0 br_10">
                          <div className="card-body py-0">
                              <Form.Label id="nv-font-lable">Fontsize</Form.Label>
                              <Form.Range id="nv-FontRange" min={10} max={20} step={2} value={FontSize} onChange={(e) => setFontSize(e.target.value)} />
                              <div className="d-flex justify-content-between">
                                  <Form.Label id="nv-FontRange-labels">10px</Form.Label>
                                  <Form.Label id="nv-FontRange-labels">12px</Form.Label>
                                  <Form.Label id="nv-FontRange-labels">14px</Form.Label>
                                  <Form.Label id="nv-FontRange-labels">16px</Form.Label>
                                  <Form.Label id="nv-FontRange-labels">18px</Form.Label>
                                  <Form.Label id="nv-FontRange-labels">20px</Form.Label>
                              </div>
                          </div>
                      </div>
                      {/* <h1>{FontSize}</h1> */}
                  </div>
                    <Nav className="mx-auto d-grid nav_offcannav pe-3">
                    <Link
                      onClick={() => {
                        scrollToTop();
                        setActiveLink('99999999');
                        handleClose()
                      }}
                      id="catCanvas-links"
                      to={{
                        pathname: "/all-news",
                        search: "?name=லேட்டஸ்ட்"
                      }}
                      className={activeLink === '99999999' ? 'text-dark active' : 'text-dark'}
                    >
                      
                      <p className={mode == 'light' ? "offcanvas-items-light" : "offcanvas-items-dark"}>லேட்டஸ்ட்</p>
                    </Link>
                    {!Data
                      ? "ஏற்றுகிறது..."
                      : Data.map((element, index) => (
                          <div key={element?.id} className="category-wrapper">
                            {element.subcategory && element.subcategory.length > 0 ? (
                              <Accordion className="accordian_footer" allowZeroExpanded={true}>
                                <AccordionItem>
                                  <AccordionItemHeading>
                                    <AccordionItemButton>
                                      <Link
                                        onClick={() => {
                                          scrollToTop();
                                          setActiveLink(element.id);
                                          handleClose()
                                        }}
                                        // id="catCanvas-links"
                                        key={element?.id}
                                        to={{
                                          pathname: "/category-view",
                                          search: `?id=${element?.id}&uid=1&name=${element?.category_name}`,
                                        }}
                                        className={activeLink === element.id ? 'catCanvas-links active' : 'catCanvas-links'}
                                      >
                                        
                                        <p className={mode == 'light' ? "offcanvas-items-light" : "offcanvas-items-dark"}>{element.category_name}</p>
                                      </Link>
                                    </AccordionItemButton>
                                  </AccordionItemHeading>
                                  <AccordionItemPanel>
                                    <ul>
                                      {element.subcategory.map((subcategory) => (
                                        <Link
                                          key={subcategory.id}
                                          onClick={handleClose}
                                          className="catCanvas-links"                                          
                                          to={{
                                            pathname: "/category-view",
                                            search: `?id=${element?.id}&subid=${subcategory.id}&uid=1&name=${element?.category_name}&sname=${subcategory.subcategory_name ?? ''}`,
                                          }}
                                        >
                                          <p className={mode == 'light' ? "offcanvas-items-light" : "offcanvas-items-dark"}>{subcategory.subcategory_name ?? ''}</p>
                                        </Link>
                                      ))}
                                    </ul>
                                  </AccordionItemPanel>
                                </AccordionItem>
                              </Accordion>
                            ) : (
                              <Link
                                onClick={() => {
                                  scrollToTop();
                                  setActiveLink(element.id);
                                  handleClose()
                                }}
                                // id="catCanvas-links"
                                key={element?.id}
                                to={{
                                  pathname: "/category-view",
                                  search: `?id=${element?.id}&uid=1&name=${element?.category_name}`,
                                }}
                                
                                className={activeLink === element.id ? 'catCanvas-links active' : 'catCanvas-links'}
                              >
                                <p className={mode == 'light' ? "offcanvas-items-light" : "offcanvas-items-dark"}>{element.category_name}</p>
                              </Link>
                            )}
                          </div>
                        ))}
                        {/* {Data.length > 0 &&
                            Data?.map((element) => (
                                <Link  className="mb-4" key={element.id} to={{ pathname: "/category-view", search: `?id=${element.id}&uid=1&name=${element.category_name}` }} onClick={() => handleClose()} >
                                    {" "}
                                    <i class="fa fa-align-left" aria-hidden="true"></i>
                                    {" "}
                                    <b className="ps-2 fs-6">{element.category_name}</b>{" "}
                                </Link>
                            ))} */}
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default CatNav;
