import React from "react";
import { FiCalendar, FiClock } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "../Hooks";
import BreadcrumbNav from "./BreadcrumbNav";
import { getnewsbycategoryApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";
import { getnewsApi } from "../store/actions/campaign";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import Slider from "react-slick";
import { getAddUrl } from "../utils/api";
import no_image from "../images/no_image.jpg";
import { setActiveLink } from "../utils";
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';
import {useThemeMode} from '../utils/themeMode'

SwiperCore.use([Navigation, Pagination, Autoplay]);

function CategoryView() {
  const {mode, menuMode} = useThemeMode();
  const location = useLocation();
  const [Data, setData] = useState([]);
  const query = useQuery();
  const catid = query.get("id");
  const subcatid = query.get("subid");
  const categoryName = query.get("name");
  const sub_categoryName = query.get("sname");
  const homePosition = query.get("pos");
  const [loading, setLoading] = useState(true);
  const currentLanguage = useSelector(selectCurrentLanguage);
  

  const scrollToTop = () => {
    window.scrollTo({ top: 0 });
  };

  // useEffect(() => {
  //   const allParagraphs = document.querySelectorAll('p');
  //   const allLinks = document.querySelectorAll('a');
  //   const paragraphStyle = {
  //     fontSize: menuMode+'px',
  //   };

  //   allParagraphs.forEach(paragraph => {
  //     for (const property in paragraphStyle) {
  //       paragraph.style[property] = paragraphStyle[property];
  //     }
  //   });
  //   allLinks.forEach(paragraph => {
  //     for (const property in paragraphStyle) {
  //       paragraph.style[property] = paragraphStyle[property];
  //     }
  //   });
  // }, [menuMode, Data])

  const [Datalatest, setDatalatest] = useState([]);
  useEffect(() => {
    getnewsApi(
      "0",
      "8",
      "",
      "",
      (response) => {
        setDatalatest(response.data);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [currentLanguage]);


  useEffect(() => {
    setShowBlueBox(false);
    getnewsbycategoryApi(
      catid,
      subcatid,
      "0",
      "99999999999999999",
      (response) => {
        setData(response.data);
        setLoading(false);
        const newsIndexToShow = 0; // Index of the news item to show
        const firstPageData = response.data.slice(
          Math.max(newsIndexToShow - 10, 0),
          newsIndexToShow + 1
        );
      },
      (error) => {
        if (error) {
          setData("");
          setLoading(false);
        }
      }
    );
  }, [catid, currentLanguage, subcatid]);

  const [adddata, setadddata] = useState([]);

  const fetchadd = async () => {
    const response = await getAddUrl();
    setadddata(response.data?.data);
  }

  useEffect(() => {
    fetchadd();
    
  }, [currentLanguage])

  const [showBlueBox, setShowBlueBox] = useState(false);


  const currentDate = new Date(); // Current date and time
  const filteredSpritual = Datalatest.filter((value) => {
      const showTillDate = new Date(value.show_till);
      return currentDate < showTillDate; // Compare current date with show_till date
  });

  const [currentPage, setCurrentPage] = useState(0); 
  const [showPagination, setShowPagination] = useState(false);
  const itemsPerPage = 10;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = Data.slice(startIndex, endIndex);

  function stripHTMLTags(html) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.innerText;
  }

  const settings = {
    dots: false,
    autoplay: true,
    slidesToShow: filteredSpritual?.length > 0 && filteredSpritual?.length > 4 ? 4 : filteredSpritual?.length == 1 ? filteredSpritual?.length : filteredSpritual?.length - 1,
    loop: true,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    infinite: false,
    beforeChange: function (currentSlide, nextSlide) {
    },
    afterChange: function (currentSlide) {
    }
};

window.onpopstate = (event) => {
  if(homePosition && homePosition == 'sixth-section') {
      setTimeout(() => document.getElementById('lefkhiuwhtiuehrteth')?.click(), 1000);
  } else if(homePosition && homePosition == 'fifth-section')  {
      setTimeout(() => document.getElementById('ggjhritgiutberjheuy')?.click(), 1000);
  }   
};

// useEffect(() => {
//   setTimeout(() => window.scrollTo({ top: 0 }), 50);
// }, []);

// useEffect(() => {
//   setTimeout(() => window.scrollTo({ top: 0 }), 50);
//   // setTimeout(() => window.scrollTo({ top: 0 }), 100);
// }, [currentPage, location]);

  return (
    <>
      <BreadcrumbNav SecondElement={categoryName ?? '0'} ThirdElement={sub_categoryName ?? '0'} />
      <div id="cv-main" className={mode == 'light' ? "" : "bg-dark"}>
        {/* <p>my corrent location is {loca.pathname}</p>
        {loca.pathname === `/category-view` ?
        <button
        onClick={ () => alert('you are awesome')} >

          click me
        </button>
        : null
        } */}
        {/* <BreadcrumbSection/> */}
        <div id="cv-content" className="my-4 container">
          <div className="row">
            <div className="col-lg-8 col-12">

              <div className="theme_card mb-3">
                {loading ? (
                  <div>
                    <Skeleton height={200} count={6} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                  </div>
                ) : (
                  <div className="row">
                    {/* {Data.length > 0 ? (
                      Data?.slice(0, 4).map((element) => ( */}

                        
                        {displayedData.length > 0 ? (
                          displayedData?.map((element) => (
                        <>
                          <div className="col-12 mb-3 col_12" key={element.id}>
                            
                            <Link
                            onClick={() => { scrollToTop(); setActiveLink() }} 
                             id="Link-all" 
                            //  to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id}
                            // href={element.slug}
                            to={{
                              pathname: "/news-view",
                              search: `?slug=${element?.slug}&c=${categoryName}&sc=${sub_categoryName ?? '0'}`
                            }}
                             >
                              <div id="cv-card" className="card rounded-0 border-bottom pb-2">
                                <div id="cv-card-body" className="card-body ps-0 pe-2 py-0">
                                  <div className="d-flex align-items-center mb-3">
                                    <a onClick={() => { scrollToTop(); setActiveLink() }}
                                     id="Link-all"
                                     key={element?.id}
                                    //  to={{
                                    //    pathname: "/category-view",
                                    //    search: `?id=${element?.category_id}&subid=${element.id}&uid=1&name=${element?.category_name}`
                                    //  }}
                                    //  to={{
                                    //    pathname: "/category-view",
                                    //    search: `?slug=${element?.slug}`
                                    //  }}
                                      // href={element.slug}
                                     >
                                    <button id="cv-btnCatagory" className="btn btn-sm me-2" type="button">
                                      {element.category_name}
                                    </button></a>
                                    <p id="cv-card-date">
                                      <FiClock size={18} id="cv-logoClock" className="me-2" />
                                      {element.date.slice(0, 10)}
                                    </p></div>
                                  <h6 id="cv-card-title" className={mode == 'light' ? "card-title two_line text-dark fw-bold" : "card-title two_line text-light fw-bold"}>
                                    {element.title}
                                  </h6>
                                  {/* <p className="two_line text-gray" dangerouslySetInnerHTML={{ __html: Data[0].description }}></p> */}
                                  
                                  <p className={mode == 'light' ? "two_line text-gray" : "two_line"}>{stripHTMLTags(element.description)}</p>

                                  {/* <p className="two_line text-gray">{element.description.slice(3)}</p> */}
                                  <a className="read_more_carefsd" href="">மேலும் வாசிக்க</a>
                                  {/* <Link id='btncvRead' className='btn overlay' type="button" to="/news-view" ><IoArrowForwardCircleSharp size={50}/></Link> */}
                                </div>
                                <img id="cv-card-image" src={element.image ? element.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img left-right_border" alt="..." />
                              </div>
                            </Link>
                          </div>

                          {/* <img src="/images/19.png" id="rns-image" className="w-100 mb-3" alt="icon" /> */}
                        </>
                      ))
                    )
                      : (
                        <div className="text-center my-5">{translate("வேறு தகவல்கள் இல்லை")}</div>
                      )}
                   

                    {/* {
                      Data.length > 4 && Data?.slice(4, 6)?.map((element) => (
                        <div className="col-12 mb-3 col_12" key={element.id}>
                          <Link onClick={() => { scrollToTop(); setActiveLink() }} id="Link-all" to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id}>
                            <div id="cv-card" className="card rounded-0 border-bottom pb-2">
                              <div id="cv-card-body" className="card-body ps-0 pe-2 py-0">
                                <div className="d-flex align-items-center mb-3">
                                <Link onClick={() => { scrollToTop(); setActiveLink() }}
                                 id="Link-all"
                                 key={element?.id}
                                 to={{
                                   pathname: "/category-view",
                                   search: `?id=${element?.category_id}&subid=${element.id}&uid=1&name=${element?.category_name}`
                                 }}>
                                  <button id="cv-btnCatagory" className="btn btn-sm me-2" type="button">
                                    {element.category_name}
                                  </button></Link>
                                  <p id="cv-card-date">
                                    <FiClock size={18} id="cv-logoClock" className="me-2" />
                                    {element.date.slice(5, 10)}
                                  </p>
                                </div>
                                <h6 id="cv-card-title" className="card-title two_line text-dark fw-bold">
                                  {element.title}
                                </h6>
                                <p className="two_line text-gray" 
                                  dangerouslySetInnerHTML={{ __html: Data[0].description }}
                                  ></p>
                                <a className="read_more_carefsd" href="">மேலும் வாசிக்க</a>
                              </div>
                              <img id="cv-card-image" src={element.image} className="card-img left-right_border" alt="..." />
                            </div>
                          </Link>
                        </div>
                      ))
                    } */}
                  </div>
                )}
                {displayedData.length > 0 &&
                <ReactPaginate
                  pageCount={Math.ceil(Data.length / itemsPerPage)}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={({ selected }) => setCurrentPage(selected)}
                  containerClassName="pagination"
                  activeClassName="active"
                /> }                             
                  <div className="">
                    {adddata.length > 0 && adddata?.map((item, i) => {
                      if (item.ad_space === "news-against-category-1") {
                        return (
                          <a target="blank" href={item.ad_url}>
                            <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" />
                          </a>
                        );
                      }
                    })}
                  </div>
                {/* {showBlueBox ? 
                   <>
                      {
                      Data.length > 6 && Data?.slice(6, 100000000000000000000)?.map((element) => (
                        <div className="col-12 mb-3 col_12" key={element.id}>
                          <Link onClick={() => { scrollToTop(); setActiveLink() }} id="Link-all" to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id}>
                            <div id="cv-card" className="card rounded-0 border-bottom pb-2">
                              <div id="cv-card-body" className="card-body ps-0 pe-2 py-0">
                                <div className="d-flex align-items-center mb-3">
                                <Link onClick={() => { scrollToTop(); setActiveLink() }}
                                 id="Link-all"
                                 key={element?.id}
                                 to={{
                                   pathname: "/category-view",
                                   search: `?id=${element?.category_id}&subid=${element.id}&uid=1&name=${element?.category_name}`
                                 }}>
                                  <button id="cv-btnCatagory" className="btn btn-sm me-2" type="button">
                                    {element.category_name}
                                  </button></Link>
                                  <p id="cv-card-date">
                                    <FiClock size={18} id="cv-logoClock" className="me-2" />
                                    {element.date.slice(5, 10)}
                                  </p>
                                </div>
                                <h6 id="cv-card-title" className="card-title two_line text-dark fw-bold">
                                  {element.title}
                                </h6>
                                <p className="two_line text-gray" 
                                  dangerouslySetInnerHTML={{ __html: Data[0].description }}
                                  ></p>
                                <a className="read_more_carefsd" href="">மேலும் வாசிக்க</a>
                              </div>
                              <img id="cv-card-image" src={element.image} className="card-img left-right_border" alt="..." />
                            </div>
                          </Link>
                        </div>
                      ))
                    }
                </>
                : null}
                 <div className="text-center my-4 d-flex align-items-center justify-content-center">
                  <button className="mb-0 next_page" disabled={showBlueBox} onClick={() => setShowBlueBox(true) } > மேலும் செய்திகள்...</button>
                </div> */}


              </div>
              
            </div>
            <div className="col-lg-4 col-12">
              <div className="">
                <h4 id="nav-logo"><b>லேட்டஸ்ட் செய்திகள்...</b></h4>
                <div className="">
                  <Slider {...settings}>
                    {/* {Datalatest.length > 0 && Datalatest?.map((value, index) => { */}
                           {filteredSpritual.length > 0 &&
                            filteredSpritual.map((value, index) => {
                              
                      return (
                        <div className="theme_card mb-3">
                          <div id="rns-card" className="card h-100" key={index}>
                            <div className="row">
                              <div className="col-4 pe-2">
                                <Link 
                                  to={{
                                    pathname: "/news-view",
                                    search: `?slug=${value?.slug}&c=${categoryName}&sc=${sub_categoryName ?? '0'}`
                                  }}
                                 >
                                  <img id="RNews-image" src={value.image ? value.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top" alt="..." />
                                </Link>
                                </div>
                              <div className="col-8">
                                <div id="rns-img-overlay" className=" card-inverse">
                                  <Link id="btnRNewsCatagory" className="btn btn-sm" type="button"
                                  //  to={`/category-view?id=${value.category_id}`}
                                  // key={value?.id}
                                  to={{
                                    pathname: "/news-view",
                                    search: `?slug=${value?.slug}&c=${categoryName}&sc=${sub_categoryName ?? '0'}`
                                  }}
                                  // to={{
                                  //   pathname: "/category-view",
                                  //   search: `?id=${value?.category_id}&uid=1&name=${value?.category_name}`
                                  // }}
                                   >
                                    {value.category_name}
                                  </Link>
                                </div>
                                <div id="rns-card-body" className="card-block">
                                    <Link
                                        to={{
                                          pathname: "/news-view",
                                          search: `?slug=${value?.slug}&c=${categoryName}&sc=${sub_categoryName ?? '0'}`
                                        }}
                                        > 
                                       <p className={mode == 'light' ? "card-title two_line text-dark" : "card-title two_line text-light"}>{value.title}</p>
                                   </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                  {adddata.length > 0 && adddata?.map((item) => {
                    if (item.ad_space === "news-against-category-2") {
                      return (
                        <a target="blank" href={item.ad_url}>
                        <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                      );
                    }
                  })}
                  {adddata.length > 0 && adddata?.map((item) => {
                    if (item.ad_space === "news-against-category-8") {
                      return (
                        <a target="blank" href={item.ad_url}>
                        <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row">
              <div className="col-md-6">
                {adddata.length > 0 && adddata?.map((item) => {
                  if (item.ad_space === "news-against-category-3") {
                    return (
                      <a target="blank" href={item.ad_url}>
                      <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                    );
                  }
                })}
              </div>
              <div className="col-md-6">
                {adddata.length > 0 && adddata?.map((item) => {
                  if (item.ad_space === "news-against-category-4") {
                    return (
                      <a target="blank" href={item.ad_url}>
                      <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                    );
                  }
                })}
              </div>
              <div className="col-md-4 col-sm-6">
                {adddata.length > 0 && adddata?.map((item) => {
                  if (item.ad_space === "news-against-category-5") {
                    return (
                      <a target="blank" href={item.ad_url}>
                      <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                    );
                  }
                })}
              </div>
              <div className="col-md-4 col-sm-6">
                {adddata.length > 0 && adddata?.map((item) => {
                  if (item.ad_space === "news-against-category-6") {
                    return (
                      <a target="blank" href={item.ad_url}>
                      <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                    );
                  }
                })}
              </div>
              <div className="col-md-4 col-sm-6">
                {adddata.length > 0 && adddata?.map((item) => {
                  if (item.ad_space === "news-against-category-7") {
                    return (
                      <a target="blank" href={item.ad_url}>
                      <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CategoryView;
