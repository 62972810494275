import { GiHamburgerMenu } from "react-icons/gi";
import React, { useEffect, useState, useRef } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BiBell, BiUserCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { getAuth, signOut } from "firebase/auth";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dropdown from "react-bootstrap/Dropdown";
import { loadLanguageLabels, loadLanguages, selectCurrentLanguage, selectLanguages, setCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import { categoriesApi, getnotificationApi, getusernotificationApi } from "../store/actions/campaign";
import { FaAngleDown } from "react-icons/fa";
import { getSiblings, slideToggle, slideUp, getClosest, isLogin, translate } from "../utils/index";
import { logoutUser, selectUser } from "../store/reducers/userReducer";
import SignInModal from "./SignInModal";
import { toast } from "react-toastify";
import {  webSettingsData } from "../store/reducers/websettingsReducer";
import axios from "axios";
import { SlCalender } from "react-icons/sl";
import { getAddUrl, getSiteDetails } from "../utils/api";
import no_image from "../images/no_image.jpg";
import CatNav from "./CatNav";
import { setActiveLink } from "../utils";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { BsSun, BsMoon } from "react-icons/bs";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion'
import {useThemeMode} from '../utils/themeMode'

const Newsbar = (props) => {
  const {updateMode, mode} = useThemeMode();
    const userData = useSelector(selectUser);
    const [weather, setWeather] = useState(null);
    const [location, setLocation] = useState(null);
    const [themeButton, setThemeButton] = useState(localStorage.getItem('newsTheme'));
    const auth = getAuth();
    const [Data, setData] = useState([]);
    const [modalShow, setModalShow,] = React.useState(false);
    const [islogout, setIsLogout] = useState(false); // eslint-disable-next-line
    const [isloginloading, setisloginloading] = useState(true); // eslint-disable-next-line
    const [ShowManu, setShowManu] = useState();
    const [notificationbadge, setNotificationBadge] = useState(0);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const closeRef = useRef();
    const languagesData = useSelector(selectLanguages);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const websettings = useSelector(webSettingsData);

    // set active element of navbar
    function setActiveLink() {
        const links = document.querySelectorAll('.nav-item a');
        links.forEach(link => {
          if (link.href === window.location.href) {
            link.classList.add('active');
          } else {
            link.classList.remove('active');
          }
        });
      }

    // to get today weekday name
    const today = new Date();

    const dayOfMonth = today.getDate();   // returns the day of the month (1-31)
    // const month = today.getMonth() + 1;  // returns the month (0-11); add 1 to get the correct month
    const year = today.getFullYear();    // returns the year (4 digits)

    const month = today.toLocaleString('default', { month: 'long' });

      // Call setActiveLink on page load and after a delay on link click
      window.addEventListener('load', setActiveLink);
      document.querySelectorAll('.nav-item a').forEach(link => {
        link.addEventListener('click', () => {
          setTimeout(setActiveLink, 100);
        });
      });

    // if user click on logo then active elem remove from other navigation and active home
    function activateHomeLink() {

        const homeLink = document.querySelector('#nav-links[href="/"]');
        if (homeLink) {
          homeLink.click();
        }
      }

    useEffect(() => {
        // get categories
        categoriesApi(
            "0",
            "100",
            (response) => {
                setData(response.data);
            },
            (error) => {
                // console.log(error);
            }
        );
        // language laod
        loadLanguages(
            (response) => {
                if (currentLanguage.code == null) {
                    let index = response.data.filter((data) => {
                        if (data.code === "ta") {
                            return { code: data.code, name: data.language, id: data.id };
                        } else {
                            return undefined;
                        }
                    });
                    setCurrentLanguage(index[0].language, index[0].code, index[0].id);
                }
            },
            (error) => {
                console.log(error);
            }
        ); // eslint-disable-next-line
    }, []);

    // language change
    const languageChange = (name, code, id) => {
        loadLanguageLabels(code);
        setCurrentLanguage(name, code, id);
    };

    useEffect(() => {
         setActiveLink();
        if (userData.data !== null) {
            setIsLogout(true);
            setisloginloading(false);
        } else {
            setIsLogout(false);
            setisloginloading(true);
        } // eslint-disable-next-line
    }, []);

    // user notification
    const getusernotification = () => {
        getusernotificationApi(
          "0",
          "10",
          (response) => {
            setNotificationBadge(response.data.length)
          },
          (error) => {
            if (error === "வேறு தகவல்கள் இல்லை") {
              setNotificationBadge(0);
            }
          }
        );
      }

      const getnotification = () => {
        getnotificationApi(
          "0",
          "20",
            (response) => {
            setNotificationBadge(response.data.length)
          },
          (error) => {
            if (error === "வேறு தகவல்கள் இல்லை") {
              setNotificationBadge(0)
            }
          }
        );
      }

      useEffect(() => {
        // Make API calls here based on route change
        if (window.location.pathname === '/') {
            getusernotification();
        }else if(window.location.pathname === '/notification' || window.location.pathname === '/persnol-notification') {
            getusernotification();
        }else if (window.location.pathname === '/news-notification') {
            getnotification();
        }// eslint-disable-next-line
      }, [window.location.pathname,isLogin()]);

    const logout = () => {
        handleClose();

        confirmAlert({
            title: "வெளியேறு",
            message: "நீங்கள் கண்டிப்பாக இதை செய்வீர்களா.",
            buttons: [
                {
                    label: "ஆம்",
                    onClick: () => {
                        signOut(auth)
                            .then(() => {
                                // // Sign-out successful.
                                // // alert("Sign-out successful.")
                                // localStorage.removeItem("token");
                                // localStorage.removeItem("user");
                                logoutUser();
                                setIsLogout(false);
                                navigate("/");
                            })
                            .catch((error) => {
                                toast.error(error);
                                // An error happened.
                            });
                    },
                },
                {
                    label: "இல்லை",
                    onClick: () => {},
                },
            ],
        });
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    
    const handleShow = () => {
        setShow(true);
    };


    const showClose=() =>{
        setModalShow(true);
        handleClose();
    }
    

    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (parentEl?.classList.contains("menu-toggle") || target.classList.contains("menu-toggle")) {
            const element = target.classList.contains("icon") ? parentEl : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };

    let userName = "";

    const checkUserData = (userData) => {
        if (userData.data && userData.data.name !== "") {
            return (userName = userData.data.name);
        } else if (userData.data && userData.data.email !== "") {
            return (userName = userData.data.email);
        } else if (userData.data && (userData.data.mobile !== null || userData.data.mobile !== "")) {
            return (userName = userData.data.mobile);
        }
    };

    // set rtl
    const selectedLang = languagesData.find(lang => lang.code === currentLanguage.code);
    useEffect(() => {
        if (selectedLang && selectedLang.isRTL === "1") {
            document.documentElement.dir = "rtl";
            document.documentElement.lang = `${selectedLang && selectedLang.code}`;
        } else {
            document.documentElement.dir = "ltr";
            document.documentElement.lang = `${selectedLang && selectedLang.code}`;
        }
    }, [selectedLang]);



    const [hoveredCategory, setHoveredCategory] = useState(null);

    const handleMouseEnter = (category) => {
      setHoveredCategory(category);
    };
  
    const handleMouseLeave = () => {
      setHoveredCategory(null);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };
    const locations = useLocation();
    const activeLink = new URLSearchParams(locations.search).get('id');
    
    // this is for mobile devices navbar with humburger menu
    function OffCanvasExample({ name, ...props }) {
        return (
            <>
                <button className="btn" onClick={handleShow}>
                    <GiHamburgerMenu />
                </button>

                <Offcanvas id="Nav-Offcanvas" className="headermodal" show={show} onHide={handleClose} {...props}>
                    <Offcanvas.Header ref={closeRef}>
                        <Offcanvas.Title className="d-flex">
                            <li id="Nav-btns">
                                {islogout && checkUserData(userData) ? (
                                    <Dropdown>
                                        <Dropdown.Toggle id="btnSignIn" className="">
                                            <BiUserCircle size={23} id="btnLogo" />
                                            {userName}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ backgroundColor: "#1A2E51" }}>
                                            {/* <Dropdown.Item id="btnLogout">
                                                <Link id="btnBookmark" to="/bookmark">
                                                {translate("bookmark")}
                                                </Link>
                                            </Dropdown.Item> */}
                                            {/*<Dropdown.Item id='btnLogout' onClick={changePassword}>*/}
                                            {/*    Change Password*/}
                                            {/*</Dropdown.Item>*/}
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={logout} id="btnLogout" className="">
                                            {translate("வெளியேறு")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                ) : (
                                    // <Button variant="danger" onClick={() => setModalShow(true)} id="btnSignIn" className="" type="button">
                                    //     <BiUserCircle size={23} id="btnLogo" />
                                    //     {translate("login")}
                                    // </Button>
                                       <Button variant="danger" onClick={showClose} id="btnSignIn" className="" type="button" >
                                       {/* <BiUserCircle size={23} id="btnLogo" /> */}
                                   {translate("உள்நுழைய")}<i className="fa fa-sign-in ms-2" aria-hidden="true"></i>

                               </Button>
                                )}

                                {/* <button id='btnSerch' type="button" onClick={handleSearchModel} className="btn"><BiSearch size={23} /></button> */}
                                {/* {['bottom-end'].map((placement) => (
                                <OverlayTrigger
                                trigger="click"
                                key={placement}
                                placement={placement}
                                overlay={
                                    <Popover id={`popover-positioned-${placement}`}>
                                    <Popover.Body id='nb-popover-body' >
                                    <form id='serchModal' className="d-flex" role="search">
                                                        <input id='inputSerchForm' className="form-control me-2" type="search" placeholder="Search" aria-label="Search" onChange={(e)=>{setSearch(e.target.value)}}/>
                                                        <button id='btnSerchForm' className="btn" type="submit" onClick={(e)=>{
                                                            e.preventDefault();

                                                        }}>Search</button>
                                                    </form>
                                    </Popover.Body>
                                    </Popover>
                                }
                                >
                                <Button id='btnSerch' className="btn" variant="secondary"><BiSearch size={23} /></Button>
                                </OverlayTrigger>
                            ))} */}
                             
                            </li>
                            <Link to="/" onClick={handleClose} className="text-secondary"><i className="fa fa-home ms-3" aria-hidden="true"></i></Link>
                            <li id="Nav-btns" className="d-none">
                                <Dropdown>
                                    <Dropdown.Toggle id="btnSignIn" className="">
                                        {currentLanguage.name}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ backgroundColor: "#1A2E51" }}>
                                        {languagesData &&
                                            languagesData?.map((data, index) => {
                                                return (
                                                    <Dropdown.Item key={index} id="btnLogout" onClick={() => languageChange(data.language, data.code, data.id)}>
                                                        {data.language}
                                                    </Dropdown.Item>
                                                );
                                            })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li id="Nav-btns">{islogout && checkUserData(userData) ? (
                                    <Link to="/notification" id="btnNotification" type="button" className="btn" onClick={handleClose}>
                                        <BiBell size={23} /><span className="noti_badge_data">{notificationbadge}</span>
                                    </Link>
                                ) : null}</li>
                            <li id="Nav-btns">
                              {/* <a id="btnTheme" type="button" className="btn" onClick={themeHandler} > 
                              {themeButton == 'dark' ? 
                                <BsSun size={20} /> : <BsMoon size={20} />}
                              </a> */}
                            </li>
                        </Offcanvas.Title>
                        <i className="fa fa-xmark fs-5" onClick={handleClose}></i>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="pt-4">
                        <ul className="">
                            {/* <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/" onClick={handleClose} >
                                        {translate("முகப்பு")}
                                    </Link>
                                </b>
                            </li>
                            <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/about-us" onClick={handleClose}>
                                        {translate("எங்களைப்பற்றி")}
                                    </Link>
                                </b>
                            </li> */}
                            {/* <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/live-news" onClick={handleClose}>
                                        {translate("livenews")}
                                    </Link>
                                </b>
                            </li>

                            <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/breaking-news-view  " onClick={handleClose}>
                                        {translate("Breaking News")}
                                    </Link>
                                </b>
                            </li> */}
                            {/* <li className="nav-item">
                                <b>
                                    <Link id="nav-links" className="" aria-current="page" to="/contact-us" onClick={handleClose}>
                                        {translate("தொடர்புகொள்ள")}
                                    </Link>
                                </b>
                            </li> */}
                            <li className="nav-item has-children">
                                {/* <span className="menu-toggle" onClick={onClickHandler}>
                                    <b>
                                        <p id="nav-links" className="">
                                            {translate("வகைகள்")}
                                        </p>
                                    </b>
                                    <i className="">
                                        <FaAngleDown />
                                    </i>
                                </span> */}
                                <ul className="sub-menu">

                                {/* <Nav  ref={navRef} className="mx-auto custome_scroll pt-1">
                                {Data?.length > 0 && Data?.map((element) => (
                                  <div
                                    key={element?.id}
                                    className="category-wrapper"
                                    onMouseEnter={() => handleMouseEnter(element.id)}
                                    onMouseLeave={handleMouseLeave}
                                  >
                                    <Link
                                      onClick={() => {
                                        scrollToTop();
                                        setActiveLink(element.id);
                                      }}
                                      id="catNav-links"
                                      key={element?.id}
                                      to={{
                                        pathname: "/category-view",
                                        search: `?id=${element?.id}&uid=1&name=${element?.category_name}`
                                      }}
                                      className={activeLink === element.id ? 'active' : ''}
                                    >
                                      <p>{element.category_name}</p>
                                    </Link>
                                    {hoveredCategory === element.id && (
                                      <div className={`subcategory-dropdown ${activeLink === element.id ? 'active' : ''}`}>
                            
                                      <div className=" custome_dropdown">
                                        <div className="controls">
                                             {element.subcategory?.map((subcategory) => (
                                               <Link
                                                 key={subcategory.id}
                                                 to={{
                                                   pathname: "/category-view",
                                                   search: `?id=${element?.id}&subid=${subcategory.id}&uid=1&name=${subcategory.subcategory_name}`,
                                                 }}
                                               >
                                                 <p>{subcategory.subcategory_name}</p>
                                               </Link>
                                 
                                             ))}
                                             </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </Nav> */}
                            <div className="category-wrapper">
                              <Link
                                onClick={() => {
                                  scrollToTop();
                                  setActiveLink('99999999');
                                  handleClose()
                                }}
                                id="catCanvas-links"
                                to={{
                                  pathname: "/all-news",
                                  search: "?name=லேட்டஸ்ட்"
                                }}
                                className={activeLink === '99999999' ? 'active' : ''}
                              >
                                <p className={mode == 'light' ? "offcanvas-items-light pb-2" : "offcanvas-items-dark pb-2"}>லேட்டஸ்ட்</p>
                              </Link>
                            </div>
                              {!Data
                                ? "ஏற்றுகிறது..."
                                : Data.map((element, index) => (
                                    <div key={element?.id} className="category-wrapper">
                                      {element.subcategory && element.subcategory.length > 0 ? (
                                        <Accordion className="accordian_footer" allowZeroExpanded={true}>
                                          <AccordionItem>
                                            <AccordionItemHeading>
                                              <AccordionItemButton>
                                                <Link
                                                  onClick={() => {
                                                    scrollToTop();
                                                    setActiveLink(element.id);
                                                    handleClose()
                                                  }}
                                                  id="catCanvas-links"
                                                  key={element?.id}
                                                  to={{
                                                    pathname: "/category-view",
                                                    search: `?id=${element?.id}&uid=1&name=${element.category_name}`,
                                                  }}
                                                  className={activeLink === element.id ? 'active' : ''}
                                                >
                                                  
                                                  <p className={mode == 'light' ? "offcanvas-items-light" : "offcanvas-items-dark"}>{element.category_name}</p>
                                                </Link>
                                              </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                              <ul>
                                                {element.subcategory.map((subcategory) => (
                                                  <Link
                                                    key={subcategory.id}
                                                    onClick={handleClose}
                                                    id="catCanvas-links"
                                                    to={{
                                                      pathname: "/category-view",
                                                      search: `?id=${element?.id}&subid=${subcategory.id}&uid=1&name=${element.category_name}&sname=${subcategory.subcategory_name ?? ''}`,
                                                    }}
                                                  >
                                                    <p className={mode == 'light' ? "offcanvas-items-light" : "offcanvas-items-dark"}>{subcategory.subcategory_name ?? ''}</p>
                                                  </Link>
                                                ))}
                                              </ul>
                                            </AccordionItemPanel>
                                          </AccordionItem>
                                        </Accordion>
                                      ) : (
                                        <Link
                                          onClick={() => {
                                            scrollToTop();
                                            setActiveLink(element.id);
                                            handleClose()
                                          }}
                                          id="catCanvas-links"
                                          key={element?.id}
                                          to={{
                                            pathname: "/category-view",
                                            search: `?id=${element?.id}&uid=1&name=${element?.category_name}`,
                                          }}
                                          className={activeLink === element.id ? 'active' : ''}
                                        >
                                          <p className={mode == 'light' ? "offcanvas-items-light pb-2" : "offcanvas-items-dark pb-2"}>{element.category_name}</p>
                                        </Link>
                                      )}
                                    </div>
                                  ))}

                                    {/* {!Data
                                        ? "Loading..."
                                        : Data?.map((element, index) => (
                                        
                                                       <div
                                                         key={element?.id}
                                                         className="category-wrapper"
                                                       
                                                       >
                                                        <Accordion className=" accordian_footer" allowZeroExpanded={true}>

                                                            <AccordionItem>
                                                                <AccordionItemHeading>
                                                                    <AccordionItemButton><Link
                                                           onClick={() => {
                                                             scrollToTop();
                                                             setActiveLink(element.id);
                                                           }}
                                                           id="catNav-links"
                                                           key={element?.id}
                                                           to={{
                                                             pathname: "/category-view",
                                                             search: `?id=${element?.id}&uid=1&name=${element?.category_name}`
                                                           }}
                                                           className={activeLink === element.id ? 'active' : ''}
                                                         >
                                                           <p>{element.category_name}</p>
                                                         </Link></AccordionItemButton>
                                                                </AccordionItemHeading>
                                                                <AccordionItemPanel>
                                                                <ul>
                                                                {element.subcategory?.map((subcategory) => (
                                                                    <Link
                                                                      key={subcategory.id}
                                                                      to={{
                                                                        pathname: "/category-view",
                                                                        search: `?id=${element?.id}&subid=${subcategory.id}&uid=1&name=${subcategory.subcategory_name}`,
                                                                      }}
                                                                    >
                                                                      <p>{subcategory.subcategory_name}</p>
                                                                    </Link>
                                                      
                                                                  ))}
                                                            </ul>
                                                                </AccordionItemPanel>
                                                            </AccordionItem>
                                                            
                                                            
                                                         </Accordion>
                                                         <Link
                                                           onClick={() => {
                                                             scrollToTop();
                                                             setActiveLink(element.id);
                                                           }}
                                                           id="catNav-links"
                                                           key={element?.id}
                                                           to={{
                                                             pathname: "/category-view",
                                                             search: `?id=${element?.id}&uid=1&name=${element?.category_name}`
                                                           }}
                                                           className={activeLink === element.id ? 'active' : ''}
                                                         >
                                                           <p>{element.category_name}</p>
                                                         </Link>
                                                        
                                            
                                                                  {element.subcategory?.map((subcategory) => (
                                                                    <Link
                                                                      key={subcategory.id}
                                                                      to={{
                                                                        pathname: "/category-view",
                                                                        search: `?id=${element?.id}&subid=${subcategory.id}&uid=1&name=${subcategory.subcategory_name}`,
                                                                      }}
                                                                    >
                                                                      <p>{subcategory.subcategory_name}</p>
                                                                    </Link>
                                                      
                                                                  ))}
                                                     }
                                                    </div>
                                               
                                              <li className="nav-item" key={index}>
                                                  <Link id="catNav-links" key={index} to={{ pathname: "/category-view", search: `?id=${element.id}&uid=1&name=${element.category_name}` }} onClick={handleClose}>
                                                      {" "}
                                                      <i class="fa fa-align-left" aria-hidden="true"></i>{" "}
                                                      <b className="ps-2">{element.category_name}</b>{" "}
                                                  </Link>
                                              </li>
                                          ))} */}
                                </ul>
                            </li>
                        </ul>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }

  //   useEffect(() => {
  //       // eslint-disable-next-line
        
  //       try{
  //        navigator.geolocation.getCurrentPosition(async (position) => {
  //         const { latitude, longitude } = position.coords;
  
  //         // get current location
  //           //   const locationresponse = await axios.get(
  //           //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
  //           //     )
         
            
  //       //   setLocation(locationresponse.data.results[0]);
  
  //         // get weather information
  //         const response = await axios.get(
  //           `https://api.weatherapi.com/v1/forecast.json?key=${process.env.REACT_APP_WEATHER_API_KEY}&q=${latitude},${longitude}&days=1&aqi=no&alerts=no&lang=${currentLanguage.code}`
  //           ).then(res => {
  //            console.log('res',res)
  //            setWeather(res?.data)
  //           }).catch(err => {console.log('err',err.message)})
  //   //   setWeather(response?.data);
  //     setLoading(false);
  //   });
  
  // }catch(error){
  //     console.error('err',error.message)
  // }
  //   }, [currentLanguage]);

    const [isLoading, setIsLoading] = useState(true);

    const [Datas, setDatas] = useState([]);
    // console.log();

    // useEffect(() => {
    //     const jfydhg = async () => {
    //         const fyhjhgjh = await getAdApi()

    //     }
    // }, [currentLanguage]);
    // useEffect(() => {
    //     getadApi(
    //         (response) => {
                
    //             const responseData = response.data;
    //             setDatas(responseData);
    //             console.log(responseData,"bnmzaurybv")
    //             setIsLoading(false);
    //         },
    //         (error) => {
    //             console.log("dfhieufuygb", error);
    //             setIsLoading(false);
    //             alert()
    //             if (error === "வேறு தகவல்கள் இல்லை") {
    //                 setData("");
    //             }
    //         }, 
    //         (erwerwe) => {
    //             alert(2)
    //             console.log("erkhiuwerguwrgfe", erwerwe);
    //         }
    //     );
    // }, [currentLanguage]);

    const [adddata  , setadddata] = useState([]);

    // console.log("rtjergtigetiuetrher", adddata);

    const fetchadd = async () => {
        const response = await getAddUrl();
        setadddata(response.data?.data)
        console.log("jtjeyrgtjehrtbjher", response);
        
    }

    useEffect(() => {
        fetchadd();
    }, [currentLanguage])


    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef(null);

    // handle scroll event
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.pageYOffset > (elTopOffset + elHeight)) {
            setSticky({ isSticky: true, offset: elHeight });
        } else {
            setSticky({ isSticky: false, offset: 0 });
        }
    };

    const siteSettings = async () => {
      const response = await getSiteDetails()
      if(response.status == 200) {
        let data = {};
        response.data?.map((item) => {
        data = {...data, [item.type]: item.message}        
        })
        
        document.title = data.meta_title;

        const descriptionMeta = document.createElement('meta');
        descriptionMeta.name = 'description';
        descriptionMeta.content = data.meta_description;
        document.head.appendChild(descriptionMeta);

        const keywordsMeta = document.createElement('meta');
        keywordsMeta.name = 'keywords';
        keywordsMeta.content = data.tags;
        document.head.appendChild(keywordsMeta);
      }
    }

    // add/remove scroll event listener
    useEffect(() => {
      siteSettings();
        var header = headerRef.current.getBoundingClientRect();
        const handleScrollEvent = () => {
            handleScroll(header.top, header.height)
        }
        window.addEventListener('scroll', handleScrollEvent);
        return () => {
            window.removeEventListener('scroll', handleScrollEvent);
        };
    }, []);

    const themeHandler = ()=>{
      if (document.documentElement?.getAttribute('data-bs-theme') == 'dark') {
          document.documentElement?.setAttribute('data-bs-theme','light')
          setThemeButton("light");
          updateMode("light");
          localStorage.setItem('newsTheme', "light");          
      }
      else {
          document.documentElement?.setAttribute('data-bs-theme','dark')
          setThemeButton("dark");
          updateMode("dark");
          localStorage.setItem('newsTheme', "dark");
      }
  }

    // useEffect(() => {
    //   // Load the Google Ad script asynchronously
    //   (function() {
    //     var script = document.createElement('script');
    //     script.async = true;
    //     script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-123456789';
    //     script.crossOrigin = 'anonymous';
    //     document.body.appendChild(script);
    //   })();
  
    //   // Initialize the adsbygoogle array
    //   window.adsbygoogle = window.adsbygoogle || [];
      
    //   // Define adBreak function
    //   window.adBreak = window.adConfig = function(o) {
    //     window.adsbygoogle.push(o);
    //   };
    // }, []);
    
    
    return (
        <div className={`w-100 sticky`} ref={headerRef}> 
        {/* <div className={`w-100${sticky.isSticky ? ' sticky' : ''}`} ref={headerRef}>  */}
            {/* <div id="sticky-header1" className={`navbar${sticky.isSticky ? ' sticky' : ''}`} ref={headerRef}> */}
            <div id="sticky-header1" className="navbar" ref={headerRef}>
            <nav className={mode == 'light' ? "Newsbar bg-light" : "Newsbar bg-dark"}>
                <div className="container">
                    <div className="navbar_content">
                        <div id="News-logo" className="News-logo" onClick={activateHomeLink}>
                            <a href={window.location?.origin} >
                                <img className="mb-0" id="NewsLogo" src={websettings && websettings.web_footer_logo ? websettings && websettings.web_footer_logo : no_image} onError={(e) => { e.target.src = no_image;}} alt="logo" />
                            </a>
                                <p className={mode == 'light' ? "mb-0 text-nowrap text-dark text-center d-none d-md-block" : "mb-0 text-nowrap text-light text-center d-none d-md-block"}>{moment().format('DD MMMM YYYY')}</p>
                                <p className={mode == 'light' ? "mb-0 text-nowrap text-dark text-center d-block d-md-none" : "mb-0 text-nowrap text-light text-center d-block d-md-none"}>{moment().format('DD MMM YYYY')}</p>
                                

                           <div className="d-flex align-items-center">

                            <div className="calender_icon me-sm-2 d-none">
                                    {/* <p className="mb-0 text-nowrap"><SlCalender />{moment().format('DD MMMM YYYY')}</p> */}
                                    {/* <p className=" mb-0"><SlCalender />{`${month}`} {`${dayOfMonth}`},{`${year}`}</p> */}
                                </div>
                            <div className="left-weather me-3 d-none-1200 d-none">
                                {/* <div className="calender_icon me-2">
                                    <p className=" mb-0"><SlCalender />{`${month}`}{`${dayOfMonth}`},{`${year}`}</p>
                                </div> */}
                                <div className="d-block">
                                    <div className="">
 
                                    <img src={weather && weather.current.condition.icon ? weather && weather.current.condition.icon : no_image} onError={(e) => { e.target.src = no_image;}} alt="weather" className="weather_icon"/>
                                    <b className="me-2">{weather && weather.current.temp_c}°C</b>
                                    </div>
                                    {/* <div className="left-state">
                                        <p className="location-wcard mb-0 ">{addressReturn("locality")},{addressReturn("administrative_area_level_1")},{addressReturn("country")}</p>
                                        <p className="day-Wtype-wcard mb-0 "><HiArrowLongUp/>{maxTempC}°C <HiArrowLongDown/>{minTempC} °C</p>
                                    </div> */}
                                     <p className="mb-0">இன்று வானிலை</p>
                                     </div>
                            </div>
                            </div>

                        </div>

                        {adddata?.map((item) => {
                           if (item.ad_space === "home_page-1")
                            {
                                return (
                                  <a target="black" className="banner_add w-100 px-4" href={item.ad_url}>
                                    {item.ads_type == "google_ads" ? <div className='p-5 overflow-hidden' dangerouslySetInnerHTML={{ __html: item.google_ads }}></div> : <img className="w-100 h-100" src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} alt="icon" />}</a>
                                );
                            }
        
                       })}
                        {/* {adddata?.map((item) => {
                           if (item.ad_space === "home_page-1")
                            {
                                return (
                                  <a target="black" className="px-3 banner_add" href={item.ad_url}>
                                    {item.ads_type == "google_ads" ? <div className='p-5 overflow-hidden' dangerouslySetInnerHTML={{ __html: item.google_ads }}></div> : <img className="w-100 h-100" src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} alt="icon" />}</a>
                                );
                            }
        
                       })} */}

                            <div className="Manu-links d-flex align-items-center ">
                             
                           
                            <ul className="d-none-1200">
                                
                                {/* <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/" onClick={setActiveLink}>
                                            {translate("home")}
                                        </Link>
                                    </b>
                                </li>
                                <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/about-us" onClick={setActiveLink}>
                                            {translate("aboutus")}
                                        </Link>
                                    </b>
                                </li>
                                <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/live-news" onClick={setActiveLink}>
                                            {translate("livenews")}
                                        </Link>
                                    </b>
                                </li>
                                <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/breaking-news-view" onClick={setActiveLink}>
                                            {translate("Breaking News")}
                                        </Link>
                                    </b>
                                </li>
                                <li id="NavHover" className="nav-item">
                                    <b>
                                        <Link id="nav-links" className="link-color" aria-current="page" to="/contact-us" onClick={setActiveLink}>
                                            {translate("contactus")}
                                        </Link>
                                    </b>
                                </li> */}
                                
                                <li id="Nav-btns" className="d-flex">
                                    {isLogin() && checkUserData(userData) ? (
                                        <Dropdown>
                                            <Dropdown.Toggle id="btnSignIn" className="me-2">
                                                <BiUserCircle size={20} id="btnLogo" />
                                                {/* {userName} */}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu style={{ backgroundColor: "#1A2E51" }}>
                                                {/* <Dropdown.Item id="btnLogout">
                                                    <Link id="btnBookmark" to="/bookmark">
                                                        {translate("bookmark")}
                                                    </Link>
                                                </Dropdown.Item> */}
                                                {/*<Dropdown.Item id='btnLogout' onClick={changePassword}>*/}
                                                {/*    Change Password*/}
                                                {/*</Dropdown.Item>*/}
                                                {/* <Dropdown.Divider /> */}
                                                <Dropdown.Item onClick={logout} id="btnLogout" className="">
                                                {translate("வெளியேறு")}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    ) : (<div className="d-flex flex-column justify-content-center flex-wrap">
                                        <Button variant="danger" onClick={() => setModalShow(true) } id="btnSignIn" className="" type="button">
                                                {/* <BiUserCircle size={23} id="btnLogo" /> */}
                                            <i className="fa fa-sign-in mx-2" aria-hidden="true"></i>
                                        </Button>
                                        <span>{translate("உள்நுழைய")}</span>
                                        </div>
                                    )}

                                    {isLogin() ? (
                                        <Link to="/notification" id="btnNotification" type="button" className="btn">
                                            <BiBell size={23} /><span className="noti_badge_data">{notificationbadge}</span>
                                        </Link>
                                    ) : null}                                    

                                    {/* <button id='btnSerch' type="button" onClick={handleSearchModel} className="btn"><BiSearch size={23} /></button> */}
                                    {/* {['bottom-end'].map((placement) => (
                                        <OverlayTrigger
                                        trigger="click"
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                            <Popover.Body id='nb-popover-body' >
                                            <form id='serchModal' className="d-flex" role="search">
                                                                <input id='inputSerchForm' className="form-control me-2" type="search" placeholder="Search" aria-label="Search" onChange={(e)=>{setSearch(e.target.value)}}/>
                                                                <Link id='btnSerchForm' className="btn" type="submit" to={`/search-news-view?Search=${Search}`}>Search</Link>
                                                            </form>
                                            </Popover.Body>
                                            </Popover>
                                        }
                                        >
                                        <Button id='btnSerch' className="btn" variant="secondary"><BiSearch size={23} /></Button>
                                        </OverlayTrigger>
                                    ))} */}
                                </li>                                
                            </ul>                           

                            <SignInModal setIsLogout={setIsLogout} setisloginloading={setisloginloading} show={modalShow} setLoginModalShow={setModalShow} onHide={() => setModalShow(false)} />
                        </div>
                        <div className="hamburger-manu">
                            {["end"]?.map((placement, idx) => (
                                <OffCanvasExample key={idx} placement={placement} name={placement} />
                            ))}
                        </div>
                        <a id="btnTheme" type="button" className="ms-1 ms-md-2 btn" onClick={() => {
                            // document.getElementById('weifrhweurgweruwefruwferuwfr')?.click();
                            themeHandler();
                          }} > 
                          {themeButton == 'dark' ? 
                            <BsSun size={23} /> : <BsMoon size={20} />}
                        </a>
                    </div>  
                </div>
            </nav>
            <div className={ShowManu ? "mobile-manu2" : "mobile-manu"}></div>
            </div>

            <CatNav />
        </div>
    );
};

export default Newsbar;
