import React, { useState, useRef, useEffect } from "react";
import { cenimanews } from "../utils/api";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { Link } from "react-router-dom";
import no_image from "../images/no_image.jpg";
import YouTubeNews from './YouTubeNews';
import {useThemeMode} from '../utils/themeMode'
// import BreakingNewsSection from './BreakingNewsSection';
// import HealthNewsSection from './HealthNewsSection';


function CinemaNews() {
    const {mode} = useThemeMode();
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [spritual, setspritualnews] = useState([]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    const fetchsetspritualnews = async () => {
        const response = await cenimanews();
        if(response.data) {
        setspritualnews(response.data?.data)
        }
    }

    useEffect(() => {
        fetchsetspritualnews();
    }, [currentLanguage])


    const currentDate = new Date(); // Current date and time

    const filteredSpritual = spritual.filter((value) => {
        const showTillDate = new Date(value.show_till);
        return currentDate <= showTillDate; // Compare current date with show_till date
    });

    return (
        <>
            <div className="container pb-lg-4 pb-3">
                <div className="row">
                    <div className="col-lg-8">

                        <div id="rns-head-main" className=""><div className="d-flex align-items-center">   <div className="border_strong_head me-2"></div>
                            <h4 className="mb-0" id="rns-main-logo">சினிமா</h4></div>
                            <Link onClick={() => scrollToTop()} href="/" id="hns-Viewmore" to={"/category-view?id=4&uid=1&name=சினிமா"}><img src="/images/8.png" className="arrow_icon" alt="icon" /></Link>
                        </div>


                        <div className="row px-2 ">
                            {/* <div className="col-lg-3 col-md-4 col-sm-6 col-6 mb-3 px-2 d-lg-block d-none">
                        <div className="">
                            <img src="/images/19.png" id="rns-image" className="w-100 mb-3" alt="icon" />
                        </div>
                    </div> */}
                            {/* {spritual.length > 0 && spritual?.slice(0,4).map((item) => { */}
                            {filteredSpritual.length > 0 ? (
                                filteredSpritual.slice(0, 3).map((item) => {
                                    return (
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-6 mb-3 px-2 " key={item.id}>
                                            <Link onClick={() => scrollToTop()} 
                                            to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + item.subcategory_name}
                                            // to={"/news-view?Nid=" + item.id + "&Cid=" + item.category_id}
                                            // href={item.slug}
                                            >
                                                <div className="">
                                                    <img src={item.image ? item.image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3 img_sritual_ju" alt="icon" />
                                                    <p className={mode == 'light' ? "card-title three_line text-dark fw-bold" : "card-title three_line text-light fw-bold"}>{item.title}</p>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })
                            ) : (
                                // Show "வேறு தகவல்கள் இல்லை" message if filteredSpritual is empty
                                <div className="col-12 text-center mt-4">
                                    <p>வேறு தகவல்கள் இல்லை</p>
                                </div>
                            )}
                        </div>

                    </div>

                    <div className="col-lg-4 mt-lg-4 mt-3 pe-0">
                        <YouTubeNews />
                    </div>
                </div>
            </div>
            {/* <BreakingNewsSection />
            <HealthNewsSection /> */}
        </>
    );
}

export default CinemaNews;
