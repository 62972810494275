import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import VideoPlayerModal from "./VideoPlayerModal";
import { FiCalendar, FiClock } from "react-icons/fi";
import { getvideoApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";
import BreadcrumbNav from "./BreadcrumbNav";
import no_image from "../images/no_image.jpg";

function VideoNewsview() {
    const [Data, setData] = useState([]);
    const [Video_url, setVideo_url] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        getvideoApi(
            (response) => {
                setData(response.data);
                setLoading(false);
            },
            (error) => {
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setData("");
                    setLoading(false);
                }
            }
        );
    }, [currentLanguage]);

    function handleLiveNewsVideoUrl(url) {
        setModalShow(true);
        setVideo_url(url);
    }

    return (
        <>
            <BreadcrumbNav SecondElement={translate("videosLbl")} ThirdElement="0" />
            <div className="my-3">
                <div className="container">
                    {loading ? (
                        <div>
                            <Skeleton height={200} count={3} />
                        </div>
                    ) : (
                        <div className="row">
                            {Data.length > 0 ? (
                                Data?.map((element) => (
                                    <div className="col-lg-4 col-md-6 col-xl-3 col-12 mb-3 px-2" key={element.id}>
                                        {/* <Link id='Link-all' to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id}> */}
                                        <div id="vnv-card" className="card h-100">
                                            <img id="vnv-card-image" src={element.image ? element.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img" alt="..." />
                                            <Link className="card-image-overlay" id="vnv-btnVideo" onClick={() => handleLiveNewsVideoUrl(element.content_value)}>
                                                <BsFillPlayFill id="vnv-btnVideo-logo" fill="red" size={30} />
                                            </Link>

                                            <div id="vnv-card-body" className="card-body">
                                                {/* <button id='vnv-btnCatagory' className='btn btn-sm' type="button" >{element.category_name}</button> */}
                                                <h6 id="vnv-card-title" className="card-title">
                                                    {element.title.slice(0, 53)}
                                                </h6>
                                                <p id="cv-card-date">
                                                    <FiClock size={18} id="vnv-logoCalendar" />
                                                    {element.date.slice(0, 10)}
                                                </p>
                                                {/* <Link id='btnvnvRead' className='btn overlay' type="button" to="/news-view" ><IoArrowForwardCircleSharp size={50}/></Link> */}
                                            </div>
                                        </div>
                                        
                                        <VideoPlayerModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        // backdrop="static"
                        keyboard={false}
                        url={Video_url}
                        // title={Data[0].title}
                    />

                                        {/* </Link> */}
                                    </div>
                                ))
                            ) : (
                                <div className="text-center my-5">{translate("வேறு தகவல்கள் இல்லை")}</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default VideoNewsview;
