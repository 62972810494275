import React, {useContext} from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import {ThemeModeProvider} from './utils/themeMode'


// const ThemeContext = createContext();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter> 
    <ThemeModeProvider>   
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeModeProvider>
  </BrowserRouter>
);
