import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import BreadcrumbNav from "./BreadcrumbNav";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { getbreakingNewsApi } from "../store/actions/campaign";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";
import no_image from "../images/no_image.jpg";

function BreakingNewsView() {
    
    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };
    const [Data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        getbreakingNewsApi(
            (response) => {
                const responseData = response.data;
                setData(responseData);
                setLoading(false);
            },
            (error) => {
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setData("");
                    setLoading(false);
                }
            }
        );
    }, [currentLanguage]);

    return (
        <>
            <BreadcrumbNav SecondElement="Breaking News" ThirdElement="0" />
            <div id="BNV-main">
                <div id="BNV-content" className="container">
                    {loading ? (
                        <div>
                            <Skeleton height={200} count={3} />
                        </div>
                    ) : (
                        <div className="row my-lg-5 my-3">
                            {Data.length > 0 ? (
                                Data?.map((element) => (
                                    <div className="col-md-4 col-sm-6 col-xl-3 col-6 mb-3 px-2" key={element.id}>
                                        <a 
                                        onClick={() => scrollToTop()} 
                                        id="Link-all" 
                                        // to={"/breaking-news?BNid=" + element.id}
                                        href={element.slug}
                                        >
                                            <div id="BNV-card" className="card h-100">
                                                <img id="BNV-card-image" src={element.image ? element.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img" alt="..." />
                                                <div id="BNV-card-body" className="card-body">
                                                    {/* <button id='BNV-btnCatagory' className='btn btn-sm' type="button" >{element.category_name}</button> */}
                                                    <h6 id="BNV-card-title" className="card-title two_line">
                                                        {element.title}
                                                    </h6>
                                                    {/* <p id="BNV-card-date"><FiCalendar size={18} id="BNV-logoCalendar" />{element.date.slice(0, 10)}</p> */}
                                                    {/* <Link id='btnBNVRead' className='btn overlay' type="button" to="/news-view" ><IoArrowForwardCircleSharp size={50}/></Link> */}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                ))
                            ) : (
                                <div className="text-center my-5">{translate("வேறு தகவல்கள் இல்லை")}</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default BreakingNewsView;
