import React, { useEffect, useState, useRef } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import VideoPlayerModal from "./VideoPlayerModal";
import { getvideoApi } from "../store/actions/campaign";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import { translate } from "../utils";
import no_image from "../images/no_image.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);
function VideoPlayerSection() {
    const [Data, setData] = useState([]);
    const [Video_url, setVideo_url] = useState();
    const [modalShow, setModalShow] = React.useState(false);
    const currentLanguage = useSelector(selectCurrentLanguage);

    const showNavigation = Data.length > 1;

    const swiperOption = {
        loop: true,
        speed: 750,
        autoplay: true,
        spaceBetween: 10,
        slidesPerView: 2,
        breakpoints: {
            0: {
                slidesPerView: 2,
            },
            567: {
                slidesPerView: 2,
            },
            999: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },

        },
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            console.log("after change", currentSlide);
        }
    };

    useEffect(() => {
        getvideoApi(
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [currentLanguage]);

    function handleVideoUrl(url) {
        setModalShow(true);
        setVideo_url(url);
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };


    const currentDate = new Date(); // Current date and time

    const filteredSpritual = Data.filter((value) => {
        const showTillDate = new Date(value.show_till);
        return currentDate <= showTillDate; // Compare current date with show_till date
    });

    const swiperRef = useRef();

    return !Data || Data.length === 0 ? null : (
        <div id="vps-main">
            <div className="container position-relative mt-3 mb-4">
                <div className="row ">
                    <div id="vps-head-main" className="">
                        <div className="d-flex align-items-center"> <div className="border_strong_head me-2" ></div><p id="vps-main-logo">{translate("வீடியோ ஆய்வு")}</p></div>
                        {/* <div className="d-flex justify-content-end"><Link href="/" id="vps-Viewmore" onClick={() => scrollToTop()} to={"/video-news-view"}>
                        {translate("மேலும் வாசிக்க")} 
                              <img src="/images/8.png" className="arrow_icon" alt="icon"/>
                        </Link>
                        </div>  */}
                    </div>
                </div>


                <Swiper
                    modules={[Navigation]}
                    onBeforeInit={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                    {...swiperOption}
                    className="h-auto mb-xl-4 mb-md-0 mb-3 mt-sm-0 mt-2 navigation"
                >
                    {filteredSpritual.length > 0 ? (
                        // Render SwiperSlides if there is data
                        filteredSpritual.map((item) => (
                            <SwiperSlide key={item.id} className="">
                                <div id="vps-body-right">
                                    <div className="">
                                        <Card id="vps-main-card" className="text-black">
                                            <Card.Img
                                                id="vps-secondry-images"
                                                src={item.image ? item.image : no_image}
                                                onError={(e) => { e.target.src = no_image;}}
                                                alt="Card image"
                                            />

                                            <Card.ImgOverlay className="p-0">
                                                <div className="d-flex align-items-center justify-content-center position_crd_vedio">
                                                    <Link
                                                        id="vps-btnVideo"
                                                        onClick={() => handleVideoUrl(item.content_value)}
                                                    >
                                                        <BsFillPlayFill
                                                            id="vps-btnVideo-logo"
                                                            fill="red"
                                                            size={60}
                                                        />
                                                    </Link>
                                                </div>
                                            </Card.ImgOverlay>
                                        </Card>
                                        <p className="text-dark mt-3 two_line">{item.title}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    ) : (
                        // Render a "வேறு தகவல்கள் இல்லை" message if the data is empty
                        <p className="text-dark">வேறு தகவல்கள் இல்லை</p>
                    )}

                    <VideoPlayerModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        // backdrop="static"
                        keyboard={false}
                        url={Video_url}
                    // title={Data[0].title}
                    />
                </Swiper>
                <div className="nav_position spectasdc">
                    <button className="nav-review" onClick={() => swiperRef.current?.slidePrev()}>
                        <img src="/images/4.png" className="img_nv pre1" alt="icon" />
                        <img src="/images/5.png" className="img_nv pre2" alt="icon" />
                    </button>
                    <button className="nav-next" onClick={() => swiperRef.current?.slideNext()}>
                        <img src="/images/6.png" className="img_nv next1" alt="icon" />
                        <img src="/images/7.png" className="img_nv next2" alt="icon" />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default VideoPlayerSection;
