import { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { useQuery } from "../Hooks";
import { getcommentbynewsApi, setcommentApi,setlikedislikeApiComment } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/userReducer";
import { translate } from "../utils";
import no_image from "../images/no_image.jpg";
import { AiOutlineLike, AiTwotoneLike } from "react-icons/ai";
import React from "react";
import { Link } from "react-router-dom";
import { setlikedislikeApi } from "../store/actions/campaign";
import { getUser } from "../utils/api";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import Skeleton from "react-loading-skeleton";
import { likegetapi } from "../utils/api";
import { toast } from "react-toastify";
import SignInModal from './SignInModal';
import { FCM } from '../Firebase'
import {useThemeMode} from '../utils/themeMode'

function CommentsView(props) {
    const {mode} = useThemeMode();
    let user = getUser();
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [CheckLike, setCheckLike] = useState(false);
    const [Like, setLike] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const shareUrl = window.location.href;
    const [jkahf, seynz] = useState([]);
    const strchk = jkahf.slice(0, 5);
    const [LoadComments, setLoadComments] = useState(false);
    const [Data, setData] = useState([]);
    const [Comment, setComment] = useState("");
    const query = useQuery();
    const Nid = query.get("Nid");
    const replyRef = useRef();
    const userData = useSelector(selectUser);

    const [likeshow , setlikeshow] = useState([]);
    const [islogout, setIsLogout] = useState(false);
    const [isloginloading, setisloginloading] = useState(true)
 
    const fetchsetsociallinks = async (id) =>{
        if (user !== null) {
        const response = await likegetapi(user);
        if(response.data) {
        setlikeshow(response.data);
        setLoading(false)
        if (response.data[0]?.like == "0") {
            setLike(false);
        } else {
            setLike(true);
        }
        }
    } else {
        setModalShow(true);
    }
    }

    useEffect(()=>{
    
      fetchsetsociallinks()

    },[props.news_id])

    // set like dislike
  
     const setLikeDislikeData = (comment_id,status) => {
        if (user !== null) {
            setlikedislikeApiComment(
                comment_id,
                status,
                (response) => {
                    getcommentbynewsApi(
                        props.news_id,
                        "0",
                        "10",
                        (response) => {
                            
                            setData(response.data);
                            seynz(response.data);
                            // setLoading(false)
                            // if (response.data[0].like === "0") {
                            //     setLike(false);
                            // } else {
                            //     setLike(true);
                            // }
                        },
                        (error) => {
                            if (error === "வேறு தகவல்கள் இல்லை") {
                                setData("");
                                setLoading(false);
                            }
            
                        }
                    );
                },
                (error) => {
                    console.error(error);
                }
            );
        } else {
            setModalShow(true);
        }
    };

    useEffect(() => {
    }, [userData.data])

    useEffect(() => {
        if (user !== null) {
        getcommentbynewsApi(
            props.news_id,
            "0",
            "10",
            (response) => {
                setData(response.data);
                seynz(response.data);
            },
            (error) => {
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setData("");
                    setLoading(false);
                }
            }
        );
    } else {
        setModalShow(true);
    }
    }, [props.Comments]);

    // set comment
    const setCommentData = (e, id) => {
        e.preventDefault();
        setcommentApi(
            id,
            props.news_id,
            Comment,
            props.slug,
            (response) => {
                setData(response.data);
                setLoadComments(true);
                setTimeout(() => {
                    setLoadComments(false);
                    setComment("");
                    toast.success("கருத்து வெற்றிகரமாக அனுப்பப்பட்டது!"); 
                    
                }, 1000);
            },
            (error) => {
                console.error(error);
            }
        );
    };

    // set replay comment
    const setreplyComment = (e, id) => {
        e.preventDefault();
        setcommentApi(
            id,
            props.news_id,
            Comment,
            props.slug,
            (response) => {
                setData(response.data);
                setLoadComments(true);
                setTimeout(() => {
                    setLoadComments(false);
                    setComment("");
                    toast.success("கருத்து வெற்றிகரமாக அனுப்பப்பட்டது!"); 
                }, 1000);
            },
            (error) => {
                console.error(error);
            }
        );
    };


    return (
        <div className="mb-3" id="" Nid={Nid}>
            {Data.length === 0 ? null : <div className="d-flex align-items-center mb-3">
                <div className="border_strong_head me-2"></div>
                <h2 className={mode == 'light' ? "mb-0" : "mb-0 text-light"}>{translate("வாசகர் கருத்து")}</h2></div>}

            <div className="card_theme p-0" >
                {loading ? (
                    <div>
                        <Skeleton height={200} count={3} />
                    </div>
                ) : Data.length > 0 &&
                Data?.map((element) => (

                    <div className="p-3" key={element.id}>
                        <div id="cv-comment">
                            {/* <img id="cs-profile" src={element.profile ? element.profile : no_image} alt="" onError={(e) => { e.target.src = no_image;}} /> */}
                            <div id="cs-card" className="card">

                                <div className="d-flex justify-content-between mb-3 mt-sm-0 mt-3" > <h5>{element.name}</h5>  <p className="text-end">{element.date}</p></div>
                                {/* <Link id="cdbtnReport">Report</Link> */}
                                <p id="cs-card-text" className="card-text">
                                    {element.message}
                                </p>
                                <div className="d-flex align-items-center">

                                    {/* <Button  className="cdbtnReplay me-3" id="likeBtn" onClick={handleClickLike}> 
                                    <i class="fa fa-thumbs-up me-2" aria-hidden="true"></i>{translate("Like")}  {count ? "" :   1}</Button> */}
                                
                                    {/* <button className="cdbtnReplay me-3" id="nv-function" onClick={() => setLikeDislikeData(element?.id,element?.user_id,!Like ? 1 : 0)}>
                                        {Like ? <AiTwotoneLike size={15} className="me-2" /> : <AiOutlineLike size={15} className="me-2" />}
                                        {likeshow.data?.like_count}{translate("Like")}
                                    </button> */}
                                    
                                  
                                    {["bottom-end"]?.map((placement) =>
                                        userData && userData.data ? (
                                            <button className="cdbtnReplay me-3" id="nv-function"
                                            onClick={() => setLikeDislikeData(element?.id,element?.like == 1 ? 2:1)}>
                                               {element?.like == 1 ? <AiTwotoneLike size={15} className="me-2" /> : <AiOutlineLike size={15} className="me-2" />}
                                               {element?.total_like}{translate("Like")}
                                           </button>
                                    ) : 
                                    (   <button className="cdbtnReplay me-3" id="nv-function" onClick={() => setModalShow(true)}>
                                          { <AiOutlineLike size={15} className="me-2" />}
                                          {element?.total_like}{translate("Like")}
                                       </button>)
                                    
                                    )}
                         <SignInModal setIsLogout={setIsLogout} setisloginloading={setisloginloading} show={modalShow} setLoginModalShow={setModalShow} onHide={() => setModalShow(false)}/>
                                   
                                    {/* <button className="cdbtnReplay me-3" id="nv-function"
                                     onClick={() => setLikeDislikeData(element?.id,element?.like == 1 ? 2:1)}>
                                        {element?.like == 1 ? <AiTwotoneLike size={15} className="me-2" /> : <AiOutlineLike size={15} className="me-2" />}
                                        {element?.total_like}{translate("Like")}
                                    </button> */}



                                    {["bottom-end"]?.map((placement) =>
                                        userData && userData.data ? (
                                            <OverlayTrigger
                                                trigger="click"
                                                key={placement}
                                                placement={placement}
                                                rootClose
                                                overlay={
                                                    <Popover className="sjiosh" id={`popover-positioned-${placement}`}>
                                                        <Popover.Header as="h3">{translate("வாசகர் கருத்து")}</Popover.Header>
                                                        <Popover.Body id="cv-replay-propover">
                                                            <form id="cv-replay-form" method="post" onSubmit={(e) => {
                                                                setCommentData(e, element.id);
                                                                document.getElementById(element.id+'p')?.click();
                                                                }}>
                                                                <textarea
                                                                    name=""
                                                                    id="cs-reply-input"
                                                                    cols="30"
                                                                    rows="5"
                                                                     value={Comment}
                                                                    onChange={(e) => {
                                                                        setComment(e.target.value);
                                                                    }}
                                                                    placeholder=" உங்கள் பதிலைப் பகிரவும்..."
                                                                ></textarea>
                                                                <button id="cdbtnsubReplay" type="submit" className="btn">
                                                                    {translate("பதில் சமர்ப்பிக்கவும்")}
                                                                </button>
                                                            </form>
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >

                                                <Button id={`${element.id}p`} className="cdbtnReplay me-3" variant="secondary" ref={replyRef}>
                                                    <i class="fa fa-reply me-2" aria-hidden="true"></i>{translate("Replay")}
                                                </Button>

                                            </OverlayTrigger>
                                        ) : null
                                    )}
                                    {/* <Button className="cdbtnReplay" href="#popup1" >
                                        <i class="fa fa-share-alt me-2" aria-hidden="true"></i>{translate("Share")}
                                    </Button> */}

                                    <div id="popup1" class="overlay">
                                        <div class="popup">

                                            <div className="popop_cards">
                                                <h2>Share</h2>
                                                <a class="close" href="#">&times;</a>
                                                <div class="content">
                                                    {process.env.REACT_APP_FACEBOOK ? (
                                                        <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href={process.env.REACT_APP_FACEBOOK} rel="noreferrer">
                                                            {/* <FaFacebookSquare /> */}
                                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                                        </Link>
                                                    ) : null}
                                                    {process.env.REACT_APP_INSTAGRAM ? (
                                                        <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href={process.env.REACT_APP_INSTAGRAM} rel="noreferrer">
                                                            {/* <FaInstagram />  */}
                                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                                        </Link>
                                                    ) : null}
                                                    {process.env.REACT_APP_LINKEDIN ? (
                                                        <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href={process.env.REACT_APP_LINKEDIN} rel="noreferrer">
                                                            {/* <FaLinkedin />  */}
                                                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                                                        </Link>
                                                    ) : null}
                                                    {process.env.REACT_APP_TWITTER ? (
                                                        <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href={process.env.REACT_APP_TWITTER} rel="noreferrer">
                                                            {/* <FaTwitterSquare />  */}
                                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                                        </Link>
                                                    ) : null}
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {element?.replay.map((ele) => (
                            <div id="" className="d-flex flex-start ps-5" key={ele.id}>
                                {/* <img id="cs-profile" src={ele.profile ? ele.profile : no_image} onError={(e) => { e.target.src = no_image;}} alt="" /> */}
                                <div id="cs-Rcard" className="card">
                                <div className="d-sm-flex justify-content-between mb-3 mt-sm-0 mt-3" >  <h5>{ele.name}</h5>  <p className="text-end">{ele.date}</p></div>
                                   
                                    {/* <Link id="cdbtnReport">Report</Link> */}
                                    <p id="cs-card-text" className="card-text">
                                        {ele.message}
                                    </p>
                                    <div className="d-flex align-items-center">
                                    {/* <button className="cdbtnReplay me-3" id="nv-function" onClick={() => setLikeDislikeData(Nid, !Like ? 1 : 0)}>
                                        {Like ? <AiTwotoneLike size={15} className="me-2" /> : <AiOutlineLike size={15} className="me-2" />}{likeshow.data?.like_count}{translate("Like")}
                                    </button> */}

  
{["bottom-end"]?.map((placement) =>
                                        userData && userData.data ? (
                                            <button className="cdbtnReplay me-3" id="nv-function" onClick={() => setLikeDislikeData(ele?.id,ele?.like == 1 ? 2:1)}>
                                            {ele?.like == 1 ? <AiTwotoneLike size={15} className="me-2" /> : <AiOutlineLike size={15} className="me-2" />}
                                            {ele?.total_like}{translate("Like")}
                                        </button>
                                    ) : 
                                    (   <button className="cdbtnReplay me-3" id="nv-function" onClick={() => setModalShow(true)}>
                                          { <AiOutlineLike size={15} className="me-2" />}
                                          {ele?.total_like}{translate("Like")}
                                       </button>)
                                    
                                    )}

                                    {/* <button className="cdbtnReplay me-3" id="nv-function" onClick={() => setLikeDislikeData(ele?.id,ele?.like == 1 ? 2:1)}>
                                        {ele?.like == 1 ? <AiTwotoneLike size={15} className="me-2" /> : <AiOutlineLike size={15} className="me-2" />}
                                        {ele?.total_like}{translate("Like")}
                                    </button> */}

                                        {["bottom-end"]?.map((placement) =>
                                            userData && userData.data ? (
                                                <OverlayTrigger
                                                    trigger="click"
                                                    key={placement}
                                                    placement={placement}
                                                    rootClose
                                                    overlay={
                                                        <Popover className="sjiosh" id={`popover-positioned-${placement}`}>
                                                            <Popover.Header as="h3">{translate("வாசகர் கருத்து")}</Popover.Header>
                                                            <Popover.Body id="cv-replay-propover">
                                                                <form method="post" onSubmit={(e) => {
                                                                    setreplyComment(e, ele.parent_id);
                                                                    document.getElementById(`${element.id}${ele.parent_id}`)?.click();
                                                                    }}>
                                                                    <textarea
                                                                        name=""
                                                                        id="cs-reply-input"
                                                                        cols="30"
                                                                        rows="5"
                                                                        value={Comment}
                                                                        onChange={(e) => {
                                                                            setComment(e.target.value);
                                                                        }}
                                                                        placeholder="உங்கள் பதிலைப் பகிரவும்..."
                                                                    ></textarea>
                                                                    <button id="cdbtnsubReplay" type="submit" className="btn">
                                                                        {translate("பதில் சமர்ப்பிக்கவும்")}
                                                                    </button>
                                                                </form>
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <Button id={`${element.id}${ele.parent_id}`}  className="cdbtnReplay me-3" variant="secondary" ref={replyRef}>
                                                        <i class="fa fa-reply me-2" aria-hidden="true"></i>{translate("Replay")}
                                                    </Button>
                                                </OverlayTrigger>
                                            ) : null
                                        )}
                                        {/* <Button className="cdbtnReplay" href="#popup1" >
                                            <i class="fa fa-share-alt me-2" aria-hidden="true"></i>{translate("Share")}
                                        </Button> */}
                                    </div>


                                </div>
                            </div>
                        ))}
                    </div>
                ))

                }
            </div>


        </div>
    );
}

export default CommentsView;
