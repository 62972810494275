import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { gettagsApi } from "../store/actions/campaign";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import { translate } from "../utils";
import { getAddUrl } from "../utils/api";
import no_image from "../images/no_image.jpg";

function TagsSection(props) {
    const [Data, setData] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        gettagsApi(
            props.id,
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [currentLanguage]);


    const [adddata , setadddata] = useState([]);

    const fetchadd = async () => {
        const response = await getAddUrl();
        setadddata(response.data?.data)
        console.log("jtjeyrgtjehrtbjher", response);
    }

    useEffect(() => {
        fetchadd();
    }, [currentLanguage])
    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };
    return (
        <div>
            <div id="rns-tags-main" className="mb-4">
                <nav id="tags-nav" className="navbar">
                    <h4 id="rns-nav-logo">
                        <b>{translate("தொடர்புடைய தலைப்புகள்")}</b>
                    </h4>
                </nav>
                <div id="tags-tag">
                    {Data.length > 0 &&
                        Data?.map((element) => (
                            <Link onClick={() => scrollToTop()} id="btnTags" key={element.id} to={"/tag-news-view?Tid=" + element.id} className="btn btn-outline-dark">
                                {element.tag_name}
                            </Link>
                            // <Link onClick={() => scrollToTop()} key={element.id} to={"/tag-news-view?Tid=" + element.id} className="btn">
                            //         <div className="btnh btn-three">
                            //             <span>{element.tag_name}</span>
                            //         </div>
                            // </Link>
                        ))}
                </div>
            </div>
            {adddata.length > 0 && adddata?.map((item) => {
                 if (item.ad_space === "news-detail-3")
                  {
                      return (
                        <a target="black" href={item.ad_url}>
                        <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                      );
                  }
             })}
             {/* <img src="/images/52.png" className="add_img mb-4" alt="icon" /> */}
        </div>
    );
}

export default TagsSection;
