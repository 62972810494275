import React, { useState, useRef, useEffect } from "react";

import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import { setsociallinks } from "../utils/api";
import { TwitterEmbed } from 'react-social-media-embed';

function TwitterNews() {

  // const embeddedCode = `
  // <a href="https://twitter.com/intent/tweet?button_hashtag=vtsindia&ref_src=twsrc%5Etfw" class="twitter-hashtag-button" data-dnt="true" data-show-count="false">Tweet #vtsindia</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  // `;
  const embeddedCode = `
  <a class="twitter-timeline" href="https://twitter.com/reflectNewstn?ref_src=twsrc%5Etfw">Loading...</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  `;
//reflectnewstn
  const [socialline, setsocial] = useState([])

  const fetchsociallink = async () => {
    const response = await setsociallinks();
    if(response.data) {
    setsocial(response.data?.data)
    console.log(response, "jhHGFAUW")
    }
  }

  useEffect(() => {
    fetchsociallink()
  }, [])

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    script.charset = 'utf-8';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [socialline]);

  return (
    <>
        

        {
          socialline.map((data, index) => {
            if (data.type === "twitter") {
              return (
                <div className="d-flex justify-content-center align-items-center">
                  <div>
                  <div className="d-flex align-items-center  justify-content-start mb-4"> <div className="border_strong_head me-2"></div> <h4 className="mb-0 text-theme" id="hns-main-logo">Twitter</h4> </div>
                  {/* <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName={data.message}
                    options={{ height: 530, width: 320 }}
                  /> */}
                    {/* <a
                      className="twitter-timeline"
                      data-width="320"
                      data-height="530"
                      data-theme="light"
                      href="https://twitter.com/reflectnewstn?ref_src=twsrc%5Etfw"
                    >
                      Loading...
                    </a> */}
                    <div className="card br_10">
                      <div className="twit-scrol border-0 overflow-hidden"
                        style={{ width: '320px', height: '415px', display: 'flex', justifyContent: 'center'}}
                        dangerouslySetInnerHTML={{ __html: embeddedCode }}
                      />
                    </div>
                    </div>
                </div>
              )
            }
          })
        }
    </>
  );
}

export default TwitterNews;


{/* <a class="twitter-timeline" href="https://twitter.com/PrimeVideoIN?ref_src=twsrc%5Etfw">Tweets by PrimeVideoIN</a> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script> */}