import React, { useState, useRef, useEffect } from "react";
import { googlenews } from "../utils/api";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);

function GoogleNews() {
    const [google, setgooglenews] = useState([]);

    const fetchgooglenews = async () => {
        try {
        const response = await googlenews();
        if(response.data) {
        setgooglenews(response.data?.data)
        }
       
    } catch (err) {
        console.error(err.message,"err");
      }
    }

    useEffect(() => {
        fetchgooglenews();
    }, [])

    const [Data, setData] = useState([]);

    const showNavigation = Data.length > 1;

    const swiperOption = {
        loop: true,
        speed: 750,
        autoplay: true,
        spaceBetween: 15,
        slidesPerView: 2,
        navigation: showNavigation,

        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            567: {
                slidesPerView: 2,
            },
            767: {
                slidesPerView: 3,
            },
            1200: {
                slidesPerView: 4,
            },
        },
        beforeChange: function (currentSlide, nextSlide) {
        },
        afterChange: function (currentSlide) {
        }
    };
    const swiperRef = useRef();
    return (
        <>
            <div className="container position-relative">
            <div className="d-flex align-items-center mb-4"> <div className="border_strong_head me-2"></div> <p className="mb-0 text-theme" id="hns-main-logo">Google News</p> </div>
            <Swiper modules={[Navigation]}
                                    onBeforeInit={(swiper) => {
                                        swiperRef.current = swiper;
                                    }} {...swiperOption} className="h-auto mb-xl-4 mb-md-0 mb-3 navigation"> 
            {
                google.map((data ,index)=>{
                  return(
                    <SwiperSlide className="h-100 py-3 ">
                        <div className="card_theme h-100 mx-1">
                       <a href={data.link}><h4 className="text-dark two_line mb-3">{data.title}</h4></a> 
                       <p dangerouslySetInnerHTML={{ __html: google[0].description }}></p>
                       </div>
                       </SwiperSlide>   
                  )
                })
            }
          </Swiper>
          <div className="nav_position">
                                    <button className="nav-review" onClick={() => swiperRef.current?.slidePrev()}>
                                        <img src="/images/4.png" className="img_nv pre1" alt="icon" />
                                        <img src="/images/5.png" className="img_nv pre2" alt="icon" />
                                    </button>
                                    <button className="nav-next" onClick={() => swiperRef.current?.slideNext()}>
                                        <img src="/images/6.png" className="img_nv next1" alt="icon" />
                                        <img src="/images/7.png" className="img_nv next2" alt="icon" />
                                    </button>
                                </div>
            </div>

        </>
    );
}

export default GoogleNews;
