import { store } from "../store/store";
import axios from "axios"

const access_key = process.env.REACT_APP_ACCESS_KEY;
const api_url = process.env.REACT_APP_API_URL;

const GENERATE_TOKEN = "generate_token_api";
const LANGUAGE_LIST = "get_languages_list";
const GET_CATEGORIES = "get_category";
const GET_BREAKING_NEWS = "get_breaking_news";
const GET_NEWS = "get_news";
const GET_VIDEO = "get_videos";
const GET_NEWS_BY_CATEGORY = "get_news_by_category";
const GET_BREAKING_NEWS_ID = "get_breaking_news_by_id";
const GET_TAG = "get_tag";
const GET_PAGES = "get_pages";
const GET_LIVE_STREAMING = "get_live_streaming";
const USER_SIGNUP = "user_signup";
const GET_LANGUAGE_JSON_DATA = "get_language_json_data";
const GET_BOOKMARK = "get_bookmark";
const SET_BOOKMARK = "set_bookmark";
const SET_COMMENT = "set_comment";
const GET_COMMENT_BY_NEWS = "get_comment_by_news";
const GET_NEWS_BY_COMMANT_ID = "get_comment_like";

const DELETE_COMMENT = "delete_comment";
const GET_NOTIFICATIONS = "get_notification";
const GET_NEWS_BY_ID = "get_news_by_id";
const SET_LIKE_DISLIKE = "set_like_dislike";
const GET_NEWS_BY_TAG = "get_news_by_tag";
const GET_WEB_SETTINGS = "get_web_settings";
const GET_USER_NOTIFICATION = "get_user_notification";
const DELETE_USER_NOTIFICATION = "delete_user_notification";
const Headline_news ="headlines";
const YOUTUBE_LINKS ="youtube_links";

const SET_LIKE_DISLIKE_COMMANT = "set_comment_like_dislike";

const AD =`${api_url}/ad`;
const UPDATE_FCM =`${api_url}/updateFcm`;

const LIVE_NEWS =`${api_url}/live_news`;

const YOUTUBE_LINKS_API =`${api_url}/youtube_links`;

const SPRITUAl_NEWS =`${api_url}/news_category`;

const CENIMA_NEWS =`${api_url}/news_category`;

const TODAY_SPTL_NEWS =`${api_url}/news_category`;

const IMPORTANT_NEWS =`${api_url}/news_category`;

const SPORTS_NEWS =`${api_url}/news_category`;

const FOOTER_NEWS =`${api_url}/sub_category`;

const FOOTER_WOMENS =`${api_url}/sub_category`;

const FOOTER_SPORTS =`${api_url}/sub_category`;

const FOOTER_CIMEMA =`${api_url}/sub_category`;

const FOOTER_CATEGORY =`${api_url}/sub_category`;

const HEADER_SUB_CATEGORY =`${api_url}/sub_category`;

const INFORMATION_NEWS =`${api_url}/news_category`;

const GOOGLE_NEWS_API=`${api_url}/fetchGoogleNews`;

const LIKE_GET_API=`${api_url}/get_comment_like`;

const COMMENT_LIKE_DISLIKE=`${api_url}/set_comment_like_dislike`;

const GET_DOCIAL_LINKS=`${api_url}/get_social_link`;

const GET_BREAKING_NEWS_LINKS=`${api_url}/get_news_breaking_news`;

const SET_NEWS_VIEW_LINKS=`${api_url}/set_news_view`;

const GET_LATEST_NEWS_LINKS=`${api_url}/latestNews`;

const GET_SITE_DETAILS_LINKS=`${api_url}/get_meta`;


export function getbreakingnewslinks(){
  return axios.get(GET_BREAKING_NEWS_LINKS)
  .then(response =>response)
}

export function getSiteDetails() {
  return axios.get(GET_SITE_DETAILS_LINKS)
  .then(response =>response.data)
}

export function getLatestNewslinks(){
  return axios.get(GET_LATEST_NEWS_LINKS)
  .then(response =>response)
}

export function setNewsCountapi(data){
  return axios.post(SET_NEWS_VIEW_LINKS, data)
  .then(response =>response)
}

export function setFCMUpdateapi(data){
  return axios.post(UPDATE_FCM, data)
  .then(response =>response)
}

export function headerSubCategory(){
  return axios.get(HEADER_SUB_CATEGORY+'?categoryId='+'14')
  .then(response =>response)
}


export function setsociallinks(){
  return axios.get(GET_DOCIAL_LINKS)
  .then(response => response)
}

export function likedislikeapi(){
  return axios.get(COMMENT_LIKE_DISLIKE)
  .then(response => response)
}
// export function GetSellerData(id) {
//   return axios
//     .get(GET_SELLER_DATA + "/" + id)
//     .then((response) => response.data);
// }
export function likegetapi(comment_id){
  return axios.get(LIKE_GET_API+'?comment_id='+ comment_id)
  .then(response => response)
}

export function googlenews(){
  return axios.get(GOOGLE_NEWS_API)
  .then(response => response)
}

export function informationnews(){
  return axios.get(INFORMATION_NEWS+'?categoryId='+'25')
  .then(response =>response)
}


export function footercategory(){
  return axios.get(FOOTER_CATEGORY+'?categoryId='+'13')
  .then(response =>response)
}

export function footercinema(){
  return axios.get(FOOTER_CIMEMA+'?categoryId='+'4')
  .then(response =>response)
}

export function footersports() {
  return axios.get(FOOTER_SPORTS+'?categoryId='+'6')
  .then(response =>  response )
}

export function footerwomens() {
  return axios.get(FOOTER_WOMENS+'?categoryId='+'11')
  .then(response =>  response )
}

export function footersnews() {
  return axios.get(FOOTER_NEWS+'?categoryId='+'12')
  .then(response =>  response )
}

export function sportsnews() {
  return axios.get(SPORTS_NEWS+'?categoryId='+'28')
  .then(response =>  response )
}

export function importantnews() {
  return axios.get(IMPORTANT_NEWS+'?categoryId='+'9')
  .then(response =>  response )
}


export function todayspritualnews() {
  return axios.get(TODAY_SPTL_NEWS+'?categoryId='+'28')
  .then(response =>  response )
}


export function spritualnews() {
  return axios.get(SPRITUAl_NEWS+'?categoryId='+'5')
  .then(response =>  response )
}

export function cenimanews() {
  return axios.get(CENIMA_NEWS+'?categoryId='+'4')
  .then(response =>  response )
}


export function getlivenews() {
  return axios.get(LIVE_NEWS)
  .then(response =>  response )
}


export function getyoutube() {
  return axios.get(YOUTUBE_LINKS_API)
  .then(response =>  response )
}

export const getYoutubelinkApi = () => {
  let { id: language_id } = getLanguage();
  return {
    url: `${YOUTUBE_LINKS}`,
    method: "POST",
    data: {
      access_key: access_key,
      language_id: language_id,
    },
    authorizationHeader: true,
  };
};
// const Headline_news_API = `${access_key}/headlines`;
export function getAddUrl() {
   return axios.get(AD)
   .then(response =>  response )
}

//get language from storage
export const getLanguage = () => {
  let language = store.getState().languages.currentLanguage;

  if (language) {
    return language;
  }
  return false;
};

export const getAdApi = () => {
  let { id: language_id } = getLanguage();
  return {
    url: `${AD}`,
    method: "POST",
    data: {
      access_key: access_key,
      language_id: language_id,
    },
    authorizationHeader: true,
  };
};

// get user
export const getUser = () => {
  let user = store.getState().user
  if (user.data !== null) {
    return user.data.id;
  } else {
    return user = "1";
 }
}

// 1. generate token
export const generateTokenApi = () => {
  return {
    url: `${GENERATE_TOKEN}`,
    method: "POST",
    data: {
      access_key: access_key,
    },
    authorizationHeader: false,
  };
};

// 2. languages
export const getLanguagesApi = () => {
  let { id: language_id } = getLanguage();
  return {
    url: `${LANGUAGE_LIST}`,
    method: "POST",
    data: {
      access_key: access_key,
      language_id: language_id,
    },
    authorizationHeader: true,
  };
};

// 3. get category
export const getCategoriesApi = (offset, limit) => {
  let { id: language_id } = getLanguage();
  return {
    url: `${GET_CATEGORIES}`,
    method: "POST",
    data: {
      access_key: access_key,
      offset: offset,
      limit: limit,
      language_id: language_id,
    },
    authorizationHeader: true,
  };
};


export const getHeadlines = () => {
  return {
    url: `${Headline_news}`,
    method: "POST",
    data: {
      access_key: access_key
    },
    authorizationHeader: true,
  };
};


// 4. get breaking news
export const getBreakingNewsApi = () => {
  let { id: language_id } = getLanguage();
  return {
    url: `${GET_BREAKING_NEWS}`,
    method: "POST",
    data: {
      access_key: access_key,
      language_id: language_id,
    },
    authorizationHeader: true,
  };
};

// 5. get news

// try{
//   setIsLoading(true)
//   const id = Category_id 
//   const response = await getItemByLazyload(id,0);
//   setItems(response.data);
//   setItemsLength(response.count)
//   setIsLoading(false)
// }catch(error){
//   console.error('err',error.message)
// }

export const getNewsApi = (offset, limit, get_user_news, search) => {
  try{
  let { id: language_id } = getLanguage();
  let user = getUser();

  return {
    url: `${GET_NEWS}`,
    method: "POST",
    data: {
      access_key: access_key,
      offset: offset,
      limit: limit,
      user_id: user,
      get_user_news: get_user_news, //pass user_id (get news where user_id=1, news added from app)
      search: search, //{optional}
      language_id: language_id,
    },
    
    authorizationHeader: true,
  }
}catch(error){
  console.error('err',error.message)
}
}


// 6. get video
export const getVideoApi = () => {
    let { id: language_id } = getLanguage();
    return {
      url: `${GET_VIDEO}`,
      method: "POST",
      data: {
        access_key: access_key,
        language_id: language_id,
      },
      authorizationHeader: true,
    };
};

// 7. get news by category
export const getNewsByCategoryApi = (category_id, subcategory_id, offset, limit) => {
  let user = getUser();
  let { id: language_id } = getLanguage();
  
  return {
    url: `${GET_NEWS_BY_CATEGORY}`,
    method: "POST",
    data: {
      access_key:access_key,
	    category_id:category_id,
      subcategory_id:subcategory_id,
      offset:offset,
      limit:limit,
      user_id:user,
      language_id:language_id,
    },
    authorizationHeader: true,
  };
};

// 8. get breaking news id
export const getBreakingNewsIdApi = (breaking_news_id) => {
  let { id: language_id } = getLanguage();
  let user = getUser();
  return {
    url: `${GET_BREAKING_NEWS_ID}`,
    method: "POST",
    data: {
      access_key:access_key,
	    breaking_news_id:breaking_news_id,
	    slug:breaking_news_id,
      user_id:user,
      language_id:language_id,
    },
    authorizationHeader: true,
  };
};

// 9. get tag
export const getTagApi = (id) => {
  let { id: language_id } = getLanguage();
  return {
    url: `${GET_TAG}?news_id=`+id,
    method: "GET",
    data: {
      // access_key:access_key,
      // language_id:language_id,
    },
    authorizationHeader: true,
  };
};

//10. get pages
export const getPagesApi = () => {
  let { id: language_id } = getLanguage();
  return {
    url: `${GET_PAGES}`,
    method: "POST",
    data: {
      access_key:access_key,
      language_id:language_id,
    },
    authorizationHeader: true,
  };
};

//11. get live streaming
export const getLiveStreamingApi = () => {
  let { id: language_id } = getLanguage();
  return {
    url: `${GET_LIVE_STREAMING}`,
    method: "POST",
    data: {
      access_key:access_key,
      language_id:language_id,
    },
    authorizationHeader: true,
  };
};

//12. user signup
export const userSignUpApi = (firebase_id,name,email,mobile,type,profile,status,fcm_id) => {
  return {
    url: `${USER_SIGNUP}`,
    method: "POST",
    data: {
      access_key:access_key,
      firebase_id:firebase_id, //Firebase ID
      name:name,
	    email:email,
	    mobile:mobile,
	    type:type, /// gmail / fb / apple / mobile
      profile:profile, //image url
      status:status, // 1 - Active & 0 Deactive
      fcm_id:fcm_id,
    },
    authorizationHeader: true,
  };
};

//13. get languages json
export const getLanguageJsonDataApi = (code) => {
  return {
    url: `${GET_LANGUAGE_JSON_DATA}`,
    method: "POST",
    data: {
      access_key:access_key,
      code:code,
    },
    authorizationHeader: true,
  };
};

//14. get bookmark
export const getBookmarkApi = (offset,limit) => {
  let { id: language_id } = getLanguage();
  let user = getUser();
  return {
    url: `${GET_BOOKMARK}`,
    method: "POST",
    data: {
      access_key:access_key,
      user_id:user,
      language_id: language_id,
      offset:offset,
      limit:limit,
    },
    authorizationHeader: true,
  };
};

//15. set bookmark
export const setBookmarkApi = (news_id,status) => {
  let user = getUser();
  return {
    url: `${SET_BOOKMARK}`,
    method: "POST",
    data: {
      access_key:access_key,
      user_id:user,
      news_id:news_id,
      status:status,  //1-bookmark, 0-unbookmark
    },
    authorizationHeader: true,
  };
};

// 16. set comment
export const setCommentApi = (parent_id,news_id,message) => {
  let user = getUser();
  return {
    url: `${SET_COMMENT}`,
    method: "POST",
    data: {
      access_key:access_key,
      user_id:user,
      parent_id:parent_id,   //if not exists, set 0
      news_id:news_id,
      // news_id:news_id,
      message:message,
    },
    authorizationHeader: true,
  };
};

// 17. get comment
export const getCommentByNewsApi = (news_id,offset,limit) => {
  let user = getUser();
  return {
    url: `${GET_COMMENT_BY_NEWS}`,
    method: "POST",
    data: {
      access_key:access_key,
      user_id:user,
      news_id:news_id,
      offset:offset,
      limit:limit,
    },
    authorizationHeader: true,
  };
};

//18. delete comment
export const deleteCommentApi = (comment_id) => {
  let user = getUser();
  return {
    url: `${DELETE_COMMENT}`,
    method: "POST",
    data: {
      access_key:access_key,
      user_id:user,
      comment_id:comment_id
    },
    authorizationHeader: true,
  };
};

//19. get notification
export const getNotificationsApi = (offset,limit) => {
  return {
    url: `${GET_NOTIFICATIONS}`,
    method: "POST",
    data: {
      access_key:access_key,
      offset:offset,
      limit:limit
    },
    authorizationHeader: true,
  };
};

//20. get news by id
export const getNewsByIdApi = (news_id) => {
  let user = getUser();
  let { id: language_id } = getLanguage();
  return {
    url: `${GET_NEWS_BY_ID}`,
    method: "POST",
    data: {
      access_key:access_key,
	    // news_id:news_id,
	    slug:news_id,
      user_id:user,
      language_id:language_id
    },
    authorizationHeader: true,
  };
};

export const getNewsByCommantIdApi = (comment_id,status) => {
  let user = getUser();
  let { id: user_id} = getLanguage();
  return {
    url: `${GET_NEWS_BY_COMMANT_ID}`,
    method: "POST",
    data: {
     access_key:access_key,
      user_id:user,
      comment_id:comment_id,
      status:status,
    },
    authorizationHeader: true,
  };
};

//21. set likedislike
export const setLikeDisLikeApi = (news_id,status) => {
  let user = getUser();
  return {
    url: `${SET_LIKE_DISLIKE}`,
    method: "POST",
    data: {
      access_key:access_key,
	    user_id:user,
      slug:news_id,
      // news_id:news_id,
      status:status,    // 1=like, 2=dislike, 0=none
    },
    authorizationHeader: true,
  };
};



export const setLikeDisLikeApicommant = (comment_id,status) => {
  let user = getUser();
  return {
    url: `${SET_LIKE_DISLIKE_COMMANT}`,
    method: "POST",
    data: {
      access_key:access_key,
      user_id:user,
      comment_id:comment_id,
      status:status,    // 1=like, 2=dislike, 0=none
    },
    authorizationHeader: true,
  };
};

//22. get news by tag
export const getNewsByTagApi = (tag_id) => {
  let user = getUser();
  let { id: language_id } = getLanguage();
  return {
    url: `${GET_NEWS_BY_TAG}`,
    method: "POST",
    data: {
      access_key:access_key,
	    user_id:user,
      tag_id:tag_id,
      language_id:language_id
    },
    authorizationHeader: true,
  };
};

//23. get web settings
export const getWebSettingsApi = () => {
  return {
    url: `${GET_WEB_SETTINGS}`,
    method: "POST",
    data: {
      access_key:access_key,
    },
    authorizationHeader: true,
  };
};

//24. get user notification
export const getUserNotificationApi = (offset, limit) => {
  let user = getUser();
  return {
    url: `${GET_USER_NOTIFICATION}`,
    method: "POST",
    data: {
      access_key: access_key,
      user_id:user,
      offset :offset,
      limit:limit,
    },
    authorizationHeader: true,
  };
};

//25. delete user notification
export const DeleteUserNotificationApi = (id) => {
  return {
    url: `${DELETE_USER_NOTIFICATION}`,
    method: "POST",
    data: {
      access_key: access_key,
      id:id,
    },
    authorizationHeader: true,
  };
};
