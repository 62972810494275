import React, { useState, useRef, useEffect } from "react";
import YouTube from 'react-youtube-embed'
import { setsociallinks } from "../utils/api";

function YouTubeNews() {

  const [socialline, setsocial] = useState([])

  const fetchsociallink = async () => {
    const response = await setsociallinks();
    if(response.data) {
    setsocial(response.data?.data)
    console.log(response, "jhHGFAUW")
    }
  }
  useEffect(() => {
    fetchsociallink()
  }, [])

  return (
    <>
      <div className="container pb-lg-4 pb-3">
        <div className="d-flex align-items-center mb-4"> <div className="border_strong_head me-2"></div> <h4 className="mb-0 text-theme" id="hns-main-logo">வீடியோஸ்</h4> </div>
        {/* <YouTube id='A71aqufiNtQ' /> */}

        {
          socialline.map((data, index) => {
            if (data.type === "youtube") {
              return (
                <div className="YouTube">
                  <YouTube id={data.message} height={530} width={350} />
                </div>
              )
            }

          })
        }

      </div>
    </>
  );
}

export default YouTubeNews;
