import { deleteCommentApi, getHeadlines, getYoutubelinkApi,getNewsByCommantIdApi, getBookmarkApi, getBreakingNewsApi,setLikeDisLikeApicommant, getBreakingNewsIdApi, getCategoriesApi, getCommentByNewsApi, getLiveStreamingApi, getNewsApi, getAdApi, getNewsByCategoryApi, getNewsByIdApi, getNotificationsApi, getPagesApi, getTagApi, getVideoApi, setBookmarkApi, setCommentApi, setLikeDisLikeApi, getNewsByTagApi, getUserNotificationApi, DeleteUserNotificationApi } from "../../utils/api"
import { store } from "../store"
import { apiCallBegan } from "./apiActions"


export const getyoutubelinkApi = (onSuccess, onError) => {
    store.dispatch(apiCallBegan({
        ...getYoutubelinkApi(),
        displayToast: false,
        onSuccess,
        onError,
    }));
};

export const getadApi = (onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getAdApi(),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

export const getheadlines = (onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getHeadlines(),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 1. get categories
export const categoriesApi = (offset, limit, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getCategoriesApi(offset, limit),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 2. get breaking news
export const getbreakingNewsApi = (onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getBreakingNewsApi(),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 3. get news
export const getnewsApi = (offset, limit, get_user_news, search, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getNewsApi(offset, limit, get_user_news, search),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};


// 4. get video
export const getvideoApi = (onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getVideoApi(),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 5. get news by category
export const getnewsbycategoryApi = (category_id, subcategory_id, offset, limit, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getNewsByCategoryApi(category_id, subcategory_id, offset, limit),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 6. get breaking news by id
export const getbreakingnewsidApi = (breaking_news_id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getBreakingNewsIdApi(breaking_news_id),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 7. get tags
export const gettagsApi = (id,onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getTagApi(id),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 8. get pages
export const getpagesApi = (onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getPagesApi(),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 9. get live streaming
export const getlivestreamApi = (onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getLiveStreamingApi(),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 9. get bookmark
export const getbookmarkApi = (offset, limit, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getBookmarkApi(offset, limit),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 10. set bookmark
export const setbookmarkApi = (news_id, status, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...setBookmarkApi(news_id, status),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 11. set comment
export const setcommentApi = (parent_id, news_id, message, slug, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...setCommentApi(parent_id, news_id, message),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

//12. get comment
export const getcommentbynewsApi = (news_id, offset, limit, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getCommentByNewsApi(news_id, offset, limit),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

//13. delete comment
export const deletecommentApi = (comment_id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...deleteCommentApi(comment_id),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 14. get notification
export const getnotificationApi = (offset, limit, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getNotificationsApi(offset, limit),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 15. get news by id
export const getnewsbyApi = (news_id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getNewsByIdApi(news_id),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

export const getnewscommantbyApi = (user_id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getNewsByCommantIdApi(user_id),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 16. set likedislike
export const setlikedislikeApi = (news_id, status, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...setLikeDisLikeApi(news_id, status),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};


export const setlikedislikeApiComment = (comment_id, status, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...setLikeDisLikeApicommant(comment_id, status),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 17. get news by tag
export const getnewsbytagApi = (tag_id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getNewsByTagApi(tag_id),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 18. get user notification
export const getusernotificationApi = (offset, limit, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...getUserNotificationApi(offset, limit),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};

// 19. delete user notification
export const deleteusernotificationApi = (id, onSuccess, onError, onStart) => {
    store.dispatch(apiCallBegan({
        ...DeleteUserNotificationApi(id),
        displayToast: false,
        onStart,
        onSuccess,
        onError,
    }));
};