import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import VideoPlayerModal from "./VideoPlayerModal";
import BreadcrumbNav from "./BreadcrumbNav";
import { getlivestreamApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";
import no_image from "../images/no_image.jpg";

function LiveNews(props) {
    const [Data, setData] = useState([]);
    const [Video_url, setVideo_url] = useState();
    const [modalShow, setModalShow] = React.useState(false);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getlivestreamApi(
            (response) => {
                setData(response.data);
                setLoading(false);
            },
            (error) => {
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setData("");
                    setLoading(false);
                }
            }
        );
    }, [currentLanguage]);

    function handleLiveNewsVideoUrl(url) {
        setModalShow(true);
        setVideo_url(url);
    }

    return (
        <>
            <BreadcrumbNav SecondElement="Live News" ThirdElement="0" />

            <div id="LN-main">
                <div id="LN-content" className="container">
                    {loading ? (
                        <div>
                            <Skeleton height={200} count={3} />
                        </div>
                    ) : (
                        <div className="row my-lg-5 my-3">
                            {Data.length > 0 ? (
                                Data?.map((element) => (
                                    <div className="col-md-4 col-sm-6 col-xl-3 col-6 mb-3 px-2" key={element.id}>
                                        <div id="LN-card" className="card h-100">
                                            <img id="LN-card-image" src={element.image ? element.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img" alt="..." />
                                            <Link className="card-image-overlay" id="LN-btnVideo" onClick={() => handleLiveNewsVideoUrl(element.url)}>
                                                <BsFillPlayFill id="LN-btnVideo-logo" fill="red" size={30} />
                                            </Link>

                                            <div id="LN-card-body" className="card-body">
                                                <h6 id="LN-card-title" className="card-title two_line">
                                                    {element.title}
                                                </h6>
                                            </div>
                                        </div>
                                        <VideoPlayerModal show={modalShow} onHide={() => setModalShow(false)} keyboard={false} url={Video_url} />
                                    </div>
                                ))
                            ) : (
                                <div className="text-center my-5">{translate("வேறு தகவல்கள் இல்லை")}</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default LiveNews;
