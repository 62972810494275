import React, { useState, useRef, useEffect } from "react";
import { setsociallinks } from "../utils/api";
import { InstagramEmbed } from 'react-social-media-embed';

function InstagramNews() {

    const [socialline, setsocial] = useState([])

    const fetchsociallink = async () => {
        const response = await setsociallinks();
        if(response.data) {
        setsocial(response.data?.data)
        }
    }

    useEffect(() => {
        fetchsociallink()
    }, [])

    return (
        <>
         
          <div className="d-flex justify-content-center align-items-center">
            <div>
          <div className="d-flex align-items-center justify-content-start mb-4"> <div className="border_strong_head me-2"></div><h4 className="mb-0 text-theme" id="hns-main-logo">Instagram</h4> </div>
            {
                socialline.map((data, index) => {
                    if (data.type === "instagram") {
                        return (
                            // <InstagramEmbed url={data.message} key={index} />
                            // <iframe height={520} scrolling="no" src={`${data?.message}embed/`} class="iframe-without-scroll"></iframe>
                            <iframe
                            // src={`https://www.instagram.com/reflectnewstamil/?utm_source=ig_embed&ig_rid=e9a74a71-7626-41bf-8f92-4b98de24fa89`}
                            src={`https://www.instagram.com/reflectnewstamil/embed`}
                            // src={`https://www.instagram.com/p/${data?.message.split('/').slice(-2, -1)}/embed`}
                            width="320"
                            height="415"                            
                            class="iframe-without-scroll"
                            frameborder="0"
                            scrolling="no"
                            allowtransparency="true"
                          ></iframe>
                        )
                    }
                })
            }
</div>
            </div>
        </>
    );
}

export default InstagramNews;

