import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { AiOutlineLike, AiTwotoneLike, AiOutlineEye } from "react-icons/ai";
import { BsBookmark, BsFillBookmarkFill, BsFillPlayFill } from "react-icons/bs";
import { FiClock } from "react-icons/fi";
import React from "react";
import { useQuery } from "../Hooks";
import RelatedNewsSection from "./RelatedNewsSection";
import { Link, useNavigate } from "react-router-dom";
import TagsSection from "./TagsSection";
import CommentSection from "./CommentSection";
import BreadcrumbNav from "./BreadcrumbNav";
import {useThemeMode} from '../utils/themeMode'
import { FacebookIcon, WhatsappIcon, TwitterIcon, TwitterShareButton, WhatsappShareButton, FacebookShareButton } from "react-share";
import SignInModal from "./SignInModal";
import { getnewsbyApi, setbookmarkApi, setlikedislikeApi } from "../store/actions/campaign";
import { getUser } from "../utils/api";
import { isLogin, translate } from "../utils";
import { Card } from "react-bootstrap";
import VideoPlayerModal from "./VideoPlayerModal";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { selectUser } from "../store/reducers/userReducer";
import { getAddUrl, setNewsCountapi } from "../utils/api";
// import { useLocation } from 'react-router-dom';
import no_image from "../images/no_image.jpg";
import { setActiveLink } from "../utils";
import YouTube from 'react-youtube-embed';
import Footer from './Footer';
import { MdUpdate } from "react-icons/md";
import moment from "moment";
import { FaEdit } from "react-icons/fa";

function NewsView() {
    const {mode, menuMode} = useThemeMode();
    const [Data, setData] = useState([]); // eslint-disable-next-line
    let user = getUser();
    const currentLanguage = useSelector(selectCurrentLanguage);
    const userData = useSelector(selectUser);
    // eslint-disable-next-line
    const [CheckLike, setCheckLike] = useState(false);
    const [Like, setLike] = useState(CheckLike); // eslint-disable-next-line
    const [Bookmark, setBookmark] = useState(false); // eslint-disable-next-line
    // const [FontSize, setFontSize] = useState(14); // eslint-disable-next-line
    const [Video_url, setVideo_url] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [VideomodalShow, setVideoModalShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const query = useQuery();
    const Nid = query.get("Nid");
    const catid = query.get("Cid");
    const slug = query.get("slug");
    const cat = query.get("c");
    const scat = query.get("sc");
    const homePosition = query.get("pos");
    const shareUrl = window.location.href;
    // eslint-disable-next-line
    const [islogout, setIsLogout] = useState(false); // eslint-disable-next-line
    const [isloginloading, setisloginloading] = useState(true); // eslint-disable-next-line
    const [jkahf, seynz] = useState([]);
    const strchk = jkahf.slice(0, 5);

    console.log("qerpiweurgiwuerg", menuMode);

    // useEffect(() => {
    //     const allParagraphs = document.querySelectorAll('p');
    //     const allLinks = document.querySelectorAll('a');
    //     const paragraphStyle = {
    //       fontSize: menuMode+'px',
    //     };
  
    //     allParagraphs.forEach(paragraph => {
    //       for (const property in paragraphStyle) {
    //         paragraph.style[property] = paragraphStyle[property];
    //       }
    //     });
    //     allLinks.forEach(paragraph => {
    //       for (const property in paragraphStyle) {
    //         paragraph.style[property] = paragraphStyle[property];
    //       }
    //     });
    //   }, [menuMode, Data])

    useEffect(() => {
        setTimeout(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        }, 500);
    }, []);

    useEffect(() => {
        getnewsbyApi(
            slug,
            (response) => {
                setData(response.data);
                seynz(response.data)
                console.log("jskgbyuAYHTOLstoapW8", response.data)
                setLoading(false)
                if (response.data[0].bookmark === "0") {
                    setBookmark(false);
                } else {
                    setBookmark(true);
                }

                if (response.data[0].like === "0") {
                    setLike(false);
                } else {
                    setLike(true);
                }
            },
            (error) => {
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setData("");
                    setLoading(false);
                }

            }
        );
    }, [Nid, currentLanguage, Like]);

    // set like dislike
    const setLikeDislikeData = (id, status) => {
        if (user !== null) {
            setlikedislikeApi(
                id,
                status,
                (response) => {
                    setLike(!Like);
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            setModalShow(true);
        }
    };

    // set bookmark
    const setbookmarkData = (newsid, status) => {
        if (user !== null) {
            setbookmarkApi(
                newsid,
                status,
                (response) => {
                    setBookmark(!Bookmark);
                },
                (error) => {
                    console.log(error);
                }
            );
        } else {
            setModalShow(true);
        }
    };

    function handleVideoUrl(url) {
        setVideoModalShow(true);
        setVideo_url(url);
    }

    const increaseCount = async (id) => {
        let body = {
            "user_id": id,
            "news_id": slug
         }
        const response = await setNewsCountapi(body)
    }

    useEffect(() => {
        console.log("ejfuegruwegriwegriwer", userData.data);
        if(userData.data) {
            increaseCount(userData.data?.id);
        } else {
            increaseCount(null);
        }
    }, [userData.data])

    const [adddata, setadddata] = useState([]);

    const fetchadd = async () => {
        const response = await getAddUrl();
        setadddata(response.data?.data)
        console.log("jtjeyrgtjehrtbjher", response);
    }

    useEffect(() => {
        fetchadd();
    }, [currentLanguage])


    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    // useEffect(() => {
    //     setTimeout(() => window.scrollTo({ top: 0 }), 50);
    // }, []);

    // useEffect(() => {
    //     var parentElement = document.getElementById("nv-description");
    //     var innerElements = parentElement?.getElementsByTagName("p");
    //     console.log("sejoiwehtiwher", innerElements);
    //     for (var i = 0; i < innerElements?.length; i++) {
    //         innerElements[i].style.fontSize = FontSize+'px';
    //     }
    //     // innerElements?.style.fontSize = FontSize+'px';
    // }, [FontSize]);

    let kghigt = document.getElementById("youtube_frame_nv");

    useEffect(() => {
        setTimeout(() => {            
            const parentElement = document.getElementById("youtube_frame_nv");
            const divElements = parentElement?.getElementsByTagName("iframe");
            for(let i in divElements) {
                if(divElements[i].width == 0 || divElements[i].width == "0" || divElements[i].width == null || divElements[i].width == undefined) {
                    divElements[i].width = document.getElementById("youtube_frame_nv")?.offsetWidth;
                }
                if(divElements[i].height == 0 || divElements[i].height == "0" || divElements[i].height == null || divElements[i].height == undefined) {
                    divElements[i].height = (document.getElementById("youtube_frame_nv")?.offsetWidth * 9) / 16;
                }
            }
        }, 1000)
    }, [kghigt])

    useEffect(() => {
        setTimeout(() => {            
            const parentElement = document.getElementById("youtube_frame_nv");
            const imgElements = parentElement?.getElementsByTagName("img");
            for(let j in imgElements) {
                if(imgElements[j].width == 0 || imgElements[j].width == "0" || imgElements[j].width == null || imgElements[j].width == undefined) {
                    console.log("shiergwergwergwuer", imgElements);
                    imgElements[j].width = document.getElementById("youtube_frame_nv")?.offsetWidth;
                } 
            }
        }, 1000)
    }, [kghigt]);

    // window.onpopstate = function(event) {
    //     console.log("Back button was pressed", event);
      
    // };

    const navigate = useNavigate();

    window.onpopstate = (event) => {
        // alert()
        setTimeout(() => {
        if(homePosition && homePosition == 'sixth-section') {
            document.getElementById('lefkhiuwhtiuehrteth')?.click();
        } else if(homePosition && homePosition == 'fifth-section')  {
            document.getElementById('ggjhritgiutberjheuy')?.click();
        }}, 2000) 
      };

    return (
        <>      
            {loading ? (
                <div>
                    <Skeleton height={100} count={1} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                    <div className="container mt-3">
                        <div className="row">
                        <div className="col-lg-8 col-12">
                            <Skeleton height={50} count={1} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                            <div className="row">
                                <div className="col-6">
                                    <Skeleton height={25} count={1} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                                </div>
                                <div className="col-6">
                                    <Skeleton height={25} count={1} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                                </div>
                            </div>
                            <Skeleton height={600} count={1} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                        </div>
                        <div className="col-lg-4 col-12">
                            <Skeleton height={50} count={1} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                            <div className="row">
                                <div className="col-6">
                                    <Skeleton height={25} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} count={2} />
                                </div>
                                <div className="col-6">
                                    <Skeleton height={25} count={2} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                                </div>
                            </div>
                            <Skeleton height={600} count={1} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                        </div>
                        </div>
                    </div>
                </div>
            ) : Data && Data.length > 0 ? (
                <>
                    <BreadcrumbNav SecondElement={cat ?? '0'} ThirdElement={scat ?? '0'}/>

                    <div id="nv-main" className="container">
                        <div id="nv-page" className="row">
                            <div className="col-lg-8 col-12">
                                <div >

                                    <div id="nv-body">
                                        <div className="d-sm-flex d-block align-items-center mb-3">
                                            <Link onClick={() => { scrollToTop(); setActiveLink() }}
                                                id="Link-all"
                                                key={Data && Data[0]?.id}
                                                to={{
                                                pathname: "/category-view",
                                                search: `?id=${Data && Data[0]?.category_id}&uid=1&name=${Data && Data[0]?.category_name}`
                                                }}>
                                                <button id="btnnvCatagory" className="btn btn-sm mb-0 me-3" type="button">
                                                    {Data && Data[0].category_name}
                                                </button>
                                            </Link>
                                            {/* <div className="news_view_time w-100 d-flex d-sm-none " >        
                                                <span className="mb-0 text-nowrap" id="cv-card-date">
                                                    <FiClock size={15} id="head-logos" />  {Data && moment(Data[0].date).format('DD-MM-YYYY hh:mm')}
                                                </span>
                                                <span className="mb-0 text-nowrap" id="cv-card-date">
                                                {Data[0].updated_at && <><MdUpdate  size={22} id="head-logos" />  {moment(Data[0].updated_at).format('DD-MM-YYYY hh:mm')}</>}
                                                </span>
                                            </div> */}
                                            <div className="news_view_time w-100 mt-sm-0 mt-3" >        
                                                <span className="mb-0 text-nowrap " id="cv-card-date">
                                                    <FiClock size={15} id="head-logos" />  {Data && moment(Data[0].date).format('DD-MM-YYYY hh:mm')}
                                                </span>
                                                <span className="mb-0 text-nowrap " id="cv-card-date">
                                                {Data[0].updated_at && <><MdUpdate  size={19} id="head-logos" />  {moment(Data[0].updated_at).format('DD-MM-YYYY hh:mm')}</>}
                                                </span>
                                            </div>
                                        </div>
                                        <h1 id="nv-title" className={mode == 'light' ? "mb-3" : "mb-3 text-light"}>{Data && Data[0].title}</h1>
                                        <div id="nv-Header" className={mode == 'light' ? "mb-3 border-bottom border-top" : "mb-3 border-bottom border-top bg-dark-bcb"}>
                                        <div id="nv-left-head">                                                    

                                            {isLogin() ?
                                                    <div id="nv-functions-right" >
                                                        <div id="nv-function-pair" className="me-3">                                                                
                                                            <span id="" className={mode == 'light' ? "d-flex align-items-center text-secondary ps-2" : "d-flex align-items-center text-light ps-2"} onClick={() => setLikeDislikeData(slug, !Like ? 1 : 0)}>
                                                             {Like ? <AiTwotoneLike size={23} /> : <AiOutlineLike size={23} />}<span className="d-flex text-nowrap pt-1 ps-1">{Data && Data[0].total_like}{translate(" likes")} </span>   
                                                            </span>
                                                            {/* <p id="nv-function-text"></p> */}
                                                        </div>
                                                        <span className={mode == 'light' ? "mb-0 text-secondary ms-2 mt-2 me-4" : "mb-0 text-light ms-2 mt-2 me-4"}>
                                                            <AiOutlineEye size={18} id="head-logos" /> {Data && Data[0].total_views}
                                                        </span>
                                                    </div>
                                                    :
                                                    <div id="nv-functions-right" >
                                                        <span className={mode == 'light' ? "mb-0 text-secondary ms-2 mt-2 me-4" : "mb-0 text-light ms-2 mt-2 me-4"}>
                                                            <AiOutlineEye size={18} id="head-logos" /> {Data && Data[0].total_views}
                                                        </span>
                                                        <div id="nv-function-pair">
                                                            <span id="" className={mode == 'light' ? "d-flex align-items-center text-secondary ps-2" : "d-flex align-items-center text-light ps-2"} onClick={() => document.getElementById('btnSignIn')?.click()}>
                                                                <AiOutlineLike size={23} />   
                                                            </span>
                                                            {/* <p id="nv-function-text"></p> */}
                                                        </div>
                                                    </div>}

                                            {/* <p id="head-lables">
                                                <AiOutlineLike size={18} id="head-logos" /> {Data && Data[0].total_like} {translate("likes")}
                                            </p> */}
                                        </div>

                                        <div id="nv-right-head">
                                            {/* <h6 id="nv-Share-Label">{translate("Share")}:</h6> */}
                                            {/* <FacebookShareButton url={shareUrl} title={Data && Data[0].title + " - News"} hashtag={"News"}>
                                                <FacebookIcon size={35} className="rounded" />
                                            </FacebookShareButton> */}

                                             <a
                                               href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                             >
                                               <FacebookIcon size={35} className="rounded" />
                                             </a>
                                             <a
                                              href={`https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <WhatsappIcon size={35} className="rounded" />
                                            </a>

                                            {/* <WhatsappShareButton target="black" url={shareUrl} title={Data && Data[0].title + " - News"} hashtag={"News"}>
                                                <WhatsappIcon size={35} className="rounded" />
                                            </WhatsappShareButton> */}

                                             <a
                                               href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(Data && Data[0].title + " - News")}&url=${encodeURIComponent(shareUrl)}&hashtags=News`}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                             >
                                               <TwitterShareButton url={shareUrl} title={Data && Data[0].title + " - News"} hashtag={"News"}>
                                                <TwitterIcon size={35} className="rounded" />
                                               </TwitterShareButton>
                                             </a>

                                            {/* <TwitterShareButton target="black" url={shareUrl} title={Data && Data[0].title + " - News"} hashtag={"News"}>
                                                <TwitterIcon size={35} className="rounded" />
                                            </TwitterShareButton> */}
                                        </div>
                                    </div>                                        


                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <div className="d-flex align-items-center "><span className={mode == 'light' ? "mb-0 text-secondary me-2" : "mb-0 text-light me-2"}><FaEdit  size={20}/></span> <span className="mb-0 text-theme-light">{Data && Data[0].author_name}</span></div>
                                            <nav id="nv-functions" className="custom-font">

                                                
                                                {isLogin() ?
                                                    <div id="nv-functions-right" className="col-md-2 col-12">
                                                        {/* <div id="nv-function-pair">
                                                        <button id="nv-function" className="btn" onClick={() => setbookmarkData(Data && Data[0].id, !Bookmark ? 1 : 0)}>
                                                            {Bookmark ? <BsFillBookmarkFill size={23} /> : <BsBookmark size={23} />}
                                                        </button>
                                                        <p id="nv-function-text">{translate("saveLbl")}</p>
                                                    </div> */}
                                                        {/* <div id="nv-function-pair">

                                                            <button id="nv-function" className="btn" onClick={() => setLikeDislikeData(Nid, !Like ? 1 : 0)}>
                                                                {Like ? <AiTwotoneLike size={23} /> : <AiOutlineLike size={23} />}
                                                            </button>

                                                            <p id="nv-function-text">{translate("likes")}</p>
                                                        </div> */}
                                                    </div>
                                                    :
                                                    null}
                                            </nav>
                                        </div>
                                        {/* {adddata.length > 0 && adddata?.map((item) => {
                                            if (item.ad_space === "news-detail-1") {
                                                return (
                                                    <a target="black" href={item.ad_url}>
                                                    <img src={Data[0].image ? Data[0].image : no_image} className="detail-pg_images mb-4" alt="icon" /></a>
                                                );
                                            }

                                        })} */}

                                        {/* <img src="/images/56.jpg" className="detail-pg_images mb-4" alt="icon"></img> */}


                                       
                                        {/* <img src="/images/57.png" className="add_img mb-4" alt="icon"></img> */}

                                        {/* <p id="nv-description" className="text-gray" style={{ fontSize: `${FontSize}px` }} dangerouslySetInnerHTML={{ __html: Data && Data[0]?.description.slice(0,10) }}></p> */}

                                        {/* <p className="text-gray">
                                            ராமநாதபுரம் மாவட்டத்தின் தெக்குப்பட்டி கிராமத்தில் சாதியப் பாகுபாடுகள் நிரம்பிக் கிடக்கின்றன. மேலத் தெருவைச் சேர்ந்த மூர்க்கசாமியும் (அருள் நிதி) ஒடுக்கப்பட்ட சமூகத்தைச் சேர்ந்த பூமிநாதனும் (சந்தோஷ் பிரதாப்) உயிர் நண்பர்கள். சாதிய பாகுபாடுகளிலிருந்து தன் மக்களை விடுவிக்க போராடும் பூமிநாதனுக்கு ஆதரவாக இருந்து தன் சொந்த சாதியினரையே எதிர்க்கிறார் மூர்க்கசாமி. இப்படியிருக்க அந்த ஊர் மாவட்ட செயலாளராக இருந்து பதவி பறிக்கப்பட்ட முனியராஜ் (ராஜசிம்மன்) அரசியல் லாபத்துக்காக பூமிநாதனை கொல்ல திட்டம் திட்டுகிறார். அவரின் திட்டம் நிறைவேறியதா? மூர்க்கன் எப்படி இதில் பலிகடாவானார் என்பது திரைக்கதை
                                        </p>
                                        <p className="text-gray">
                                            இருவேறு சமூகங்கள். அவர்களிடையேயான சாதியப் பாகுபாடு. ஒரே சமூகத்தின் இரண்டு பிரிவுகளில் உள்ள ஏற்றத் தாழ்வுகள், தங்களைச் சுற்றியிருக்கும் வாய்ப்புகளை பயன்படுத்தி முன்னேறும் ஒடுக்கப்பட்ட சமூகத்தினர் உள்ளிட்ட பல்வேறு விஷயங்களை காட்சிப்படுத்தியதில் 'கழுவேத்தி மூர்க்கன்' மற்ற வழக்கமான கிராமத்து கதைகளிலிருந்து விலகி நிற்கிறது. குறிப்பாக. ஒடுக்கப்படும் மக்களின் பக்கம் மற்ற சமூகத்தினர் நிற்க வேண்டிய தேவையையும், சுய சாதிப்பற்று சொந்த சாதிக்காரனையே எப்படி பலி கொடுத்துவிடும் என்பதையும் சொன்ன விதம் அடர்த்தி கூட்டுகிறது.
                                        </p>
                                        <p className="text-gray">
                                            கொல பண்றது வீரம் இல்ல; 10 பேர காப்பாத்துறது தான் வீரம்', 'ஒருத்தன் தலைக்கு மேல நீங்க இருக்குறதா நெனைக்கிறீங்க, ஆனா நீங்களே இன்னொருத்த காலுக்கு கீழ தான்னு சொல்றாங்க", 'மீசைங்குறது வெறும் மயிர்' போன்ற வசனங்கள் சொல்ல வந்த கருத்துகளுக்கு உறுதுணையாயிருப்பது படத்தின் கன்டென்ட்டை இன்னும் ஆழமாக்குகிறது.
                                        </p> */}
                                        <div id="vps-body-left">
                                            {Data && Data[0] ? (<>
                                            {Data[0].content_type == "video_youtube" ? 
                                                <YouTube id={Data[0].content_value} height={530} width={350} /> : 
                                                <Card id="vps-main-card" className="text-black">
                                                    <Card.Img id="" src={Data[0].image ? Data[0].image : no_image} onError={(e) => { e.target.src = no_image;}} alt="Card image" />
                                                    <Card.ImgOverlay>
                                                       {Boolean(Data[0].content_value) && (
                                                            <Link id="vps-btnVideo" onClick={() => handleVideoUrl(Data && Data[0].content_value)}>
                                                               <BsFillPlayFill id="vps-btnVideo-logo" fill="red" size={60} />
                                                         </Link>
                                                         )}
                                                    </Card.ImgOverlay>
                                                </Card>}
                                            </>) : null}                                            
                                        </div>


                                        {adddata.length > 0 && adddata?.map((item) => {
                                            if (item.ad_space === "news-detail-2") {
                                                return (
                                                    <a target="black" href={item.ad_url}>
                                                    <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 my-3" alt="icon" /></a>
                                                );
                                            }
                                        })}

                                        {/* <CarouselSection images={Data[0].image}/> */}
                                        {/* <img id="nv-image" src={Data && Data[0].image} alt="..." /> */}

                                        <div id="youtube_frame_nv" className={mode == 'light' ? '' : 'text-light'} dangerouslySetInnerHTML={{ __html: Data && Data[0].description }}></div>

                                        {adddata.length > 0 && adddata?.map((item) => {
                                            if (item.ad_space === "news-detail-4") {
                                                return (
                                                    <a target="black" href={item.ad_url}>
                                                    <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 my-3" alt="icon" /></a>
                                                );
                                            }

                                        })}
                                        {/* <img src="/images/47.png" className="add_img mb-4" alt="icon"/> */}

                                        {/* <p className="text-gray">ராமநாதபுரம் மாவட்டத்தின் தெக்குப்பட்டி கிராமத்தில் சாதியப் பாகுபாடுகள் நிரம்பிக் கிடக்கின்றன. மேலத் தெருவைச் சேர்ந்த மூர்க்கசாமியும் (அருள் நிதி) ஒடுக்கப்பட்ட சமூகத்தைச் சேர்ந்த பூமிநாதனும் (சந்தோஷ் பிரதாப்) உயிர் நண்பர்கள். சாதிய பாகுபாடுகளிலிருந்து தன் மக்களை விடுவிக்க போராடும் பூமிநாதனுக்கு ஆதரவாக இருந்து தன் சொந்த சாதியினரையே எதிர்க்கிறார் மூர்க்கசாமி. இப்படியிருக்க அந்த ஊர் மாவட்ட செயலாளராக இருந்து பதவி பறிக்கப்பட்ட முனியராஜ் (ராஜசிம்மன்) அரசியல் லாபத்துக்காக பூமிநாதனை கொல்ல திட்டம் திட்டுகிறார். அவரின் திட்டம் நிறைவேறியதா? மூர்க்கன் எப்படி இதில் பலிகடாவானார் என்பது திரைக்கதை</p> */}
                                    </div>                                   

                                    {/* <img src="/images/53.png" className="add_img mb-4" alt="icon" /> */}


                                    
                                    {/* <p id='nv-description' dangerouslySetInnerHTML={{__html: Data[0].description}}></p> */}
                                    <CommentSection slug={slug} news_id={Data[0]?.id} />
                                </div>
                            </div>
                            <div id="nv-right-section" className="col-lg-4 col-12">
                                <RelatedNewsSection Cid={catid} />
                                <TagsSection id={Data[0]?.id}/>
                                {adddata.length > 0 && adddata?.map((item) => {
                                    if (item.ad_space === "news-detail-5") {
                                        return (
                                            <a target="black" href={item.ad_url}>
                                            <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                                        );
                                    }

                                })}
                            </div>
                        </div>

                        {adddata.length > 0 && adddata?.map((item) => {
                            if (item.ad_space === "news-detail-6") {
                                return (
                                    <a target="black" href={item.ad_url}>
                                    <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" /></a>
                                );
                            }

                        })}

                        {/* <img src="/images/54.png" className="add_without_border mb-4" alt="icon" /> */}

                        <VideoPlayerModal
                            show={VideomodalShow}
                            onHide={() => setVideoModalShow(false)}
                            // backdrop="static"
                            keyboard={false}
                            url={Video_url}
                        // title={Data[0].title}
                        />
                        <SignInModal setIsLogout={setIsLogout} setisloginloading={setisloginloading} show={modalShow} setLoginModalShow={setModalShow} onHide={() => setModalShow(false)} />
                    </div>
                    <Footer />
                </>
            ) : (
                <div className="text-center my-5">{translate("வேறு தகவல்கள் இல்லை")}</div>
            )}
        </>
    );
}

export default NewsView;
