import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { BsPlayCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Breathing } from "react-shimmer";
import { getbreakingNewsApi } from "../store/actions/campaign";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";
import Slider from "react-slick";
import { FiCalendar, FiClock } from "react-icons/fi";
import { getheadlines } from "../store/actions/campaign";
import { getCategory, getHeadlines, getlivenews ,getbreakingnewslinks} from "../utils/api";
import no_image from "../images/no_image.jpg";
import YouTubePlayer from "react-player/youtube";
import ReactPlayer from "react-player";
import {useThemeMode} from '../utils/themeMode';
import Marquee from "react-fast-marquee";
SwiperCore.use([Navigation, Pagination, Autoplay]);

function NewsCardFS(props) {
    // const [category, setcategory] = useState([]);
    // const fetchCategory = async () => {
    //     try {
    //    const responcedata = await getCategory()
    //    setcategory(responcedata.data)
    //    console.log("jhgg", responcedata.data)
    //     }catch (error) {
    //         console.error(error);

    //       }
    // }


    // useEffect(() => {
    //    fetchCategory();
    // }, [])

    const {mode} = useThemeMode();
    const [Data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [counter, setCounter] = useState(0);
    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        getbreakingNewsApi(
            (response) => {
                const responseData = response.data;
                setData(responseData);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setData("");
                }
            }
        );
    }, [currentLanguage]);

    const truncate = (input) => (input?.length > 180 ? `${input.substring(0, 180)}...` : input);

    const swiperOption = {
        loop: false,
        speed: 750,
        spaceBetween: 10,
        slidesPerView: 1,
        navigation: false,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        // pagination: { clickable: true },
    };

    const [Datas, setDatas] = useState([]);

    useEffect(() => {
        getheadlines(
            (response) => {
                const responseData = response.data;
                setDatas(responseData);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setDatas("");
                }
            }
        );

        // getheadlines()
    }, []);



    const [livenews, setlivenews] = useState([]);

    const fetchlivenews = async () => {
        const response = await getlivenews();
        if(response.data) {
        setlivenews(response.data?.data)
        }
    }

    useEffect(() => {
        fetchlivenews();

    }, [currentLanguage])


    const videoRef = useRef();
    const [stop, setStop] = useState(false);

    const handleVideo = () => {
        setStop(!stop);
        if (stop === true) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
    };
    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };



    const [adddata, setadddata] = useState([]);

    const fetchadd = async () => {
        const response = await getbreakingnewslinks();
        if(response.data) {
            setadddata(response.data?.data);
        }
    }

    useEffect(() => {
        fetchadd();
    }, [])

    const settings = {
        dots: false,
        autoplay: true,
        slidesToShow: 4,
        loop: false,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        infinite: false,
        beforeChange: function (currentSlide, nextSlide) {
        },
        afterChange: function (currentSlide) {
        }
    };

    const headNewsRef = useRef();

    // console.log("thweoweuirweuir", headNewsRef.current.offsetHeight);
    const jehrgwruwe = () => {
        if (headNewsRef.current) {
            headNewsRef.current.scrollTop += 99.5;
        }
    }

    useEffect(() => {
    setInterval(() => {
        jehrgwruwe();
    }, 5000);
    }, []);

    // useEffect(() => {
    //     if(counter%5 == 0) {
    //         jehrgwruwe();
    //     }
    // }, [counter])

    return (
        <div className="container py-lg-4 py-3 px-0">
            <div className="px-3">
                <div className={mode == 'light' ? "bg_marqyre mb-xl-5 mb-md-4 mb-3" : "bg_marqyre mb-xl-5 mb-md-4 mb-3 bg-dark-marque"}>
                    <div className="txt_anrer"> <img src="/images/43.png" className="img_icon_home" alt="icon" /><span className="d-md-block d-none">தலைப்பு செய்திகள்</span></div>
                    <div className="marquresfgscz">
                        
                    
                    {/* <marquee behavior="smooth" scrollamount="4" onMouseEnter={(e) => e.currentTarget.stop()} onMouseLeave={(e) => e.currentTarget.start()}>
                        <div className="d-flex">
                            {adddata.length > 0 &&
                            adddata?.map((item) => {
                                if (item.status === "1") {
                                    return (
                                <div className="d-flex align-items-center" key={item.id}>
                                <div className="me-5">
                                    <div>
                                    <i className="fa fa-dot-circle-o me-1 fs-10" aria-hidden="true"></i>{" "}
                                    <Link
                                        onClick={() => scrollToTop()}
                                        id="Link-all"
                                        to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + item.subcategory_name}
                                        // href={item.slug}
                                        className="text-dark"
                                    >
                                        {item.title}
                                    </Link>{" "}
                                    </div>
                                </div>
                                </div>
                                    )
                                }
                            }
                            )}
                        </div>
                        </marquee> */}

            <Marquee
                // gradient={true}
                // gradientColor={[11, 11, 11]}
                direction="left"
                pauseOnHover={true}
              >
                <div
                  data-scroll
                  data-scroll-direction="horizontal"
                  data-scroll-speed="-3"
                  className="text-4xl md:text-6xl h-full font-bold tracking-widest uppercase d-flex"
                >
                    {adddata?.map((item) => {
                    if (item.status === "1") {
                        return (
                        <div className="me-5">
                            <i className="fa fa-dot-circle-o me-1 fs-10" aria-hidden="true"></i>{" "}
                            <Link
                                onClick={() => scrollToTop()}
                                id="Link-all"
                                to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + (item.subcategory_name ?? '')}
                                // href={item.slug}
                                className="text-dark"
                            >
                                {item.title}
                            </Link>{" "}
                        </div>
                    )}})}
                </div>
              </Marquee>


                        {/* <marquee behavior="scroll" scrollamount="4">
                            <div className="d-flex">
                                {Data.length > 0 && Data?.map((item) => {
                                    return (
                                        <div className="d-flex align-items-center ">
                                            <div className="me-5">
                                                <div key={item.id}> <i className="fa fa-dot-circle-o me-1" aria-hidden="true"></i>  <Link onClick={() => scrollToTop()} id="Link-all" to={"/news-view?Nid=" + item.id + "&Cid=" + item.category_id} className="text-dark">{item.title}</Link>  </div>
                                            </div>
                                        </div>
                                    );
                                })}</div>
                        </marquee> */}

                    </div>
                    {/* <div className="marquee-container">
                        {adddata?.map((item, index) => (
                            <div className="marquee-item" key={index}>
                                {item.title}
                            </div>
                        ))}
                    </div>   */}
                </div>
            </div>
            <div className="row w-100 m-0">
                <div className="col-lg-8 mb-lg-0 mb-3">
                    {livenews.length > 0 && livenews?.map((item) => {
                        {
                            return (
                                <div className="w-100 position-relative h-100">
                                    <iframe
                                        src={`https://www.youtube.com/embed/${item.url?.split('v=')[1]}`}
                                        title="YouTube Video Player"
                                        className="w-100 h-100 home_banner"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" autoplay muted allowFullScreen></iframe>

                                    {/* <ReactPlayer
                                         url={item.url}
                                         playing={true}
                                         className="home_banner w-100 h-100" 
                                       />   */}

                                    {/* <video 
            autoPlay={false} 
            preload={false} 
            controls={false} 
            ref={videoRef} 
            poster={item.image ? item.image : no_image} 
            onError={(e) => { e.target.src = no_image;}} 
            className="home_banner" >

                <source
                 src={item.url} 
                 type={item.type}/>

            </video> */}
                                    {/* <div  className="overlay_home_image">
               <div className="mb-3 ms-3">

               <a className="cursor-pointer" onClick={handleVideo}> 
                   <div className="d-flex align-items-center">
                   <div className="icon_youtubr_overlay "><i className="fa fa-youtube-play" aria-hidden="true"></i></div>
                      <h6 className="text-white mb-0 ms-3">நேரடி ஒளிபரப்பு</h6>
            
                   </div></a> 
               </div>
          </div> */}
                                </div>
                                //     <div className="w-100 position-relative h-100">
                                //     <img src={item.image} onError={(e) =>
                                //         (e.currentTarget.src = "/images/no_image.jpeg")
                                //       } className="home_banner" alt="icon" />
                                //     <div className="overlay_home_image">
                                //         <div className="mb-3 ms-3">

                                //         <a href={item.url}> 
                                //             <div className="d-flex align-items-center">
                                //             <div className="icon_youtubr_overlay "><i className="fa fa-youtube-play" aria-hidden="true"></i></div>
                                //                <h6 className="text-white mb-0 ms-3">நேரடி ஒளிபரப்பு</h6>

                                //             </div></a> 
                                //         </div>
                                //     </div>
                                // </div>
                            );
                        }

                    })}

                </div>
                <div className="col-lg-4 d-none d-lg-block">
                    <div className={mode == 'light' ? "custom-swiper ul_home bg-light" : "custom-swiper ul_home bg-dark"} >
                        <div className="d-flex align-items-center mb-3"><img className="headre_icon me-3" src="/images/9 change.png"></img>
                            <h4>தலைப்பு செய்திகள்</h4>
                        {/* <button type="button" onClick={jehrgwruwe} >click</button> */}

                        </div>

                        <Slider {...settings} className="d-none">
                            {isLoading ? (
                                <div className="col-12 loading_data">
                                    <Skeleton height={90} count={4} />
                                </div>
                            ) : adddata.length > 0 ? (
                                adddata?.map((item, i) =>{
                                    if (item.status === "1") {
                                        return (
                                    <div key={item.id} className="li_home">
                                        <div id="fs-main" className="h-100">
                                            <div id="body-first-section">
                                                <div className="d-flex">
                                                    <div id="right-first-section" className="">
                                                        <Link onClick={() => scrollToTop()}
                                                         to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + (item.subcategory_name ?? '')}
                                                        //  to={"/breaking-news?slug=" + item.slug}
                                                        // href={item.slug}
                                                         >
                                                            <img
                                                                src={item.image ? item.image : no_image} onError={(e) => { e.target.src = no_image;}}
                                                                className="float-end fs-Newscard-image"
                                                                id="fs-Newscard-image"
                                                                fallback={<Breathing width={800} height={600} />}
                                                                alt="..."
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="w-100 ps-2">
                                                        <div id="Left-first-section" className="">
                                                            <Link onClick={() => scrollToTop()} 
                                                            //  href={item.slug}
                                                            to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + (item.subcategory_name ?? '')}
                                                            // to={"/breaking-news?slug=" + item.slug}
                                                            >
                                                                <p className="text-dark" id="Top-Title">
                                                                    {item.title}
                                                                </p>
                                                            </Link>
                                                            <p id="cv-card-date" className="w-100 mb-0">
                                                                <FiClock size={18} id="cv-logoClock" className="me-2" />
                                                                {item.date}
                                                            </p>
                                                            <div>{/* Add any child elements here */}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        );
                                    }
                                }
                                )
                            ) : (
                                // Show "வேறு தகவல்கள் இல்லை" message if no data is available
                                <div className="col-12 no_data mt-5">
                                    <p className="text-center">{translate("நிர்வாக குழுவிலிருந்து தரவைச் சேர்க்கவும்")}</p>
                                </div>
                            )}
                        </Slider>
                        <div className="home_head_news overflow-x-hidden" ref={headNewsRef}>                            
                        {isLoading ? (
                                <div className="col-12 loading_data">
                                    <Skeleton height={90} count={4} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                                </div>
                            ) : adddata.length > 0 ? (
                                adddata?.map((item, i) =>{
                                    if (item.status === "1") {
                                        return (
                                    <div key={item.id} className="li_home">
                                        <div id="fs-main" className="h-100">
                                            <div id="body-first-section" className="">
                                                <div className="d-flex">
                                                    <div id="right-first-section" className="">
                                                        <Link onClick={() => scrollToTop()}
                                                         to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + (item.subcategory_name ?? '')}
                                                        //  to={"/breaking-news?slug=" + item.slug}
                                                        // href={item.slug}
                                                         >
                                                            <img
                                                                src={item.image ? item.image : no_image} onError={(e) => { e.target.src = no_image;}}
                                                                className="float-end fs-Newscard-image"
                                                                id="fs-Newscard-image"
                                                                fallback={<Breathing width={800} height={600} />}
                                                                alt="..."
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="w-100 ps-2">
                                                        <div id="Left-first-section" className={mode == 'light' ? "" : "bg-dark"}>
                                                            <Link onClick={() => scrollToTop()} 
                                                            //  href={item.slug}
                                                            to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + (item.subcategory_name ?? '')}
                                                            // to={"/breaking-news?slug=" + item.slug}
                                                            >
                                                                <p className={mode == 'light' ? "text-dark" : "text-light"} id="Top-Title">
                                                                    {item.title}
                                                                </p>
                                                            </Link>
                                                            <p id="cv-card-date" className="w-100 mb-0">
                                                                <FiClock size={18} id="cv-logoClock" className="me-2" />
                                                                {item.date}
                                                            </p>
                                                            <div>{/* Add any child elements here */}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        );
                                    }
                                }
                                )
                            ) : (
                                // Show "வேறு தகவல்கள் இல்லை" message if no data is available
                                <div className="col-12 no_data mt-5">
                                    <p className="text-center">{translate("நிர்வாக குழுவிலிருந்து தரவைச் சேர்க்கவும்")}</p>
                                </div>
                            )}
                        </div>
                    
                        <Slider {...settings} className="d-none">
                            {isLoading ? (
                                <div className="col-12 loading_data ">
                                    <Skeleton height={20} count={22} />
                                </div>
                            ) : Data.length > 0 ? (
                                Data?.map((item) => (
                                    <div key={item.id} className="li_home">
                                        <div id="fs-main" className="h-100">
                                            <div id="body-first-section">
                                                <div className="d-flex">
                                                    <div id="right-first-section" className="">
                                                        <a onClick={() => scrollToTop()}
                                                        //  to={"/breaking-news?BNid=" + item.id}
                                                        href={item.slug}
                                                         >
                                                            <img
                                                                src={item.image ? item.image : no_image} onError={(e) => { e.target.src = no_image;}}
                                                                className="float-end fs-Newscard-image"
                                                                id="fs-Newscard-image"
                                                                fallback={<Breathing width={800} height={600} />}
                                                                alt="..."
                                                            />
                                                        </a>
                                                    </div>
                                                    <div className="w-100 ps-2">
                                                        <div id="Left-first-section" className="">
                                                            <a onClick={() => scrollToTop()} 
                                                             href={item.slug}
                                                            // to={"/breaking-news?BNid=" + item.id}
                                                            >
                                                                <p className="text-dark" id="Top-Title">
                                                                    {item.title}
                                                                </p>
                                                            </a>
                                                            <p id="cv-card-date" className="w-100 mb-0">
                                                                <FiClock size={18} id="cv-logoClock" className="me-2" />
                                                                {item.created_at}
                                                            </p>
                                                            <div>{/* Add any child elements here */}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                // Show "வேறு தகவல்கள் இல்லை" message if no data is available
                                <div className="col-12 no_data mt-5">
                                    <p className="text-center">{translate("நிர்வாக குழுவிலிருந்து தரவைச் சேர்க்கவும்")}</p>
                                </div>
                            )}
                        </Slider>


                    </div>
                </div>


                <div className="col-lg-4 d-block d-lg-none">
                    <div className={mode == 'light' ? "custom-swiper ul_home bg-light" : "custom-swiper ul_home bg-dark"} >
                        <div className="d-flex align-items-center mb-3"><img className="headre_icon me-3" src="/images/9 change.png"></img>
                            <h4>தலைப்பு செய்திகள்</h4>
                        {/* <button type="button" onClick={jehrgwruwe} >click</button> */}

                        </div>
                        <div className="home_head_news overflow-x-hidden">                            
                        {isLoading ? (
                                <div className="col-12 loading_data">
                                    <Skeleton height={90} count={4} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} />
                                </div>
                            ) : adddata.length > 0 ? (
                                adddata?.slice(0, 5).map((item, i) =>{
                                    if (item.status === "1") {
                                        return (
                                    <div key={item.id} className="li_home">
                                        <div id="fs-main" className="h-100">
                                            <div id="body-first-section" className="">
                                                <div className="d-flex">
                                                    <div id="right-first-section" className="">
                                                        <Link onClick={() => scrollToTop()}
                                                         to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + (item.subcategory_name ?? '')}
                                                        //  to={"/breaking-news?slug=" + item.slug}
                                                        // href={item.slug}
                                                         >
                                                            <img
                                                                src={item.image ? item.image : no_image} onError={(e) => { e.target.src = no_image;}}
                                                                className="float-end fs-Newscard-image"
                                                                id="fs-Newscard-image"
                                                                fallback={<Breathing width={800} height={600} />}
                                                                alt="..."
                                                            />
                                                        </Link>
                                                    </div>
                                                    <div className="w-100 ps-2">
                                                        <div id="Left-first-section" className={mode == 'light' ? "" : "bg-dark"}>
                                                            <Link onClick={() => scrollToTop()} 
                                                            //  href={item.slug}
                                                            to={"/news-view?slug=" + item.slug + "&c=" + item.category_name + "&sc=" + (item.subcategory_name ?? '')}
                                                            // to={"/breaking-news?slug=" + item.slug}
                                                            >
                                                                <p className={mode == 'light' ? "text-dark" : "text-light"} id="Top-Title">
                                                                    {item.title}
                                                                </p>
                                                            </Link>
                                                            <p id="cv-card-date" className="w-100 mb-0">
                                                                <FiClock size={18} id="cv-logoClock" className="me-2" />
                                                                {item.date}
                                                            </p>
                                                            <div>{/* Add any child elements here */}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        );
                                    }
                                }
                                )
                            ) : (
                                // Show "வேறு தகவல்கள் இல்லை" message if no data is available
                                <div className="col-12 no_data mt-5">
                                    <p className="text-center">{translate("நிர்வாக குழுவிலிருந்து தரவைச் சேர்க்கவும்")}</p>
                                </div>
                            )}
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewsCardFS;
