import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { translate } from "../utils";
import {useThemeMode} from '../utils/themeMode'

function BreadcrumbNav(props) {
    const {mode} = useThemeMode();
    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };
    return (
        <Breadcrumb id="bcb-main" className={mode == 'light' ? "mt-3" : "mt-3 bg-dark-bcb"}>
            <div className="container">
                <div className="breadcrumb_data">
                    <Breadcrumb.Item id="bcb-item">
                        <Link onClick={() => scrollToTop()} style={{ textDecoration: "none" }} className="d-flex align-items-center fw-bold" id="bcb-link-text" to="/">
                            {/* <FaHome size={25} id="bcb-home-logo" />  */}
                            <div className="border_strong_head me-2"></div>
                           <span className={mode == 'light' ? "mt-1" : "mt-1 text-light"}>{translate("முகப்பு")}</span> 
                        </Link>
                    </Breadcrumb.Item>
                    {props.SecondElement === "0" || props.SecondElement === 0 || props.SecondElement === "" || props.SecondElement === null || props.SecondElement === undefined || props.SecondElement === "undefined" ? null : (
                    <Breadcrumb.Item active id="bcb-active-item" className="p-0">
                        {props.SecondElement}
                    </Breadcrumb.Item>)}
                    {props.ThirdElement === "0" || props.ThirdElement === 0 || props.ThirdElement === "" || props.ThirdElement === null || props.ThirdElement === undefined || props.ThirdElement === 'undefined' ? null : (
                        <Breadcrumb.Item active id="bcb-third-item" className="p-0">
                            {props.ThirdElement}
                        </Breadcrumb.Item>
                    )}
                    {props.FourthElement === "0" || props.FourthElement === 0 || props.FourthElement === "" || props.FourthElement === null || props.FourthElement === undefined || props.FourthElement === 'undefined' ? null : (
                        <Breadcrumb.Item active id="bcb-third-item" className="p-0">
                            {props.FourthElement}
                        </Breadcrumb.Item>
                    )}

                    {/* <Breadcrumb.Item active>Data</Breadcrumb.Item> */}
                </div>
            </div>
        </Breadcrumb>
    );
}

export default BreadcrumbNav;
