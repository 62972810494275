import React, { useState, useRef, useEffect } from "react";
import { FacebookProvider, EmbeddedPost, Page } from 'react-facebook';
import { setsociallinks } from "../utils/api";
import { FacebookEmbed } from 'react-social-media-embed';

function FacebookNews() {

    const [socialline, setsocial] = useState([])

    const fetchsociallink = async () => {
        const response = await setsociallinks();
        if(response.data) {
        setsocial(response.data?.data)
        }
    }
    useEffect(() => {
        fetchsociallink()
    }, [])
    // const iframeUrl = `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F587075654802606&tabs=timeline&width=500&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`;
    return (
        <>
               
                {
                    socialline.map((data, index) => {
                        if (data.type === "facebook") {
                            return (
                                <div className="d-flex justify-content-center align-items-center">
                                     <div > 
                                     <div className="d-flex align-items-center justify-content-start mb-4"> <div className="border_strong_head me-2"></div> <h4 className="mb-0 text-theme" id="hns-main-logo">Facebook</h4> </div>
                                    
                                    {/* <iframe
                                    src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${data?.message}&tabs=timeline&small_header=false&width=320&height=530&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                                    title="Facebook Latest Posts"
                                    className="iframe-without-scroll"
                                    // width="350"
                                    height="530"
                                    // allowFullScreen={true}
                                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                    /> */}
                                    <iframe
                                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Freflectnewstamil&tabs=timeline&width=320&height=530&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                    width="320"
                                    height="415"
                                    className="iframe-without-scroll"
                                    style={{ overflow: 'hidden' }}
                                    scrolling="no"
                                    frameBorder="0"
                                    allowFullScreen="true"
                                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                    ></iframe>
                                </div>
                                </div>
                            )
                        }   
                    })
                }
        </>
    );
}

export default FacebookNews;


// <div className="d-flex justify-content-center" key={data.id}>
                                          {/* <FacebookEmbed url={'https://www.facebook.com/reflectnewstamil'} width={320} height={520} /> */}
                                          
                                            {/* <FacebookProvider appId="100134262415310" width={320} height={520}>
                                                <Page href="https://www.facebook.com/reflectnewstamil" tabs="timeline"/>
                                            </FacebookProvider> */}

                                          {/* <iframe  height={520} scrolling="no" src={`${data?.message}embed/`} class="iframe-without-scroll"></iframe> */}

                                            {/* <iframe  src="http://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2F587075654802606&width=600&colorscheme=light&show_faces=true&border_color&stream=true&header=true&height=435" scrolling="yes" allowtransparency="true" frameborder="0"></iframe> */}
                                             
                                            {/* <iframe
                                            // src=" const iframeUrl = `https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${pageId}&tabs=timeline&width=500&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`;"
                                            src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F${data?.message}&tabs=timeline&width=500&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`} 
                                            title="Facebook Latest Posts"
                                            class="iframe-without-scroll"
                                            height="530"
                                            style={{  overflow: 'hidden' }}
                                            scrolling="no"
                                            allowfullscreen="true"
                                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                                          /> */}

                                          

                                    // </div>