import React, { useState, useEffect } from "react";
import BreadcrumbNav from "./BreadcrumbNav";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import { getpagesApi } from "../store/actions/campaign";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";
import GoogleMapReact from 'google-map-react';
import {useThemeMode} from '../utils/themeMode';

const AnyReactComponent = ({ text }) => <div>{text}</div>;


function ContactUs() {
    const {mode, menuMode, updateMenuMode} = useThemeMode();
    const defaultProps = {
        center: {
          lat: 10.99835602,
          lng: 77.01502627
        },
        zoom: 11
      };

    const [loading, setLoading] = useState(true);
    const [Data, setData] = useState({
        page_content: "",
    });

    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        setLoading(true);
        getpagesApi(
            (response) => {
                let contactUs = response.data.filter((data) => data.title === "Contact Us");
                setData(contactUs[0]);
                setLoading(false);
            },
            (error) => {
                setData("");
                setLoading(false);
                console.error(error);
            }
        );
    }, [currentLanguage]);

    return (
        <>
            <BreadcrumbNav SecondElement="எங்களை தொடர்பு கொள்ள" ThirdElement="0" />
            <div id="main_contact_us">
                <div className="main-aboutus py-lg-5 py-3 my-3">
                    <div id="about-us" className="container">
                        <div id="about">
                            <h1 className={mode == 'light' ? "A my-4" : "A my-4 text-light"} > <strong>{translate("எங்களை தொடர்பு கொள்ள")}</strong></h1>
                            <div className="row">
                                <div className="col-lg-6 mb-4 mb-lg-0">
                                {loading ? <Skeleton count={5} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} /> : Data?.page_content ? <p className={mode == 'light' ? "" : "text-light"} dangerouslySetInnerHTML={{ __html: Data.page_content }} /> : <div className=""> <img src="/images/no_image.jpg" className="no_img my-5" alt="icon" /> </div>}
                                </div>
                                <div className="col-lg-6 ">
                                <div className="responsive-map">
                                <iframe  className="map-ifram w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.549372023174!2d80.1225068758275!3d13.06433071283692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5261c6337ac3af%3A0xffd242f21380d228!2sREFLECT%20NEWS%20PVT%20LTD!5e0!3m2!1sen!2sus!4v1688546651207!5m2!1sen!2sus" height="290" frameborder="0" style={{border:"0"}} allowfullscreen></iframe>
                                  </div>
                                        {/* <GoogleMapReact
                                          bootstrapURLKeys={{ key: "" }}
                                          defaultCenter={defaultProps.center}
                                          defaultZoom={defaultProps.zoom}
                                        >
                                          <AnyReactComponent
                                            lat={59.955413}
                                            lng={30.337844}
                                            text="My Marker"
                                          />
                                        </GoogleMapReact> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUs;
