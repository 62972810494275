import React, {createContext, useContext, useEffect, useState} from 'react'
// import {ThemeModeComponent} from '../../../assets/ts/layout'
// import {toAbsoluteUrl} from '../../../helpers'

// export type ThemeModeType = 'dark' | 'light' | 'system'
export const themeModelSKey = 'newsTheme'
export const themeMenuModeLSKey = 'newsFont'

const systemMode = 'light'

// type ThemeModeContextType = {
//   mode: ThemeModeType
//   menuMode: ThemeModeType
//   updateMode: (_mode: ThemeModeType) => void
//   updateMenuMode: (_mode: ThemeModeType) => void
// }

const themeModeSwitchHelper = (_mode) => {
  // change background image url
  const mode = _mode !== 'system' ? _mode : systemMode
  const imageUrl = '/media/patterns/header-bg' + (mode === 'light' ? '.jpg' : '-dark.png')
//   document.body.style.backgroundImage = `url("${toAbsoluteUrl(imageUrl)}")`
}

const getThemeModeFromLocalStorage = (lsKey) => {
  if (!localStorage) {
    return 'light'
  }

  const data = localStorage.getItem(lsKey)
  if (data === 'dark' || data === 'light' || data === 'system') {
    return data
  }

  if (document.documentElement.hasAttribute('data-bs-theme')) {
    const dataTheme = document.documentElement.getAttribute('data-bs-theme')
    if (dataTheme && (dataTheme === 'dark' || dataTheme === 'light' || dataTheme === 'system')) {
      return dataTheme
    }
  }

  return 'system'
}

const getModeFromLocalStorage = (lsKey) => {
  if (!localStorage) {
    return '14'
  }

  const data = localStorage.getItem(lsKey)
  if (data) {
    return data
  }
  return 14
}

const defaultThemeMode = {
  mode: getThemeModeFromLocalStorage(themeModelSKey),
  menuMode: getModeFromLocalStorage(themeMenuModeLSKey),
  updateMode: (_mode) => {},
  updateMenuMode: (_menuMode) => {},
}

const ThemeModeContext = createContext({
  mode: defaultThemeMode.mode,
  menuMode: defaultThemeMode.menuMode,
  updateMode: (_mode) => {},
  updateMenuMode: (_menuMode) => {},
})

const useThemeMode = () => useContext(ThemeModeContext)

const ThemeModeProvider = ({children}) => {
  const [mode, setMode] = useState(defaultThemeMode.mode)
  const [menuMode, setMenuMode] = useState(defaultThemeMode.menuMode)

  const updateMode = (_mode, saveInLocalStorage = true) => {
    setMode(_mode)
    // themeModeSwitchHelper(updatedMode)
    if (saveInLocalStorage && localStorage) {
      localStorage.setItem(themeModelSKey, _mode)
    }

    if (saveInLocalStorage) {
      const updatedMode = _mode === 'system' ? systemMode : _mode
      document.documentElement.setAttribute('data-bs-theme', updatedMode)
    }
    // ThemeModeComponent?.init()
  }

  const updateMenuMode = (_menuMode, saveInLocalStorage = true) => {
    setMenuMode(_menuMode)
    if (saveInLocalStorage && localStorage) {
      localStorage.setItem(themeMenuModeLSKey, _menuMode)
    }
  }

  useEffect(() => {
    updateMode(mode, false)
    updateMenuMode(menuMode, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeModeContext.Provider value={{mode, menuMode, updateMode, updateMenuMode}}>
      {children}
    </ThemeModeContext.Provider>
  )
}

export {ThemeModeProvider, useThemeMode, systemMode, themeModeSwitchHelper}
