import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getnewsbycategoryApi } from "../store/actions/campaign";
import { translate } from "../utils";
import Slider from "react-slick";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import no_image from "../images/no_image.jpg";
import { setActiveLink } from "../utils";

SwiperCore.use([Navigation, Pagination, Autoplay]);

function RelatedNewsSection(props) {
    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            console.log("after change", currentSlide);
        }
    };

    const [Data, setData] = useState([]);
    const catid = props.Cid;

    useEffect(() => {
        getnewsbycategoryApi(
            catid,
            "",
            "0",
            "10",
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [catid]);

    return (
        <div>
            {Data.length === 0 ? (
                ""
            ) : (

                <div>

                    {/* id="RNews-main" */}
                    <nav id="RNews-cat-nav" className="navbar">
                        <h4 id="nav-logo">
                            <b>{translate("தொடர்புடைய செய்திகள்...")}</b>
                        </h4>
                    </nav>

                    {/* <Slider {...settings}> */}
                    {/* <div className="scrol_listinf"> */}
                    {Data.length > 0 &&
                        Data?.slice(0, 4).map((element) => (
                            <div className="theme_card mb-3">
                                <Link onClick={() => { scrollToTop(); setActiveLink() }}
                                 id="Link-all"
                                 key={element?.id}
                                //  to={{
                                //    pathname: "/category-view",
                                //    search: `?id=${element?.category_id}&uid=1&name=${element?.category_name}`
                                //  }}
                                //  to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id} key={element.id}
                                 >
                                    {/* <div id="RNews-card" className="card"> */}
                                    <div className="row">
                                        <div className="col-4 pe-2">
                                            <img id="RNews-image" src={element.image ? element.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top" alt="..." />
                                        </div>
                                        {/* <div id="RNews-card-body" className="RNews-card-body"> */}
                                        <div className="col-8">
                                            
                                            <button id="btnRNewsCatagory" className="btn btn-sm" type="button">
                                                {element.category_name}
                                            </button>
                                         <a href={element.slug}></a>
                                            <h6 id="RNews-card-text" className="card-text two_line">
                                                {element.title}
                                            </h6>
                                        </div>
                                        {/* <>
                                          {index<=3 ?
                                             <div id='RNews-card' className="card">
                                                <img  id='RNews-image' src={element.image} className="card-img-top" alt="..."/>
                                                  <div className="RNews-card-body">
                                                     <button id='btnRNewsCatagory' className='btn btn-sm' type="button" >{element.category_name}</button>
                                                     <h6 id='RNews-card-text' className="card-text">{element.title.slice(0,40)}...</h6>
                                                  </div>
                                              </div>
                                          :null}

                                          </> */}
                                    </div>
                                </Link></div>
                        ))}
                    {/* </div> */}
                    {/* </Slider> */}

                </div>
            )}
        </div>
    );
}

export default RelatedNewsSection;
