import photo from "../images/Login.jpg";
import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { translate } from "../utils";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { webSettingsData } from "../store/reducers/websettingsReducer";
import no_image from "../images/no_image.jpg";
import {useThemeMode} from '../utils/themeMode'

function ForgotPasswordTwo(props) {
    const {mode} = useThemeMode();
    const initialValues = { email: "", password: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState(
        "",
        setTimeout(() => {
            if (formErrors !== "") setFormErrors("");
        }, 5000)
    );
    const [isSubmit, setIsSubmit] = useState(false);

    const websettings = useSelector(webSettingsData);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        const auth = getAuth();
        await sendPasswordResetEmail(auth, formValues.email)
            .then((userCredential) => {
                toast.success("மின்னஞ்சல் வெற்றிகரமாக அனுப்பப்பட்டது")
                // ...
                props.onHide();
                props.setLoginModalShow(true);
            })
            .catch((error) => {
                console.error(error);
                // ..
            });
    };
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit); // eslint-disable-next-line
    }, [formErrors]); // eslint-disable-next-line
    const validate = (values) => {
        const errors = {};
        const regex = /^[^\s@]+@[^s]+\.[^\s@]{2,}$/i;
        if (!values.email) {
            errors.email = "மின்னஞ்சல் தேவை!";
        } else if (!regex.test(values.email)) {
            errors.email = "சரியான மின்னஞ்சலை உள்ளிடவும்";
        }

        return errors;
    };
    return (
        <div >
            <Modal {...props} size="xl" aria-labelledby="contained-modal-title-vcenter" centered dialogClassName="border-radius-2">
                <div className="ModalWrapper" id="ModalWrapper11">
                    <div style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "20px" }} id="login_img2">
                        <img className="ModalImg" id="ModalImg2" src={photo} alt="" />
                      
                        {/* <div className="logo-text2">
                            <h4> {translate("beautifulltheme")}</h4>
                            <p> {translate("bestinvestment")}</p>
                        </div> */}
                    </div>

                    <div className={mode == 'light' ? "forgot_paword_popup" : "forgot_paword_popup bg-dark"} id="modal-content2">
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter"> </Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ marginTop: "12px" }}>

                        <div className="d-flex justify-content-center">
                            <img id="NewsLogo" src={websettings && websettings.web_footer_logo ? websettings && websettings.web_footer_logo : no_image} onError={(e) => { e.target.src = no_image;}} alt="" />
                        </div>

                        <h1 className={mode == 'light' ? "text-center my-3 fw-bold text-dark" : "text-center my-3 fw-bold text-light"}>{translate("கடவுச்சொல்லை மறந்துவிட்டீர்களா")}</h1>

                            <div className="groupformcontrol">
                                <div className="AC mt-lg-5 mt-3 pt-xl-5">
                                    <div className="welcom-back2">
                                        <h5>
                                            <strong>{translate("மின்னஞ்சல் முகவரி")} </strong>
                                        </h5>
                                        {/* <div className="mb-3" id="Welcom" style={{ fontSize: "14px" }}>
                                        {translate("Createnewpassword")}
                                        </div> */}
                                    </div>
                                    <form className="my-2 " onSubmit={handleSubmit}>
                                        <div className="mb-3">


                                        <div class="input-group mb-3">
                                                  <div class="input-group-prepend">
                                                    <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope" aria-hidden="true"></i></span>
                                                  </div>
                                                  <input
                                                type="text"
                                                className="form-control email-input"
                                                name="email"
                                                id="exampleInputEmail1"
                                                aria-describedby="emailHelp"
                                                placeholder="உங்கள் மின்னஞ்சல் முகவரியை உள்ளிடவும்"
                                                value={formValues.email}
                                                onChange={handleChange}
                                            />
                                                </div>
                                            
                                            <p className="error-msg"> {formErrors.email}</p>
                                        </div>

                                        <div className="py-3 d-flex justify-content-center">
                                            <button type="submit" className="btn btn-lg " id="submitbutton">
                                                {translate("சமர்ப்பிக்கவும்")}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ForgotPasswordTwo;
