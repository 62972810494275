import React, { useState, useEffect } from "react";
import { FaFacebookSquare, FaInstagram, FaLinkedin, FaTwitterSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { categoriesApi } from "../store/actions/campaign";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsCondition from "./TermsCondition";
import { activateHomeLink, setActiveLink, translate } from "../utils";
import { webSettingsData } from "../store/reducers/websettingsReducer";
import BackToTop from 'react-custom-back-to-top-button';
import { getAddUrl, footersnews ,footerwomens ,footersports,footercinema ,footercategory} from "../utils/api";
import no_image from "../images/no_image.jpg";
import { useLocation } from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel
} from 'react-accessible-accordion'
function Footer() {
    const location = useLocation();
    const [modal1Open, setModal1Open] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [Data, setData] = useState([]);

    const currentLanguage = useSelector(selectCurrentLanguage);

    const websettings = useSelector(webSettingsData);

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    useEffect(() => {
        categoriesApi(
            "0",
            "9",
            (response) => {
                const responseData = response.data;
                setData(responseData);
            },
            (error) => {
                console.error(error);
            }
        );
    }, [currentLanguage]);




    const [adddata, setadddata] = useState([]);

    const fetchadd = async () => {
        const response = await getAddUrl();
        setadddata(response.data?.data)
    }

    useEffect(() => {
        fetchadd();
    }, [currentLanguage])



const[newsfooter , setnewsfooter] = useState([])
const[womensfooter , setfooterwomens] = useState([])
const[sportsfooter , setfootersport] = useState([])
const[cinemafooter, setfootercinema] = useState([])
const[categoryfooter, setfootercategory] = useState([])


  const fetchsportsnews = async () =>{
    const response = await footersnews();
    if(response.data) {
    setnewsfooter(response.data?.data);
    }
  }

  const fetchfooterwomens = async () =>{
    const response = await footerwomens();
    if(response.data) {
        setfooterwomens(response.data?.data);
    }
  }

  const fetchfootersport = async () =>{
    const response = await footersports();
    if(response.data) {
    setfootersport(response.data?.data);
    }
  }


  const fetchfootercinema = async () =>{
    const response = await footercinema();
    if(response.data) {
    setfootercinema(response.data?.data);
    }
  }
  

  const fetchcategory = async () =>{
    const response = await footercategory();
    if(response.data) {
    setfootercategory(response.data?.data);
    }
  }
  

  useEffect(()=>{
    fetchsportsnews();
    fetchfooterwomens();
    fetchfootersport();
    fetchfootercinema();
    fetchcategory();
  },[])

    return (
        <>
        
            <section id="footer" className="pt-0">
                <div className="pt-2">


                    <div className="container">

                        <div className="d-none">
                            {/* <img src="/images/36.png" className="footer_add mb-3" alt="icon" /> */}
                            {adddata?.map((item) => {
                                    if (item.ad_space === "home_page-4") {
                                        return (
                                            <img src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} className="w-100 mb-3" alt="icon" />
                                        );
                                    }

                                })}

                            <Accordion className="text-white accordian_footer" allowZeroExpanded={true}>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton> செய்திகள் </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    <ul>
                                {newsfooter?.map((item) => {
                                   return(
                                    <Link id="catNav-links" key={item.category_id} onClick={() => {scrollToTop(); setActiveLink()}}  to={{
                                        pathname: "/category-view",
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link>
                                   )
                                })}  
                                </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>மகளிர்</AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    <ul>
                                {womensfooter?.map((item) => {
                                   return(
                                    <Link id="catNav-links" onClick={() => {scrollToTop(); setActiveLink()}}  key={item.category_id} to={{
                                        pathname: "/category-view",
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link>
                                   )
                                })}  
                                </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>விளையாட்டு</AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    <ul>
                                {sportsfooter?.map((item) => {
                                   return(
                                    <Link id="catNav-links" onClick={() => {scrollToTop(); setActiveLink()}}  key={item.category_id} to={{
                                        pathname: "/category-view",
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link> 
                                   )
                                })}  
                                </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>

                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>சினிமா</AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    <ul>
                                {cinemafooter?.map((item) => {
                                   return(
                                    <Link id="catNav-links" onClick={() => {scrollToTop(); setActiveLink()}}  key={item.category_id} to={{
                                        pathname: "/category-view",
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link>
                                   )
                                })}  
                                </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>


                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>மற்றவை</AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                    <ul>
                                {categoryfooter?.map((item) => {
                                   return(
                                    <Link id="catNav-links" onClick={() => {scrollToTop(); setActiveLink()}}  key={item.category_id} to={{
                                        pathname: "/category-view",
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link>
                                   )
                                })}  
                                </ul>
                                    </AccordionItemPanel>
                                </AccordionItem>

                            </Accordion>
                        </div>


                        {/* <div className="row footer_row d-md-flex d-none mb-3 d-none">
                            <div className="col-xl-2 col-md-3 mb-3">

                                {adddata?.map((item) => {
                                    if (item.ad_space === "home_page-4") {
                                        return (
                                            <a target="black" href={item.ad_url}> <img className="footer_add" src={item.ad_image ? item.ad_image : no_image} onError={(e) => { e.target.src = no_image;}} alt="icon" /> </a>
                                        );
                                    }

                                })}


                            </div>

                            <div className="col-xl-2 col-md-3 mb-3">
                                <h5>செய்திகள்</h5>
                                <ul>
                                {newsfooter?.map((item) => {
                                   return(

                                    <Link id="catNav-links" key={item.category_id} onClick={() => {scrollToTop(); setActiveLink()}} to={{
                                        pathname: "/category-view",
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=செய்திகள்&sname=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link>
                                    
                                   )
                                })}  
                                </ul>
                            </div>


                            <div className="col-xl-2 col-md-3 mb-3">
                                <h5>மகளிர்</h5>
                                <ul>
                                {womensfooter?.map((item) => {
                                   return(
                                    <Link id="catNav-links" key={item.category_id} onClick={() => {scrollToTop(); setActiveLink()}}  to={{
                                        pathname: "/category-view",
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=மகளிர்&sname=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link>
                                   )
                                })}  
                                </ul>
                              
                            </div>


                            <div className="col-xl-2 col-md-3 mb-3">
                                <h5>விளையாட்டு</h5>
                                <ul>
                                {sportsfooter?.map((item) => {
                                   return(
                                    <Link id="catNav-links" key={item.category_id} onClick={() => {scrollToTop(); setActiveLink()}}  to={{
                                        pathname: "/category-view",
                                        // search: `?id=${element?.id}&subid=${subcategory.id}&uid=1&name=${subcategory.subcategory_name}`,
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=விளையாட்டு&sname=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link>
                                   )
                                })}  
                                </ul>
                            
                            </div>

                            <div className="col-xl-2 col-md-3 d-block d-xl-none mb-3">
                            </div>

                            <div className="col-xl-2 col-md-3 mb-3">
                                <h5>சினிமா</h5>
                                <ul>
                                {cinemafooter?.map((item) => {
                                   return(
                                    <Link id="catNav-links" key={item.category_id} onClick={() => {scrollToTop(); setActiveLink()}}  to={{
                                        pathname: "/category-view",
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=சினிமா&sname=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link>
                                   )
                                })}  
                                </ul>
                              
                            </div>


                            <div className="col-xl-2 col-md-3 mb-3">
                                <h5>மற்றவைகள்</h5>
                                <ul>
                                {categoryfooter?.map((item) => {
                                   return(
                                    <Link id="catNav-links" key={item.category_id} onClick={() => {scrollToTop(); setActiveLink()}}  to={{
                                        pathname: "/category-view",
                                        search: `?id=${item.category_id}&subid=${item.id}&uid=1&name=மற்றவைகள்&sname=${item.subcategory_name}`
                                    }}>   
                                     <li>{item.subcategory_name}</li>
                                    </Link>
                                   )
                                })}  
                                </ul>
                            </div>

                        </div> */}
                        <div className="row resd d-none">
                            <div className="col-md-2 col-12">

                                {/* <div className="Lorem-text">
                                <p className="lorem">
                                    {websettings && websettings.web_footer_description}
                                    <br />
                                </p>
                            </div> */}
                            </div>
                            <div className="col-md-3 col-12">
                                {/* <p id="footer-nav">{translate("newscategories")}</p> */}
                                {Data && Data.length > 0 ? (
                                    <ul className="newscate">
                                        {Data?.map((value, index) => {
                                            return (
                                                <li key={index}>
                                                    <Link to={"/category-view?id=" + value.id} onClick={() => {scrollToTop(); setActiveLink()}} >
                                                        <i className="fa fa-circle" aria-hidden="true"></i>  {value.category_name}{" "}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : null}
                            </div>

                            {/* <div className="col-md-3 col-12">
                            <p id="footer-nav">{translate("usefulllinks")}</p>
                            <ul className="useL">
                                <li className="nav-item">
                                    <Link to="/"  onClick={() => {scrollToTop(); setActiveLink()}}>
                                    <i className="fa fa-circle" aria-hidden="true"></i>   {translate("home")}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/about-us"  onClick={() => {scrollToTop(); setActiveLink()}}>
                                    <i className="fa fa-circle" aria-hidden="true"></i>    {translate("aboutus")}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/live-news" onClick={() => {scrollToTop(); setActiveLink()}}>
                                    <i className="fa fa-circle" aria-hidden="true"></i>   {translate("livenews")}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/breaking-news-view"  onClick={() => {scrollToTop(); setActiveLink()}}>
                                    <i className="fa fa-circle" aria-hidden="true"></i>   {translate("Breaking News")}
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact-us"  onClick={() => {scrollToTop(); setActiveLink()}}>
                                    <i className="fa fa-circle" aria-hidden="true"></i>   {translate("contactus")}
                                    </Link>
                                </li>
                            </ul>
                        </div> */}

                            {/* <div className="col-md-3 col-12">
                            <p id="footer-nav">{translate("followus")} </p>
                            <div className="social_media">
                                {process.env.REACT_APP_FACEBOOK ? (
                                    <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href={process.env.REACT_APP_FACEBOOK} rel="noreferrer">
                                        <FaFacebookSquare />  {translate("facebook")}
                                    </Link>
                                ) : null}
                                {process.env.REACT_APP_INSTAGRAM ? (
                                    <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href={process.env.REACT_APP_INSTAGRAM} rel="noreferrer">
                                        <FaInstagram />  {translate("instagram")}
                                    </Link>
                                ) : null}
                                {process.env.REACT_APP_LINKEDIN ? (
                                    <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href={process.env.REACT_APP_LINKEDIN} rel="noreferrer">
                                        <FaLinkedin /> {translate("linkedin")}
                                    </Link>
                                ) : null}
                                {process.env.REACT_APP_TWITTER ? (
                                    <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href={process.env.REACT_APP_TWITTER} rel="noreferrer">
                                        <FaTwitterSquare /> {translate("twitter")}
                                    </Link>
                                ) : null}
                            </div>
                        </div> */}
                        </div>

                        {/* <hr className="hr_line mt-1" /> */}
                        <div className="text-white row align-items-center my-4">
                            <div className="col-lg-2 col-6 foot1">
                                <div className="News" onClick={activateHomeLink}>
                                    <Link to="/">
                                        <img id="NewsLogo" src={websettings && websettings.web_footer_logo ? websettings && websettings.web_footer_logo : no_image} onError={(e) => { e.target.src = no_image;}} alt="" />
                                    </Link>
                                </div>
                            </div>
                            <div className="d-flex align-items-center col-lg-8 justify-content-center terms_privacy foot2 d-none d-lg-block">
                                <div className="row">
                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p className="h6 p-2"><Link className="d-flex align-items-center" to="/about-us" onClick={() => { scrollToTop(); setActiveLink() }}><span className="footer-star">*</span><span className="one_line">{translate("எங்களைப்பற்றி")} </span></Link></p>
                                    </div>

                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p id="footer-pri-pol" className="h6 p-2"> <Link className="d-flex align-items-center" to="/privacy-policy" onClick={() => { scrollToTop(); setActiveLink() }}>{" "} <span className="footer-star">*</span><span className="one_line">{translate("தனித்தன்மை பாதுகாப்பு")}{" "}</span> </Link></p>
                                    </div>
                                    {/* <div className="col-xl-auto col-lg-4 col-6 px-2">
                              <p className="h6 p-2"><Link to="/live-news" onClick={() => {scrollToTop(); setActiveLink()}}> {translate("livenews")}</Link></p> 
                           </div>
                           <div className="col-xl-auto col-lg-4 col-6 px-2">
                               <p className="h6 p-2"><Link to="/breaking-news-view"  onClick={() => {scrollToTop(); setActiveLink()}}> {translate("Breaking News")}</Link></p> 
                           </div> */}
                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p className="h6 p-2"><Link className="d-flex align-items-center" to="/complaints" onClick={() => { scrollToTop(); setActiveLink() }}> <span className="footer-star">*</span><span className="one_line">{translate("புகார்கள்")}</span> </Link></p>
                                    </div>
                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p id="footer-tearms" className="h6 p-2" > <Link className="d-flex align-items-center" to="/terms-condition" onClick={() => { scrollToTop(); setActiveLink() }}> <span className="footer-star">*</span><span className="one_line">{translate("விதிமுறைகள் மற்றும் நிபந்தனைகளின்")}</span>  </Link></p>
                                    </div>
                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p className="h6 p-2"><Link className="d-flex align-items-center" to="/contact-us" onClick={() => { scrollToTop(); setActiveLink() }}> <span className="footer-star">*</span> <span className="one_line">{translate("தொடர்புகொள்ள")}</span></Link></p>
                                    </div>

                                    {/* <div className="col-xl-auto col-lg-4 col-6 px-2">
                              <p id="footer-pri-pol" className="h6 p-2" variant="primary" onClick={() => setModal2Open(true)}> {" "} {translate("Privacy Policy")}{" "} </p>
                           </div> */}


                                </div>
                            </div>
                            <div className="col-lg-2 col-6 foot3 d-flex align-items-center justify-content-end">
                                <div className="social_media social_media_link d-flex flex-row">
                                    {process.env.REACT_APP_FACEBOOK ? (
                                        <a target="_blank" id="social_platforms" className="btn btn-outline-white text-start footer_social_media" href={process.env.REACT_APP_FACEBOOK} rel="noreferrer">
                                          
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </a>
                                    ) : null}
                                    {process.env.REACT_APP_INSTAGRAM ? (
                                        <a target="_blank" id="social_platforms" className="btn btn-outline-white text-start footer_social_media" href={process.env.REACT_APP_INSTAGRAM} rel="noreferrer">
                                           
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </a>
                                    ) : null}
                                    {process.env.REACT_APP_LINKEDIN ? (
                                        <a target="_blank" id="social_platforms" className="btn btn-outline-white text-start footer_social_media" href={process.env.REACT_APP_LINKEDIN} rel="noreferrer">
                                           
                                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                                        </a>
                                    ) : null}
                                    {process.env.REACT_APP_TWITTER ? (
                                        <a target="_blank" id="social_platforms" className="btn btn-outline-white text-start footer_social_media" href={process.env.REACT_APP_TWITTER} rel="noreferrer">
                                          
                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                        </a>
                                    ) : null}



                                        {/* <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href="https://www.facebook.com/reflectnewstamil/" rel="noreferrer">
                                        
                                            <i className="fa fa-facebook" aria-hidden="true"></i>
                                        </Link>
                             
                                        <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href="https://www.instagram.com/p/CiZjIryBwtk/" rel="noreferrer">
                                          
                                            <i className="fa fa-instagram" aria-hidden="true"></i>
                                        </Link>
                                  
                                        <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href={process.env.REACT_APP_LINKEDIN} rel="noreferrer">
                                          
                                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                                        </Link>
                                 
                                        <Link target="_blank" id="social_platforms" className="btn btn-outline-white text-start" href="https://twitter.com/reflectnewstn" rel="noreferrer">
                                            
                                            <i className="fa fa-twitter" aria-hidden="true"></i>
                                        </Link> */}
                                   

                                </div>
                            </div>
                            <div className="d-flex align-items-center col-lg-8 justify-content-center terms_privacy foot2 d-block d-lg-none">
                                <div className="row">
                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p className="h6 p-2"><Link className="d-flex align-items-center" to="/about-us" onClick={() => { scrollToTop(); setActiveLink() }}><span className="footer-star">*</span><span className="one_line">{translate("எங்களைப்பற்றி")} </span></Link></p>
                                    </div>

                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p id="footer-pri-pol" className="h6 p-2"> <Link className="d-flex align-items-center" to="/privacy-policy" onClick={() => { scrollToTop(); setActiveLink() }}>{" "} <span className="footer-star">*</span><span className="one_line">{translate("தனித்தன்மை பாதுகாப்பு")}{" "}</span> </Link></p>
                                    </div>
                                    {/* <div className="col-xl-auto col-lg-4 col-6 px-2">
                              <p className="h6 p-2"><Link to="/live-news" onClick={() => {scrollToTop(); setActiveLink()}}> {translate("livenews")}</Link></p> 
                           </div>
                           <div className="col-xl-auto col-lg-4 col-6 px-2">
                               <p className="h6 p-2"><Link to="/breaking-news-view"  onClick={() => {scrollToTop(); setActiveLink()}}> {translate("Breaking News")}</Link></p> 
                           </div> */}
                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p className="h6 p-2"><Link className="d-flex align-items-center" to="/complaints" onClick={() => { scrollToTop(); setActiveLink() }}> <span className="footer-star">*</span><span className="one_line">{translate("புகார்கள்")}</span> </Link></p>
                                    </div>
                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p id="footer-tearms" className="h6 p-2" > <Link className="d-flex align-items-center" to="/terms-condition" onClick={() => { scrollToTop(); setActiveLink() }}> <span className="footer-star">*</span><span className="one_line">{translate("விதிமுறைகள் மற்றும் நிபந்தனைகளின்")}</span>  </Link></p>
                                    </div>
                                    <div className="col-xl-custome col-md-4 col-sm-6 px-2 px-xl-0">
                                        <p className="h6 p-2"><Link className="d-flex align-items-center" to="/contact-us" onClick={() => { scrollToTop(); setActiveLink() }}> <span className="footer-star">*</span> <span className="one_line">{translate("தொடர்புகொள்ள")}</span></Link></p>
                                    </div>

                                    {/* <div className="col-xl-auto col-lg-4 col-6 px-2">
                              <p id="footer-pri-pol" className="h6 p-2" variant="primary" onClick={() => setModal2Open(true)}> {" "} {translate("Privacy Policy")}{" "} </p>
                           </div> */}


                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="bg-copyright">
                        <div className="container">
                            <div className=" copyright text-center" id="copyright1">


                                <p id="footer-Copyright" className="h6 px-2 py-3">
                                    © {moment().year()} 
                                    {/* {translate("Reflect தமிழ்,")}  {translate("அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை")} */}
                                    {/* {websettings && websettings.web_name} */}
                                    <span className="ps-1">
                                        {websettings && websettings.web_footer_description}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <BackToTop showAt={100} speed={1500} easing="easeInOutQuint">
                </BackToTop>


            </section>

            {/* <TermsCondition modal1Open={modal1Open} setModal1Open={setModal1Open} /> */}
            {/* <PrivacyPolicy modal2Open={modal2Open} setModal2Open={setModal2Open} /> */}
        </>
    );
}

export default Footer;
