import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { getnewsbycategoryApi, getyoutubelinkApi } from "../store/actions/campaign";
import { translate } from "../utils";
import { FiCalendar, FiClock, FiYoutube } from "react-icons/fi";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import { BsFillPlayFill } from "react-icons/bs";
import Card from "react-bootstrap/Card";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { useSelector } from "react-redux";
import VideoPlayerModal from "./VideoPlayerModal";
import { getyoutube, sportsnews } from "../utils/api";
import no_image from "../images/no_image.jpg";
import YouTube from 'react-youtube-embed';
import {useThemeMode} from '../utils/themeMode'

SwiperCore.use([Navigation, Pagination]);

function HealthNewsSection() {
    const {mode} = useThemeMode();
    const [Data, setData] = useState([]);
    const [Video_url, setVideo_url] = useState();
    const [modalShow, setModalShow] = React.useState(false);
    const [slideStop, setSlideStop] = React.useState(0);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [Datas, setDatas] = useState([]);


    useEffect(() => {
        getyoutubelinkApi(
            "5",
            "",
            "0",
            "10",
            (response) => {
                setDatas(response.data);
            },
            (error) => {
                console.error(error);
            }
        );
    }, []);

    useEffect(() => {
        getnewsbycategoryApi(
            "5",
            "",
            "0",
            "10",
            (response) => {
                // setData(response.data);
            },
            (error) => {
                console.error(error);
            }
        );
    }, []);

    function handleVideoUrl(url) {
        setModalShow(true);
        setVideo_url(url);
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0 });
    };

    const showNavigation = Data.length > 1;

    const swiperOption = {
        speed: 750,
        spaceBetween: 10,
        slidesPerView: 2,
        // navigation: showNavigation

        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            567: {
                slidesPerView: 1,
            },
            990: {
                slidesPerView: 1,
            },
            1200: {
                slidesPerView: 1,
            },
            1399: {
                slidesPerView: 1,
            },
            1400: {
                slidesPerView: 1,
            },
        },
        autoplay: true,
        beforeChange: function (currentSlide, nextSlide) {
        },
        afterChange: function (currentSlide) {
        }
    };


    const [newstoday, setnewstoday] = useState([]);

    const fetchnewstoday = async () => {
        const response = await sportsnews();
        if(response.data) {            
            setnewstoday(response.data?.data)
        }
    }

    useEffect(() => {
        fetchnewstoday();
    }, [currentLanguage])


    // fade in

    const scrollElements = document.querySelectorAll(".js-scroll");

    const elementInView = (el, dividend = 1) => {
        const elementTop = el.getBoundingClientRect().top;

        return (
            elementTop <=
            (window.innerHeight || document.documentElement.clientHeight) / dividend
        );
    };

    const elementOutofView = (el) => {
        const elementTop = el.getBoundingClientRect().top;

        return (
            elementTop > (window.innerHeight || document.documentElement.clientHeight)
        );
    };

    const displayScrollElement = (element) => {
        element.classList.add("scrolled");
    };

    const hideScrollElement = (element) => {
        element.classList.remove("scrolled");
    };

    const handleScrollAnimation = () => {
        scrollElements.forEach((el) => {
            if (elementInView(el, 1.25)) {
                displayScrollElement(el);
            } else if (elementOutofView(el)) {
                hideScrollElement(el)
            }
        })
    }

    window.addEventListener("scroll", () => {
        handleScrollAnimation();
    });


    const swiperRef = useRef();
    const [adddata, setadddata] = useState([]);
    const fetchadd = async () => {
        const response = await getyoutube();
        if(response.data?.data) {            
            setadddata(response.data?.data)
        }
    }
    useEffect(() => {
        fetchadd();
    }, [currentLanguage])

    const erhwerufweryfguy = (event) => {
        setSlideStop(1);
    }

    return (
        <>
            <div className="">

                <div className="container mt-3 mt-xl-4 d-none">
                    <div id="hns-head" className="row">
                        <div id="hns-head-main">
                            <div className="d-flex align-items-center"> <div className="border_strong_head me-2" ></div> <p className="mb-0" id="hns-main-logo">{translate("Health News")}</p> </div>
                            <div className="d-flex justify-content-end">
                                <Link href="/" id="hns-Viewmore" to={"/category-view?id=5&uid=1&pos=sixth-section"} onClick={() => scrollToTop()}>
                                    {translate("மேலும் வாசிக்க")}
                                    <img src="/images/8.png" className="arrow_icon" alt="icon" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div id="hns-main" className="row ">
                        <div id="hns-main-card" className="card col-lg-12 col-xl-4 col-12 mb-4 ">
                            <div className="card_theme" >
                                <Link id="Link-all"
                                    to={"/news-view?slug=" + Data[0]?.slug + "&c=" + Data[0]?.category_name + "&sc=" + Data[0]?.subcategory_name + "&pos=sixth-section"}
                                //  to={"/news-view?Nid=" + Data[0]?.id + "&Cid=" + Data[0]?.category_id}
                                    >
                                    <img id="hns-main-image" src={Data[0]?.image} className="card-img" alt={Data[0]?.image_data.other_image} />
                                    {/* <div id="hns-main-body" className="card-img-overlay"> */}
                                    <div className="">
                                        {/* <Link id="btnhnsCatagory" className="btn" type="button" to={`/category-view?id=${Data[0]?.category_id}`}>
                                        {Data[0]?.category_name}
                                    </Link> */}
                                        {/* <p id="hns-main-text" className="card-text"></p> */}
                                        <h6 className="mb-0 mt-3 two_line custome_titile"> {Data[0]?.title} </h6>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-8 mb-2 ">
                            <div className="card_theme">
                                <div className="row">
                                    <div id="hns-center-col" className="col-md-6  mb-md-0 mb-4">
                                        {Data.length > 0 && Data?.slice(0, 4).map((value, index) => {
                                            return (
                                                <Link key={index} id="Link-all"
                                                    to={"/news-view?slug=" + value.slug + "&c=" + value.category_name + "&sc=" + value.subcategory_name+ "&pos=sixth-section"}
                                                //  to={"/news-view?Nid=" + value.id + "&Cid=" + value.category_id}
                                                    className="mb-3">
                                                    <div id="hns-card" className="card">
                                                        <img id="hns-card-image" src={value.image} className="card-img-top" alt={value.image_data.other_image} />
                                                        <div className="hns-card-body">
                                                            {/* <Link id="btnhnsCatagorysub" className="btn btn-sm" type="button" to={`/category-view?id=${value.category_id}`}>
                                                                {value.category_name}
                                                            </Link> */}
                                                            <p id="hns-card-text" className="card-text two_line text-dark">
                                                                {value.title}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </div>
                                    <div id="hns-right-col" className="col-md-6">
                                        {Data.length > 4 && Data?.slice(4, 8).map((value, index) => {
                                            return (
                                                <div key={index} className="mb-3">
                                                    <Link id="Link-all"
                                                        to={"/news-view?slug=" + value.slug + "&c=" + value.category_name + "&sc=" + value.subcategory_name+ "&pos=sixth-section"}
                                                    //  to={"/news-view?Nid=" + value.id + "&Cid=" + value.category_id}
                                                        className="mb-3">
                                                        <div id="hns-card" className="card">
                                                            <img id="hns-card-image" src={value.image} className="card-img-top" alt={value.image_data.other_image} />
                                                            <div className="hns-card-body">
                                                                {/* <Link id="btnhnsCatagorysub" className="btn btn-sm" type="button" to={`/category-view?id=${value.category_id}`}>
                                                    {value.category_name}
                                                </Link> */}
                                                                <p id="hns-card-text" className="card-text two_line text-dark">
                                                                    {value.title}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            );
                                        })}


                                    {/* <Link id='Link-all' to={"/news-view?Nid=" + Data[5].id + "&Cid=" + Data[5].category_id}>

                                            <div id='hns-card' className="card">
                                                <img id='hns-card-image' src={Data[5].image} className="card-img-top" alt={Data[5].image_data.other_image}/>
                                                <div className="hns-card-body">
                                                    <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[5].category_id}`}>{Data[5].category_name}</Link>
                                                    <p id='hns-card-text' className="card-text">{Data[5].title.slice(0, 60)}...</p>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link id='Link-all' to={"/news-view?Nid=" + Data[6].id + "&Cid=" + Data[6].category_id}>

                                            <div id='hns-card' className="card">
                                                <img id='hns-card-image' src={Data[6].image} className="card-img-top" alt={Data[6].image_data.other_image}/>
                                                <div className="hns-card-body">
                                                    <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[6].category_id}`}>{Data[6].category_name}</Link>
                                                    <p id='hns-card-text' className="card-text">{Data[6].title.slice(0, 60)}...</p>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link id='Link-all' to={"/news-view?Nid=" + Data[7].id + "&Cid=" + Data[7].category_id}>

                                            <div id='hns-card' className="card">
                                                <img id='hns-card-image' src={Data[7].image} className="card-img-top" alt={Data[7].image_data.other_image}/>
                                                <div className="hns-card-body">
                                                    <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[7].category_id}`}>{Data[7].category_name}</Link>
                                                    <p id='hns-card-text' className="card-text">{Data[7].title.slice(0, 60)}...</p>
                                                </div>
                                            </div>
                                        </Link>
                                        <Link id='Link-all' to={"/news-view?Nid=" + Data[8].id + "&Cid=" + Data[8].category_id}>
                                            <div id='hns-card' className="card">
                                                <img id='hns-card-image' src={Data[8].image} className="card-img-top" alt={Data[8].image_data.other_image}/>
                                                <div className="hns-card-body">
                                                    <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[8].category_id}`}>{Data[8].category_name}</Link>
                                                    <p id='hns-card-text' className="card-text">{Data[8].title.slice(0, 60)}...</p>
                                                </div>
                                            </div>
                                        </Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="hns-main-mobile"></div>
                </div>

                <div className="container mt-3 mt-xl-5">
                    <div id="hns-main" className="row ">
                        <div id="hns-main-card" className="card col-lg-4 col-12 mb-2  medicalhdo" >
                            <div className="d-flex align-items-center mb-3"> <div className="border_strong_head me-2" ></div> <h4 className="mb-0" id="hns-main-logo">{translate("நேர்காணல்")}</h4> </div>
                            {adddata?.length > 0 ?
                            <div className="card_theme mt-1 h-100 mb-3 mb-lg-0" >
                                <Swiper modules={[Navigation]}
                                    onBeforeInit={(swiper) => {
                                        swiperRef.current = swiper;
                                    }} {...swiperOption} className="h-100">

                                    {adddata.length > 0 ? adddata?.map((value, index) => {
                                        return (
                                            <SwiperSlide className="">
                                                <div className="">
                                                    {/* <Card id="vps-image-cards" className="text-black third_video mb-3">
                                                        <Card.Img id="hns-main-image" className="" src={value.thumbnail ? value.thumbnail : no_image} onError={(e) => { e.target.src = no_image;}} alt="Card image" />
                                                        <Card.ImgOverlay className="p-0">
                                                            <div className="d-flex align-items-center justify-content-center position_crd_vedio">
                                                                <Link href={value.link} id="vps-btnVideo" onClick={() => handleVideoUrl(value.link)}>
                                                                    <FiYoutube id="vps-btnVideo-logo" fill="white" size={60} />
                                                                </Link>
                                                            </div>
                                                        </Card.ImgOverlay>
                                                    </Card> */}
                                                    <YouTube id={value.link} height={530} width={350} />
                                                    {/* <div className="video-responsive">
                                                        <iframe
                                                        width="350"
                                                        height="530"
                                                        src={`https://www.youtube.com/embed/${value.link}`}
                                                        frameBorder="0"
                                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                        allowFullScreen
                                                        title="Embedded youtube"
                                                        />
                                                    </div> */}
                                                    <div className="d-none d-xxl-block">
                                                        <h5 id="hns-card-text" className={mode == 'light' ? "card-text three_line text-dark mt-2" : "card-text three_line text-light mt-2"}>{value.name}</h5>
                                                    </div>
                                                    <div className="d-none d-xl-block d-xxl-none">
                                                        <h5 id="hns-card-text" className={mode == 'light' ? "card-text five_line text-dark mt-2" : "card-text five_line text-light mt-2"}>{value.name}</h5>
                                                    </div>
                                                    <div className="d-none d-md-block d-xl-none">
                                                        <h5 id="hns-card-text" className={mode == 'light' ? "card-text seven_line text-dark mt-2" : "card-text seven_line text-light mt-2"}>{value.name}</h5>
                                                    </div>
                                                    <div className="d--block d-md-none">
                                                        <h5 id="hns-card-text" className={mode == 'light' ? "card-text five_line text-dark mt-2" : "card-text five_line text-light mt-2"}>{value.name}</h5>
                                                    </div>
                                                    <p id="cv-card-date" className="w-100 mb-0">
                                                        <FiClock size={18} id="cv-logoClock" className="me-2" />
                                                        {value.created_at}
                                                    </p>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    }) : <div className="col-12 text-center my-9">
                                            <p>வேறு தகவல்கள் இல்லை</p>
                                        </div>}

                                </Swiper>


                                <VideoPlayerModal
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    // backdrop="static"
                                    keyboard={false}
                                    url={Video_url}
                                // title={Data[0].title}
                                />
                                <div className="nav_position">
                                    <button className="nav-review" onClick={() => swiperRef.current?.slidePrev()}>
                                        <img src="/images/4.png" className="img_nv pre1" alt="icon" />
                                        <img src="/images/5.png" className="img_nv pre2" alt="icon" />
                                    </button>
                                    <button className="nav-next" onClick={() => swiperRef.current?.slideNext()}>
                                        <img src="/images/6.png" className="img_nv next1" alt="icon" />
                                        <img src="/images/7.png" className="img_nv next2" alt="icon" />
                                    </button>
                                </div>
                            </div> : <div className="col-12 text-center my-9">
                                            <p>வேறு தகவல்கள் இல்லை</p>
                                        </div>}
                        </div>
                        <div className="col-lg-8 mb-2 mt-xxl-0">
                            <div className="h-100">
                                <div id="hns-head-main" className="mb-lg-4 mb-3">
                                    <div className="d-flex align-items-center"> <div className="border_strong_head me-2" ></div> <h4 className="mb-0" id="hns-main-logo">{translate("மற்றவை")}</h4> </div>
                                    <div className="d-flex justify-content-end">
                                        <Link href="/" id="hns-Viewmore" to={"/category-view?id=13&uid=1&name=மற்றவை&pos=sixth-section"} onClick={() => scrollToTop()}>
                                            {/* {translate("மேலும் வாசிக்க")} */}
                                            <img src="/images/8.png" className="arrow_icon" alt="icon" />
                                        </Link>
                                    </div>
                                </div>
                                {newstoday.length > 0 ? 
                                <div className="card_theme">
                                    <div className="row">
                                            {newstoday.length > 0 && newstoday?.slice(0, 8).map((value, index) => {
                                                return (
                                                    <div id="hns-center-col" className="col-md-6 mb-md-2 mb-4">
                                                    <Link 
                                                    onClick={() => scrollToTop()} key={index} 
                                                    id="Link-all" 
                                                    // href={value.slug}
                                                    // to={"/news-view?Nid=" + value.id + "&Cid=" + value.category_id} 
                                                    to={"/news-view?slug=" + value.slug + "&c=" + value.category_name + "&sc=" + value.subcategory_name + "&pos=sixth-section"} 
                                                    className="mb-3"
                                                    >
                                                        <div id="hns-card" className="card">
                                                            <div>
                                                                <img id="hns-card-image" src={value.image ? value.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top" alt="icon" />
                                                            </div>
                                                            <div className="hns-card-body">
                                                                <p id="hns-card-text" className={mode == 'light' ? "card-text two_line text-dark mb-2" : "card-text two_line text-light mb-2"}>
                                                                    {value.title}
                                                                </p>

                                                                <p id="cv-card-date" className="w-100 mb-0">
                                                                    <FiClock size={18} id="cv-logoClock" className="me-2" />
                                                                    {value.date}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                                );
                                            })}
                                        <div id="hns-right-col" className="col-md-6 d-none">
                                            {newstoday.length > 0 && newstoday?.slice(4, 8).map((value, index) => {
                                                return (
                                                    <div key={index} className="mb-3">
                                                        <Link 
                                                        onClick={() => scrollToTop()} 
                                                        id="Link-all" 
                                                        // href={value.slug}
                                                        // to={"/news-view?Nid=" + value.id + "&Cid=" + value.category_id} 
                                                        to={"/news-view?slug=" + value.slug + "&c=" + value.category_name + "&sc=" + value.subcategory_name + "&pos=sixth-section"} 
                                                        className="mb-3"
                                                        >
                                                            <div id="hns-card" className="card">
                                                                <div>
                                                                    <img id="hns-card-image" src={value.image ? value.image : no_image} onError={(e) => { e.target.src = no_image;}} className="card-img-top" alt="icon" />
                                                                </div>
                                                                <div className="hns-card-body">
                                                                    <p id="hns-card-text" className="card-text two_line text-dark mb-3">
                                                                        {value.title}
                                                                    </p>
                                                                    <p id="cv-card-date" className="w-100 mb-0 ">
                                                                        <FiClock size={18} id="cv-logoClock" className="me-2" />
                                                                        {value.date}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                            {/* <Link id='Link-all' to={"/news-view?Nid=" + Data[5].id + "&Cid=" + Data[5].category_id}>

                            <div id='hns-card' className="card">
                                <img id='hns-card-image' src={Data[5].image} className="card-img-top" alt={Data[5].image_data.other_image}/>
                                <div className="hns-card-body">
                                    <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[5].category_id}`}>{Data[5].category_name}</Link>
                                    <p id='hns-card-text' className="card-text">{Data[5].title.slice(0, 60)}...</p>
                                </div>
                            </div>
                        </Link>
                        <Link id='Link-all' to={"/news-view?Nid=" + Data[6].id + "&Cid=" + Data[6].category_id}>

                            <div id='hns-card' className="card">
                                <img id='hns-card-image' src={Data[6].image} className="card-img-top" alt={Data[6].image_data.other_image}/>
                                <div className="hns-card-body">
                                    <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[6].category_id}`}>{Data[6].category_name}</Link>
                                    <p id='hns-card-text' className="card-text">{Data[6].title.slice(0, 60)}...</p>
                                </div>
                            </div>
                        </Link>
                        <Link id='Link-all' to={"/news-view?Nid=" + Data[7].id + "&Cid=" + Data[7].category_id}>

                            <div id='hns-card' className="card">
                                <img id='hns-card-image' src={Data[7].image} className="card-img-top" alt={Data[7].image_data.other_image}/>
                                <div className="hns-card-body">
                                    <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[7].category_id}`}>{Data[7].category_name}</Link>
                                    <p id='hns-card-text' className="card-text">{Data[7].title.slice(0, 60)}...</p>
                                </div>
                            </div>
                        </Link>
                        <Link id='Link-all' to={"/news-view?Nid=" + Data[8].id + "&Cid=" + Data[8].category_id}>
                            <div id='hns-card' className="card">
                                <img id='hns-card-image' src={Data[8].image} className="card-img-top" alt={Data[8].image_data.other_image}/>
                                <div className="hns-card-body">
                                    <Link id='btnhnsCatagorysub' className='btn btn-sm' type="button" to={`/category-view?id=${Data[8].category_id}`}>{Data[8].category_name}</Link>
                                    <p id='hns-card-text' className="card-text">{Data[8].title.slice(0, 60)}...</p>
                                </div>
                            </div>
                        </Link> */}
                                        </div>
                                    </div>
                                </div> : <div className="col-12 text-center my-9">
                                            <p>வேறு தகவல்கள் இல்லை</p>
                                        </div>}
                            </div>
                        </div>
                    </div>
                    <div id="hns-main-mobile"></div>
                </div>

            </div>
        </>
    );
}

export default HealthNewsSection;
