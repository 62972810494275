import React, { useState, useEffect } from "react";
import CommentsView from "./CommentsView";
import { setcommentApi } from "../store/actions/campaign";
import { isLogin, translate } from "../utils"
import { toast } from "react-toastify";
import { FCM } from '../Firebase'
import {useThemeMode} from '../utils/themeMode'


function CommentSection(props) {
  const {mode} = useThemeMode();
  const [Comment, setComment] = useState("");
  const [LoadComments, setLoadComments] = useState(false);
  const [Comments, setComments] = useState([]);
  const slug = props.slug;
  

  const setCommentData = (e) => {
    e.preventDefault();
    if (!isLogin()) {
      toast.error("கருத்து தெரிவிக்க முதலில் உள்நுழையவும்");
      document.getElementById('btnSignIn')?.click();
      return;
    }
    if (Comment.trim() === "") {
      toast.error("தயவுசெய்து ஒரு கருத்தை உள்ளிடவும்");
      return;
    }
    
    setcommentApi(
      "0",
      props.news_id,
      Comment,
      slug,
      (response) => {
        setLoadComments(true);
        setTimeout(() => {
          setLoadComments(false);
          setComment(""); 
          setComments((prevComments) => [...prevComments, Comment]);
          toast.success("கருத்து வெற்றிகரமாக அனுப்பப்பட்டது!"); 
          
        }, 1000);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <div className="">
      <CommentsView news_id={props.news_id} slug={slug} LoadComments={LoadComments} Comments={Comments} />
      <div className="d-flex align-items-center mb-3">
        <div className="border_strong_head me-2"></div>
        <h2 className={mode == 'light' ? "mb-0" : "mb-0 text-light"} >{translate("உங்கள் கருத்தைப் பதிவு செய்ய")}</h2>
      </div>
      <div className="card_theme mb-4">
        <form id="cs-main" onSubmit={setCommentData}>
          <textarea
            name=""
            id="cs-input"
            cols="30"
            rows="4"
            value={Comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            placeholder={`${translate("உங்கள் கருத்தைப் பதிவு செய்ய...")}`}
          ></textarea>
          <button id="cs-btnsub" type="submit" className="btn">
            <i className="fa fa-paper-plane pe-2" aria-hidden="true"></i>{" "}
            {translate("சமர்ப்பிக்க")}
          </button>
        </form>
      </div>
    </div>
  );
}

export default CommentSection;
