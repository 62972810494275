import React, { useEffect, useState } from "react";
import BreadcrumbNav from "./BreadcrumbNav";
import { getpagesApi } from "../store/actions/campaign";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import Skeleton from "react-loading-skeleton";
import { translate } from "../utils";
import {useThemeMode} from '../utils/themeMode';

function AboutUs() {
    const {mode, menuMode, updateMenuMode} = useThemeMode();
    const [loading, setLoading] = useState(true);
    const [Data, setData] = useState({
        page_content: "",
    });
    const currentLanguage = useSelector(selectCurrentLanguage);

    useEffect(() => {
        setLoading(true);
        getpagesApi(
            (response) => {
                let aboutUs = response.data.filter((data) => data.title === "About Us");
                setData(aboutUs[0]);
                setLoading(false);
            },
            (error) => {
                setData("");
                setLoading(false);
                console.error(error);
            }
        );
    }, [currentLanguage]);

    return (
        <div>
            <BreadcrumbNav SecondElement="எங்களை பற்றி" ThirdElement="0" />
            <div className="main-aboutus mb-5">
                <div id="about-us" className="container">
                    <div id="about">
                        <h4 className="A my-4">
                            <strong>{translate("எங்களைப்பற்றி")}</strong>
                        </h4>
                        {loading ? <Skeleton count={5} className={mode == 'light' ? "" : "bg-dark-bcb"} highlightColor={mode == 'light' ? "" : "bg-dark-bcb-skel"} /> : Data?.page_content ? <p dangerouslySetInnerHTML={{ __html: Data.page_content }} /> :<div className=""> <img src="/images/no_image.jpg" className="no_img my-5" alt="icon" /> </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
