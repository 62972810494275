import React, { useState, useEffect, useRef } from "react";
import { FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import BreadcrumbNav from "./BreadcrumbNav";
import { deleteusernotificationApi, getusernotificationApi } from "../store/actions/campaign";
import { translate } from "../utils";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import no_image from "../images/no_image.jpg";

function Notification() {
    const [Data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const initialData = useRef([]);

    useEffect(() => {
      initialData.current = Data;
    }, [Data]);



    useEffect(() => {
        getusernotificationApi(
            "0",
            "10",
            (response) => {
                setData(response.data);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setData("");
                }
            }
        );
    }, []);


    const handleDeleteComment = (e, id) => {
        e.preventDefault()
        deleteusernotificationApi(
            id,
            (response) => {
                 // Remove the deleted notification from the state
                setData(prevData => prevData.filter(notification => notification.id !== id));
                toast.success(response.message)
                setIsLoading(false);

            },
            (error) => {
                setIsLoading(false);
                if (error === "வேறு தகவல்கள் இல்லை") {
                    setData("");
                }
            }

        );
    };

    return (
        <>
            <BreadcrumbNav SecondElement="அறிவிப்பு" />

            <div id="main-Noticard" className="my-3 container">
                <div className="d-flex bd-highlight mb-3 d-none" >
                    <Link to="/persnol-notification" id="btnNotification1" className="btn mx-1 bd-highlight">
                        {" "}
                        {translate("தனிப்பட்ட எல்பிஎல்")}{" "}
                    </Link>
                    <Link to="/news-notification" id="btnNewsnoti" className="btn mx-1 bd-highlight">
                        {" "}
                        {translate("செய்தி")}
                    </Link>
                    {/* <button id='btnNotification1' className="btn  btn mx-1 ms-auto bd-highlight" onClick={handleDeleteAll} > Delete All</button> */}
                </div>
                <div className="my-3">
                    {isLoading ? (
                        <div className="col-12 loading_data">
                            <Skeleton height={20} count={22} />
                        </div>
                    ) : Data.length > 0 ? (
                        Data?.map((element,index) => (
                            <div className="card_theme my-3" key={index}>
                                <div className="card-body bd-highlight" id="card-noti">
                                {/* <img id="noti_profile" src={element.profile ? element.profile : no_image} onError={(e) => { e.target.src = no_image;}} alt="" /> */}
                                    <div className="Noti-text pe-5">
                                        <p className="bd-highlight ">{translate("மீண்டும் கருத்துரை")}  {element.message}</p>
                                        {/* <p>{translate("by")} {element.name}</p> */}
                                        <p className="bd-highlight"> {element.date} {translate("ago")}</p>
                                    </div>

                                    <div className="iconTrash ms-auto bd-highlight">
                                        <button className="btn p-1 btn m-1" id="btntrash" onClick={(e) => handleDeleteComment(e,element.id)}>
                                            {/* <p className="hide-mobile">{translate("deleteTxt")}</p> */}
                                            <i class="fa fa-trash-o" aria-hidden="true"></i>

                                            {/* <p className="hide-laptop">
                                                <FiTrash2 />
                                            </p> */}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="col-12 no_data mt-5">
                            <p className="text-center">{translate("வேறு தகவல்கள் இல்லை")}</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Notification;
