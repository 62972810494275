import React from "react";
import { FiCalendar, FiClock } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import { getnewsApi } from "../store/actions/campaign";

function RecentNewsView() {
    const [Data, setData] = useState([]);
    const currentLanguage = useSelector(selectCurrentLanguage);
    useEffect(() => {
        getnewsApi(
            "0",
            "12",
            "",
            "",
            (response) => {
                setData(response.data);
            },
            (error) => {
                console.log(error);
            }
        );
    }, [currentLanguage]);

    return (
        <div id="rnv-main" className="my-3">
            <div id="rnv-content" className="container">
                <div className="row">
                    {Data.length > 0 &&
                        Data?.map((element) => (
                            <div className="col-lg-4 col-md-6 col-xl-3 col-12 mb-3 px-2" key={element.id}>
                                <Link id="Link-all" to={"/news-view?Nid=" + element.id + "&Cid=" + element.category_id}>
                                    <div id="rnv-card" className="card h-100">
                                        <img id="rnv-card-image" src={element.image} className="card-img" alt="..." />
                                        <div id="rnv-card-body" className="card-body">
                                            <button id="rnv-btnCatagory" className="btn btn-sm" type="button">
                                                {element.category_name}
                                            </button>
                                            <h6 id="rnv-card-title" className="card-title">
                                                {element.title.slice(0, 150)}
                                            </h6>
                                            <p id="cv-card-date">
                                                <FiClock size={18} id="rnv-logoCalendar" />
                                                {element.date.slice(0, 10)}
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default RecentNewsView;
