import { useSelector } from "react-redux";
import { selectCurrentLanguageLabels } from "./store/reducers/languageReducer";
import { loadToken, tokenApi, tokenData } from "./store/reducers/tokenReducer";
import { Suspense, useEffect, useState } from "react";
import Footer from "./Componenets/Footer";
import CatNav from "./Componenets/CatNav";
import Newsbar from "./Componenets/Newsbar";
import { ToastContainer } from "react-toastify";
import Router from "./routes/Router";
import WeatherCard from "./Componenets/WeatherCard";
import { laodwebsettingsApi } from "./store/reducers/websettingsReducer";
import "./CSS/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, Routes, Route } from 'react-router-dom';
import { setFCMUpdateapi } from './utils/api';
import { logoutUser, selectUser, userSlice } from "./store/reducers/userReducer";
import HomePage from './Componenets/HomePage';
import {useThemeMode} from './utils/themeMode';

function App() {
    const location = useLocation();
    const {mode, menuMode, updateMenuMode} = useThemeMode();
    const FontSize = localStorage.getItem('newsFont');
    const [pathName, setPathName] = useState('/');
    // useEffect(() => {
    //     if ('serviceWorker' in navigator && 'PushManager' in window) {
    //       navigator.serviceWorker.register('/service-worker.js')
    //         .then((registration) => {
    //           console.log('Service Worker registered successfully');
    //         })
    //         .catch((error) => {
    //           console.error('Service Worker registration failed:', error);
    //         });
    //     }
    //   }, []);
    
      
    
    // const location = useLocation();

    //   useEffect(() => {
    //     window.scrollTo(0, 0); 
    //   }, [location.pathname]);

    //    window.onload = function() {
    //     window.scrollTo(0, 0);
    //   };

    // Set loader color theme
    function changeLoaderColor() {
        document.documentElement.style.setProperty('--loader-color', process.env.REACT_APP_COLOR);
    }

    // secondary color
    const secondaryColor = () => {
        document.documentElement.style.setProperty('--secondary-color', process.env.REACT_APP_SECONDARY_COLOR);
    }

    useEffect(() => {
        // window.scrollTo(0, 0);
        changeLoaderColor();
        secondaryColor();
    }, []);


    useEffect(() => {
        // token fetch
        tokenApi(
            (response) => {
                let token = response.data;
                loadToken(token);
            },
            (error) => {
                console.error(error);
            }
        );
    }, []);

    useSelector(selectCurrentLanguageLabels);

    const hasToken = useSelector(tokenData);

    // web settings load
    useEffect(() => {
        if (hasToken) {
            laodwebsettingsApi(
                (response) => {
                    document.documentElement.style.setProperty('--primary-color', response && response.data.web_color_code);
                    // Handle response data
                },
                (error) => {
                    // Handle error
                }
            );
        }
    }, [hasToken]);

    // useEffect(() => {
    //     // Request permission for notifications
    //     messaging
    //       .requestPermission()
    //       .then(() => {
    //         console.log('Notification permission granted.');
    //       })
    //       .catch((error) => {
    //         console.error('Unable to get permission for notifications:', error);
    //       });
    //   }, []);  

//     useEffect(() => {
//         setTimeout(() => window.scrollTo({ top: 0 }), 50);
//         console.log("gkheruithelrjtrith", location.pathname);
//   }, [location.pathname]);

//   useEffect(() => {
//     setTimeout(() => window.scrollTo({ top: 0 }), 100);
//   }, [currentPage, location]);

const [isDarkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
  };

  useEffect(() => {
    const theme = localStorage.getItem('newsTheme');
    if(theme) {
        document.documentElement?.setAttribute('data-bs-theme', theme)
    }
  }, [localStorage.getItem('newsTheme')]);

  useEffect(() => {    
    // const allParagraphs = document.querySelectorAll('p');
    // const allLinks = document.querySelectorAll('a');
    // const paragraphStyle = {
    //   fontSize: menuMode+'px',
    // };

    // allParagraphs.forEach(paragraph => {
    //   for (const property in paragraphStyle) {
    //     paragraph.style[property] = paragraphStyle[property];
    //   }
    // });
    // allLinks.forEach(paragraph => {
    //   for (const property in paragraphStyle) {
    //     paragraph.style[property] = paragraphStyle[property];
    //   }
    // });
    // document.documentElement.style.setProperty('--text-size', `${menuMode}`);
  }, [menuMode])


    return (
        <>
        <button onClick={toggleDarkMode} className="d-none" id="weifrhweurgweruwefruwferuwfr">Toggle Dark Mode</button>
            <ToastContainer theme="colored" />
            
            {hasToken ? (
                <div style={{
                    // color: isDarkMode ? 'var(--text-color-dark)' : 'var(--text-color-light)',
                    // background: isDarkMode ? 'var(--background-color-dark)' : 'var(--background-color-light)',
                }}>
                    <WeatherCard />
                       <Newsbar />
                        {/* <CatNav /> */}
                        <div className="default-heifht overflow-hidden" >
                        
                        <Suspense className="sdfjkjfjh" fallback={ <div className="loader-container"><span className="loader"></span></div> }>
                            <Routes>
                                <Route exact path="/" element={<HomePage />}></Route>
                            </Routes>
                            <Router  />
                        </Suspense>
                        </div>

                    {location.pathname === '/news-view' ? <></> : <Footer />}
                </div>
            ) : (
                    <div className="loader-container">
                        <span className="loader"></span>
                    </div>
            )}
        </>
    );
}

export default App;
